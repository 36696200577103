import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';
import type { HexVariant } from '@dx-ui/libs/osc-full-width-media';
import {
  FullWidthMedia,
  FullWidthMediaContentBlock,
  HamptonHexBlock,
} from '@dx-ui/libs/osc-full-width-media';

export default createCpmComponentDefinition(
  'Full-Width Media and Copy',

  function mapData({ data }) {
    const asset = data.cpmAssets[0];

    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;

    return {
      $ref: data.ref?.$ref,
      id: data.id,
      description: data.shortDescription,
      title: data.headline,
      link: data.link ?? undefined,
      desktopImageUrl: asset?.aspectRatios['16x9']?.url ?? '',
      mobileImageUrl: asset?.aspectRatios['1x1']?.url ?? '',
      captionData,
      imageAltText: asset?.altText ?? '',
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
    };
  },

  function FullWidthMediaComponent({ items = [], componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);

    const data = filteredItems[0];

    if (!data) {
      return null;
    }

    const shouldDisplayStandardContent =
      !componentParams.display ||
      componentParams.display === 'mediaWithCopy' ||
      componentParams.display === 'static' ||
      componentParams.display === '';
    const shouldDisplayHamptonHex =
      [
        'hxHexagonBlue',
        'hxHexagonDarkBlue',
        'hxHexagonDarkOrange',
        'hxHexagonDarkPurple',
        'hxHexagonDarkRed',
        'hxHexagonRed',
      ].includes(componentParams.display) && brandCode === 'HP';

    return (
      <FullWidthMedia
        alignment={componentParams.textAlign}
        imageWrapperClass={shouldDisplayHamptonHex ? 'md:absolute md:size-full' : ''}
        image={{
          altText: data.imageAltText,
          mobileImageUrl: data.mobileImageUrl,
          desktopImageUrl: data.desktopImageUrl,
        }}
        captionData={data.captionData}
      >
        {shouldDisplayStandardContent ? (
          <FullWidthMediaContentBlock
            link={data.link}
            headline={data.title}
            alignment={componentParams.textAlign}
            longDescription={data.description}
            brandComponentTheme={componentParams.theme}
          />
        ) : null}
        {shouldDisplayHamptonHex ? (
          <HamptonHexBlock
            link={data.link}
            variant={componentParams.display as HexVariant}
            headline={data.title}
          />
        ) : null}
      </FullWidthMedia>
    );
  }
);
