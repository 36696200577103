import type * as React from 'react';

export const ErrorBlock: React.FC<{ title: React.ReactNode; message: string }> = ({
  title,
  message,
}) => {
  return (
    <div className="bg-danger-alt flex flex-col items-center justify-center p-8 text-center">
      <h2 className="text-2xl font-bold" data-e2e="pageSubTitle">
        {title}
      </h2>

      <p>{message}</p>
    </div>
  );
};
