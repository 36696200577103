import { ParallaxProvider } from 'react-scroll-parallax';
import Cookies from 'universal-cookie';
import type { ContainerItem } from '@bloomreach/spa-sdk';
import { TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import * as React from 'react';
import type { MetricsInterface } from '@dx-ui/cpm-metrics';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { MappingContextProvider, useMappingContext } from './context';
import type {
  CpmDataBundle,
  CpmServerBundle,
  CpmClientBundle,
  CpmComponentDefinition,
  KeyedMappingDefinition,
  CpmMappedComponentData,
  CpmPreMappedDataInstance,
  CpmMandatoryMappingOutputFields,
  CpmListDocumentData,
  CpmDataDocumentData,
  ExperimentationAgents,
} from './types';
import { useBrComponentContext } from '../adapters/hooks';
import type { BrMapping, ExtractCampaignCodeTaggingValuesFn } from '../adapters/types';
import EditWrapper from '../components/EditWrapper';
import Fallback from '../components/Fallback';
import { OneLinkWrapper } from '../components/OneLinkWrapper';
import { useIssues } from '../hooks/use-issues';
import type { MappedComponentName } from '../schema';
import { mappingSchema } from '../schema';
import { mapCpmData } from './mapCpmData';
import { BrPage, BrComponent } from '../adapters/components';
import { CookiesContext } from '../context/CookiesContext';
import { getIsReducedMotion } from '../utils/get-is-reduced-motion';
import { getTaggingValuesFromCpmAnalytics } from '../utils/add-campaign-id-to-url';
import { shouldRenderExperiment } from '../utils/should-render-experiment';
import { useRouter } from 'next/router';
import { HeadingLevelProvider } from '@dx-ui/osc-heading-level';
import type { BrProps } from '@bloomreach/react-sdk';
import { CpmQueryClientProvider, CpmDataWrapper } from './DataWrapper';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';
import { CpmConductricsAgentsProvider, useCpmConductricsAgents } from './ConductricsWrapper';
import { filterExperimentationItems, filterLinks } from '../utils/filter-experimentation-items';
import { registerMappedAgents, useConductricsAgentsContext } from '../context/ConductricsAgents';
import {
  usePreviewConductricsAgents,
  convertPreviewAgentsToSelections,
} from '../context/PreviewConductricsAgents';

export type CpmRendererProps<Definitions extends KeyedMappingDefinition> = {
  cpmData: CpmDataBundle<Definitions>;
  sectionNames: Array<string>;
  metrics: Partial<MetricsInterface>;
  children: (layout: Record<string, React.ReactNode>) => React.ReactNode;
  campaignCodeTaggingValues?: ExtractCampaignCodeTaggingValuesFn;
  mappingName?: string;
};

export type CpmServerRendererProps<Definitions extends KeyedMappingDefinition> =
  CpmRendererProps<Definitions> & {
    cpmData: CpmServerBundle<Definitions>;
    definitions: Partial<Definitions>;
  };

export type CpmEditorRendererProps<Definitions extends KeyedMappingDefinition> =
  CpmRendererProps<Definitions> & {
    cpmData: CpmClientBundle;
    definitions: Partial<Definitions>;
  };

function makeCpmMappingComponent<
  ComponentName extends MappedComponentName,
  MappingOutput extends CpmMandatoryMappingOutputFields
>(
  definition: CpmComponentDefinition<ComponentName, MappingOutput>,
  campaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
) {
  const { component: RenderComponent, cpmComponentName, mapData } = definition;
  const componentSchema = mappingSchema[cpmComponentName];

  const CpmMapper = () => {
    const pageBr = useCpmMergedBrPageContext();
    const componentBr = useBrComponentContext();
    const issuesCallbacks = useIssues();
    const { mappedPage } = useMappingContext();
    const conductricsAgentsContext = useConductricsAgentsContext();
    const [previewAgents] = usePreviewConductricsAgents();
    const agents = convertPreviewAgentsToSelections(previewAgents);

    const props: CpmMappedComponentData<ComponentName, MappingOutput> = mapCpmData<
      ComponentName,
      MappingOutput
    >(
      cpmComponentName,
      mapData,
      pageBr,
      componentBr,
      mappedPage,
      issuesCallbacks,
      campaignCodeTaggingValues,
      true
    );

    registerMappedAgents(conductricsAgentsContext, props.experimentationAgentIds);

    // Filter out components that have agentId/variant set
    if (
      props.componentParams.agentId &&
      props.componentParams.variation &&
      !shouldRenderExperiment(agents, {
        agentId: props.componentParams.agentId,
        variation: props.componentParams.variation,
      })
    ) {
      return null;
    }

    const filteredData = filterMappedData(
      props as CpmMappedComponentData<
        ComponentName,
        ReturnType<KeyedMappingDefinition[ComponentName]['mapData']>
      >,
      agents
    );

    return (
      <OneLinkWrapper componentParams={props.componentParams}>
        <HeadingLevelProvider
          isEnabled={!!props.componentParams.topHeadingLevel}
          level={props.componentParams.topHeadingLevel}
        >
          <RenderComponent
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(filteredData as any)}
            metrics={{}}
            experimentationAgents={agents}
          />
        </HeadingLevelProvider>
      </OneLinkWrapper>
    );
  };

  return function CpmMappingWrapper(props: BrProps<ContainerItem>) {
    return (
      <CpmDataWrapper {...props} componentName={definition.cpmComponentName}>
        <EditWrapper
          displayName={definition.cpmComponentName}
          isEditable={componentSchema.isEditable}
          isMappedComponent={componentSchema.mappingKind !== 'Unmapped'}
        >
          <CpmMapper />
        </EditWrapper>
      </CpmDataWrapper>
    );
  };
}

const UniversalProviders = ({ children }: { children: React.ReactNode }) => (
  <ParallaxProvider isDisabled={getIsReducedMotion()}>
    <CookiesContext.Provider value={new Cookies()}>{children}</CookiesContext.Provider>
  </ParallaxProvider>
);

export function makeCpmRenderer<Definitions extends KeyedMappingDefinition>(
  definitions: Partial<Definitions>
) {
  return function CpmRenderer({
    cpmData,
    children,
    sectionNames,
    mappingName,
    metrics,
    campaignCodeTaggingValues = getTaggingValuesFromCpmAnalytics,
  }: CpmRendererProps<Definitions>) {
    // Data from the CMS
    if ('cpmPage' in cpmData) {
      return (
        <CpmEditorRenderer
          campaignCodeTaggingValues={campaignCodeTaggingValues}
          cpmData={cpmData}
          definitions={definitions}
          mappingName={mappingName}
          metrics={metrics}
          sectionNames={sectionNames}
        >
          {children}
        </CpmEditorRenderer>
      );
    }

    // Pre-processed data from on the server for apps
    return (
      <CpmConductricsAgentsProvider agentIds={cpmData.experimentationAgentIds}>
        <CpmAppRender
          campaignCodeTaggingValues={campaignCodeTaggingValues}
          cpmData={cpmData}
          definitions={definitions}
          mappingName={mappingName}
          metrics={metrics}
          sectionNames={sectionNames}
        >
          {children}
        </CpmAppRender>
      </CpmConductricsAgentsProvider>
    );
  };
}

/**
 * CpmEditorRenderer is only rendered inside the editor app (dx-cpm-lite)
 */
function CpmEditorRenderer<Definitions extends KeyedMappingDefinition>({
  definitions,
  cpmData,
  children,
  sectionNames,
  mappingName,
  campaignCodeTaggingValues = getTaggingValuesFromCpmAnalytics,
}: CpmEditorRendererProps<Definitions>) {
  const router = useRouter();
  const mapping: BrMapping = {
    ...Object.fromEntries(
      Object.values(definitions).map((definition) => [
        definition.cpmComponentName,
        makeCpmMappingComponent<
          typeof definition.cpmComponentName,
          ReturnType<typeof definition.mapData>
        >(
          definition as CpmComponentDefinition<
            typeof definition.cpmComponentName,
            ReturnType<typeof definition.mapData>
          >,
          campaignCodeTaggingValues
        ),
      ])
    ),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    [TYPE_CONTAINER_ITEM_UNDEFINED]: (props: any) => (
      <Fallback {...props} mappingName={mappingName} />
    ),
  };

  return (
    <UniversalProviders>
      <MappingContextProvider mappedPage={cpmData.mappedPage}>
        <CpmQueryClientProvider token={router.query?.token as string}>
          <BrPage
            page={cpmData.cpmPage}
            configuration={cpmData.bloomReachConfiguration}
            mapping={mapping}
          >
            {children(
              Object.fromEntries(
                sectionNames.map((sectionName) => [
                  sectionName,
                  <BrComponent key={sectionName} path={sectionName} />,
                ])
              )
            )}
          </BrPage>
        </CpmQueryClientProvider>
      </MappingContextProvider>
    </UniversalProviders>
  );
}

/**
 * CpmAppRender is rendered inside apps like dx-brands-ui or dx-guests-ui
 */
function CpmAppRender<Definitions extends KeyedMappingDefinition>({
  definitions,
  cpmData,
  children,
  sectionNames,
  metrics,
}: CpmServerRendererProps<Definitions>) {
  const agents = useCpmConductricsAgents();
  const { preMappedData } = cpmData;

  //eslint-disable-next-line no-inner-declarations
  function composePreMappedData(sectionName: string): React.ReactNode {
    const section = preMappedData[sectionName];

    if (!section) {
      return null;
    }

    return (
      <React.Fragment>
        {Object.values(section).map(
          (
            {
              componentName,
              mappedData,
            }: CpmPreMappedDataInstance<MappedComponentName, Definitions>,
            i: number
          ) => {
            const componentDefinition = definitions[componentName];

            if (!componentDefinition) {
              return null;
            }

            // Filter out components that have agentId/variant set
            if (
              mappedData.componentParams.agentId &&
              mappedData.componentParams.variation &&
              !shouldRenderExperiment(agents, {
                agentId: mappedData.componentParams.agentId,
                variation: mappedData.componentParams.variation,
              })
            ) {
              return null;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const RenderComponent = (componentDefinition as any).component;

            return (
              // eslint-disable-next-line react/no-array-index-key -- This data will come from the server, it will never change, we're safe to use indexes as keys
              <ErrorBoundary showError={false} key={i}>
                <OneLinkWrapper componentParams={mappedData.componentParams}>
                  <HeadingLevelProvider
                    isEnabled={!!mappedData.componentParams.topHeadingLevel}
                    level={mappedData.componentParams.topHeadingLevel}
                  >
                    <RenderComponent
                      {...filterMappedData(mappedData, agents)}
                      metrics={metrics}
                      experimentationAgents={agents}
                    />
                  </HeadingLevelProvider>
                </OneLinkWrapper>
              </ErrorBoundary>
            );
          }
        )}
      </React.Fragment>
    );
  }

  return (
    <UniversalProviders>
      <MappingContextProvider mappedPage={cpmData.mappedPage}>
        {children(
          Object.fromEntries(
            sectionNames.map((sectionName) => [sectionName, composePreMappedData(sectionName)])
          )
        )}
      </MappingContextProvider>
    </UniversalProviders>
  );
}

/**
 * Filter documents and links based on clientside conductrics state
 */
export function filterMappedData<
  ComponentName extends MappedComponentName,
  MappingDefinition extends KeyedMappingDefinition
>(
  mappedData: CpmMappedComponentData<
    ComponentName,
    ReturnType<MappingDefinition[ComponentName]['mapData']>
  >,
  agents: ExperimentationAgents
) {
  if (isCpmListMappingKind(mappedData)) {
    return {
      ...mappedData,
      items: filterExperimentationItems(agents, Object.values(mappedData.keyedItems)),
      listData: mappedData.listData
        ? {
            ...mappedData.listData,
            links: mappedData.listData.links.filter((link) =>
              shouldRenderExperiment(agents, link.experimentationConfiguration)
            ),
          }
        : null,
    };
  }

  if (isCpmDataDocumentMappingKind(mappedData) && !!mappedData.data) {
    const links = filterLinks(agents, mappedData.data.links);
    return {
      ...mappedData,
      data: {
        ...mappedData.data,
        link: links[0],
        links,
      },
    };
  }

  return mappedData;
}

export function isCpmListMappingKind<
  ComponentName extends MappedComponentName,
  Output extends CpmMandatoryMappingOutputFields
>(
  data: CpmMappedComponentData<ComponentName, Output>
): data is CpmMappedComponentData<ComponentName, Output> & CpmListDocumentData<Output> {
  return data.mappingKind === 'ListDocument';
}

export function isCpmDataDocumentMappingKind<
  ComponentName extends MappedComponentName,
  Output extends CpmMandatoryMappingOutputFields
>(
  data: CpmMappedComponentData<ComponentName, Output>
): data is CpmMappedComponentData<ComponentName, Output> & CpmDataDocumentData<Output> {
  return data.mappingKind === 'DataDocument';
}
