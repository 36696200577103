import { BrManageContentButton } from '@bloomreach/react-sdk';
import { useBrPageContextSafe } from '../adapters/hooks';

/**
 * Allows rendering of a dynamic orange "manage content" button. This will only be enabled in the CMS (Bloomreach) via
 * the CPM editor app (dx-cpm-lite).
 */
export function useCreateManageContentButton() {
  // This *won't* throw in apps that are missing the Bloomreach context provider
  const page = useBrPageContextSafe();

  return function CpmManageContentButton($ref?: string) {
    // Context will only exist in the editor app
    if (!page || typeof page.getContent !== 'function' || !$ref) {
      return null;
    }

    // Bloomreach needs to know what content should be edited
    const content = page.getContent($ref);

    if (!content) {
      return null;
    }

    return (
      <div className="relative" data-testid="cms-control">
        <BrManageContentButton content={content} />
      </div>
    );
  };
}
