import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';
import { makeHeadingOverrides } from '@dx-ui/osc-textual-block';

export interface TextBlockHeadingProps {
  /**
   * The markdown for the headline component.
   */
  markdown: string;
  /**
   * Is the component within the viewport?
   */
  isInView: boolean;
  /**
   * fade-in text animation
   */
  isAnimated: boolean;
  /**
   * The alignment of the text.
   */
  textAlign?: 'left' | 'right' | 'center';
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
}

/**
 * The TextBlock Heading component.
 */
export const TextBlockHeading = ({
  markdown,
  isInView,
  isAnimated = false,
  textAlign = 'left',
  brandComponentTheme,
}: TextBlockHeadingProps) => {
  if (!markdown) {
    return null;
  }

  const options = {
    overrides: {
      ...makeHeadingOverrides({
        brandComponentTheme,
        isAnimated,
        isInView,
        textAlign,
      }),
      span: {
        props: {
          className: 'text-text py-2 sm:heading-lg lg:heading-xl block',
        },
      },
    },
  };

  return (
    <CustomMarkdown
      options={options}
      data-testid="textBlockHeader"
      brandComponentTheme={brandComponentTheme}
    >
      {markdown}
    </CustomMarkdown>
  );
};
