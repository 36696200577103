import { env } from '@dx-ui/framework-env';

export function getQueryProviderProps() {
  const config = {
    incompleteLoggingEnabled: env('RUMCS_CAPTURE_CUSTOM_ERRORS') === 'enabled',
    appId: env('DX_AUTH_API_CUSTOMER_APP_ID'),
    appName: env('APP_NAME'),
    authEndpoints: {
      browser: env('DX_AUTH_API_CLIENT_URI'),
      server: process.env.DX_AUTH_API_SERVER_URI || '',
    },
    gqlEndpoints: {
      browser: env('BROWSER_DX_GQL_URI'),
      server: process.env.SERVER_DX_GQL_URI || '',
    },
  };

  return config;
}
