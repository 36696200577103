import type { Context } from 'react';
import { createContext, useContext } from 'react';
import type { BrandSocialMediaLink, FeatureToggle, ShopAvailOptions } from '@dx-ui/gql-types';
import type { GetCompanyByAccountIdQuery } from './types/gql';

function makeContext<T>(name: string): [context: Context<T | undefined>, hook: () => T] {
  const Context = createContext<T | undefined>(undefined);

  return [
    Context,
    () => {
      const value = useContext(Context);

      if (value === undefined) {
        throw new Error(
          `${name} is undefined. This component can only be used in apps that are wrapped in ${name}Context`
        );
      }

      return value;
    },
  ];
}

export type PreviewSegments = string[];
export const [PreviewSegmentsContext, usePreviewSegments] =
  makeContext<PreviewSegments>('PreviewSegments');

export type FeatureToggles = Pick<FeatureToggle, 'name' | 'enabled' | 'cacheable'>[];
export const [FeatureTogglesContext, useFeatureToggles] =
  makeContext<FeatureToggles>('FeatureToggles');

export type CorporateAccount = GetCompanyByAccountIdQuery | null;
export const [CorporateAccountContext, useCorporateAccount] =
  makeContext<CorporateAccount>('CorporateAccount');

export type BrandData = {
  code?: string | null | undefined;
  name?: string | null | undefined;
  isPartnerBrand?: boolean | null | undefined;
  formalName?: string | null | undefined;
  url?: string | null | undefined;
  locationsUrl?: string | null | undefined;
  socialMediaLinks?: Array<Pick<BrandSocialMediaLink, 'channel' | 'url'>>;
  phone?: { number: string; friendlyNumber: string };
  shopAvailOptions?: Pick<ShopAvailOptions, 'maxNumRooms'> | null;
};
export const [BrandDataContext, useBrandData] = makeContext<BrandData>('BrandData');
