import * as React from 'react';
import cx from 'classnames';

type TabList = React.HTMLAttributes<HTMLDivElement>;

/**
 * The `TabList` component is used to render the tab buttons. It sets up a11y properties for the best compliance with the WCAG 2.0 accessibility guidelines.
 */
const TabList = React.forwardRef<HTMLDivElement, TabList>(
  ({ className, ...rest }, forwardedRef) => (
    <div
      role="tablist"
      className={cx('flex justify-start overflow-x-auto px-1 pt-2', className)}
      ref={forwardedRef}
      {...rest}
    />
  )
);

TabList.displayName = 'TabList';

export { TabList };
export default TabList;
