import {
  TextBlockCTA,
  TextBlockDescription,
  TextBlockHeading,
  TextBlockOrderedList,
  TextBlockUnorderedList,
} from './blocks';
import type { ParsedContentSection } from './types';
import { useIntersectionObserver } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { TextualBlock } from '@dx-ui/osc-textual-block';

export interface TextBlockProps {
  /**
   * The individual text block elements that will be rendered.
   * The text box heading and text box description needs a markdown
   */
  data?: ParsedContentSection[];
  /**
   * Adds additional styling control to the wrapper that is rendered.
   */
  className?: string;
  /**
   * fade-in text animation
   */
  isAnimated: boolean;
  /**
   * The alignement of the items.
   */
  textAlign?: 'left' | 'right' | 'center';
  /**
   * Adds outline around content, within container
   */
  isBorderTrim?: boolean;
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
}

/**
 * A component that is designed to render a headline, description, ordered list, unordered list, and/or cta. Background color theme and background image are full screen, content sits within a container.
 */
export function TextBlock({
  data,
  textAlign = 'left',
  isAnimated = false,
  isBorderTrim,
  brandComponentTheme,
}: TextBlockProps) {
  const { ref, isIntersecting: inView } = useIntersectionObserver({
    threshold: 0.5,
    freezeOnceVisible: true,
  });
  const wrapperRef = !getIsReducedMotion() && isAnimated ? ref : null;

  return (
    <TextualBlock
      brandComponentTheme={brandComponentTheme}
      textAlign={textAlign}
      hasBorderTrim={isBorderTrim ?? false}
      ref={wrapperRef}
    >
      {data?.map((section: ParsedContentSection) => {
        switch (section.kind) {
          case 'heading':
            return (
              <TextBlockHeading
                key={section.content}
                textAlign={textAlign}
                isInView={inView}
                isAnimated={isAnimated}
                markdown={section.content}
                brandComponentTheme={brandComponentTheme}
              />
            );

          case 'description':
            return (
              <TextBlockDescription
                key={section.content}
                textAlign={textAlign}
                isInView={inView}
                isAnimated={isAnimated}
                body={section.content}
                brandComponentTheme={brandComponentTheme}
              />
            );

          case 'orderedList':
            return (
              <TextBlockOrderedList
                key={`${section.content.join('')}-ordered-list`}
                textAlign={textAlign}
                isInView={inView}
                isAnimated={isAnimated}
                items={section.content}
                brandComponentTheme={brandComponentTheme}
              />
            );

          case 'unorderedList':
            return (
              <TextBlockUnorderedList
                key={`${section.content.join('')}-unordered-list`}
                textAlign={textAlign}
                isInView={inView}
                isAnimated={isAnimated}
                items={section.content}
                brandComponentTheme={brandComponentTheme}
              />
            );

          case 'cta':
            return (
              <TextBlockCTA
                key={section.content._id}
                isInView={inView}
                isAnimated={isAnimated}
                link={section.content}
                brandComponentTheme={brandComponentTheme}
              />
            );

          default:
            return null;
        }
      })}
    </TextualBlock>
  );
}

export default TextBlock;
