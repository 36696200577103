import Image from 'next/image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { Caption } from '@dx-ui/osc-caption';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { BrandLink } from '@dx-ui/osc-brand-buttons';

export type FullWidthMediaProps = {
  image: {
    altText: string;
    mobileImageUrl: string;
    desktopImageUrl: string;
  };
  captionData?: CaptionProps;
  brandComponentTheme?: CmsBrandComponentTheme;
  imageWrapperClass?: string;
  children: React.ReactNode;
  alignment: 'left' | 'center' | 'right';
};

export type FullWidthMediaContentProps = Pick<FullWidthMediaProps, 'alignment'> & {
  longDescription: string;
  headline: string;
  brandComponentTheme?: CmsBrandComponentTheme;
  link?: {
    label: string;
    url: string;
    adaDescription?: string;
    isNewWindow?: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  };
};

export function FullWidthMediaContentBlock({
  alignment = 'center',
  longDescription,
  headline,
  brandComponentTheme,
  link,
}: FullWidthMediaContentProps) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isCentered = alignment === 'center';

  return (
    <div
      className={cx('flex w-full items-center lg:min-h-full', {
        'justify-end': alignment === 'right',
      })}
    >
      <div
        className={cx('relative w-full max-w-screen-lg lg:w-1/2 xl:w-[45%]', {
          'lg:start-1/2 lg:-translate-x-1/2': isCentered,
        })}
      >
        <div
          className={cx('px-16 py-8', {
            'bg-bg': !isDark && !isLight,
            'bg-bg-dark': isDark,
            'bg-bg-light': isLight,
          })}
        >
          {headline ? (
            <BrandTextHeader
              className={cx('text-center lg:mb-4 ', {
                'text-center': isCentered,
                'lg:text-start': !isCentered,
                '!text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {headline}
            </BrandTextHeader>
          ) : null}
          {longDescription ? (
            <BrandTextBody
              brandComponentTheme={brandComponentTheme}
              className={cx('text-center ', {
                'text-center': !isCentered,
                'lg:text-start': !isCentered,
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {longDescription}
            </BrandTextBody>
          ) : null}
          {link?.label && link?.url ? (
            <div
              className={cx('mt-4 flex w-full justify-center', {
                'lg:justify-center': isCentered,
                'lg:justify-normal': !isCentered,
              })}
            >
              <BrandLink
                url={link.url}
                label={link.label}
                isNewWindow={link.isNewWindow}
                showNewWindowIcon={link.isNewWindow}
                brandComponentTheme={brandComponentTheme}
                data-conductrics-goal={link.experimentationConfiguration?.goal}
                data-conductrics-value={link.experimentationConfiguration?.value}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function FullWidthMedia({
  image,
  captionData,
  children,
  imageWrapperClass,
  alignment,
}: FullWidthMediaProps) {
  return (
    <div className="relative md:aspect-[16/9]">
      <div
        className={cx(
          'relative -z-10 aspect-square md:aspect-[16/9] md:w-full lg:absolute lg:aspect-auto lg:size-full',
          imageWrapperClass
        )}
      >
        <Image
          className="hidden object-cover lg:block"
          unoptimized={true}
          src={image.desktopImageUrl}
          alt={image?.altText || ''}
          fill
        />
        <Image
          className="block object-cover lg:hidden"
          unoptimized={true}
          src={image.mobileImageUrl}
          alt={image?.altText || ''}
          fill
        />
      </div>
      {children}
      {captionData && (
        <Caption
          align={alignment === 'right' ? 'start' : 'end'}
          caption={captionData?.caption}
          captionLink={captionData?.captionLink}
        />
      )}
    </div>
  );
}

export default FullWidthMedia;
