import type { ExperimentationAgentIdsSet } from '../mappingEngine/types';

export function registerExperimentItem(
  set: ExperimentationAgentIdsSet,
  isCpmEditor: boolean,
  config?: { agentId?: string; variation?: string } | null
) {
  if (!config?.agentId || !config?.variation) {
    return;
  }

  if (isCpmEditor) {
    set.add(`${config.agentId}:${config.variation}`);
  } else {
    set.add(config.agentId);
  }
}
