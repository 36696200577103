import Image from 'next/image';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { GetHeightForWidthAndAspectRatio } from '@dx-ui/osc-responsive-image';

type FilmstripImage = {
  id?: string;
  alt: string;
  src: string;
  width: number;
  aspectRatio: AspectRatio;
  className?: string;
  onLoad?: () => void;
};

const NextImage = ({
  id,
  alt,
  src,
  width,
  aspectRatio,
  onLoad,
  className = 'object-cover',
}: FilmstripImage) => (
  <Image
    id={id}
    alt={alt}
    src={src}
    width={width}
    height={GetHeightForWidthAndAspectRatio(width, aspectRatio)}
    className={className}
    placeholder="blur"
    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88h8AAs0B5QWO2loAAAAASUVORK5CYII="
    onLoad={onLoad}
  />
);

export { NextImage as Image };
