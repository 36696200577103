import * as React from 'react';
import dynamic from 'next/dynamic';

import { Link } from '@dx-ui/osc-link';
import { Alert, Alerts, AlertDialog } from '@dx-ui/osc-alert';

import { useBrandMessagesQuery } from '../../generated/queries';

import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { logError } from '@dx-ui/framework-logger';
import { useTranslation } from 'next-i18next';
import type { BrandMessageType } from '@dx-ui/gql-types';

const getStatus = (messageType: BrandMessageType) => {
  switch (messageType) {
    case 'error':
      return 'emergency';
    default:
      return 'urgent';
  }
};

const DynamicMarkdown = dynamic(() => import('../../utils/dynamic-markdown-component'));

export default createCpmComponentDefinition(
  'BrandAlerts',

  () => null,

  function BrandAlertsCpm({ componentParams, mappedPage: { brandCode, languageCode } }) {
    const { data, isFetching, refetch } = useBrandMessagesQuery({
      brandCode,
      lang: languageCode,
    });
    const { t } = useTranslation('osc-alert');

    /*
     * This causes the request to be made client side,
     * This is so alerts are not included in the server-side page cache
     */
    React.useEffect(() => {
      refetch().catch((error) => logError('BrandAlerts', error, 'failed to refech brand alert'));
    }, [refetch]);

    const messages = data?.clientMessages?.messages ?? [];
    const label = '';

    if (isFetching || messages?.length === 0) {
      return null;
    }

    return messages?.length > 0 ? (
      <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
        <div>
          <Alerts label={label}>
            {messages.map(
              ({
                link: brandLink,
                type: messageType,
                shortDescription,
                headline,
                ...messageProps
              }) => {
                const link: React.ComponentProps<typeof Link> = {
                  ...brandLink,
                  adaDescription: brandLink?.adaDescription ?? undefined,
                  isNewWindow: brandLink?.isNewWindow ?? undefined,
                  label: brandLink?.label ?? undefined,
                  url: brandLink?.url ?? '',
                };

                return (
                  <Alert
                    key={[headline, shortDescription, brandLink].join('')}
                    title={headline ?? undefined}
                    status={messageType ? getStatus(messageType) : undefined}
                  >
                    {link && !shortDescription ? (
                      <Link {...link}>{link.label}</Link>
                    ) : (
                      !!shortDescription && (
                        <AlertDialog
                          label={t('readMessage')}
                          {...(headline ? { title: headline } : { ariaLabel: t('messageDialog') })}
                          {...messageProps}
                        >
                          <>
                            <DynamicMarkdown>{shortDescription}</DynamicMarkdown>
                            {link?.label && link?.url ? <Link {...link}>{link.label}</Link> : null}
                          </>
                        </AlertDialog>
                      )
                    )}
                  </Alert>
                );
              }
            )}
          </Alerts>
        </div>
      </BrandComponentThemeInline>
    ) : null;
  }
);
