import cx from 'classnames';

interface ForTheStayOverlayProps {
  overlayPosition: 'center' | 'left' | 'right';
  count: number;
}

export const ForTheStayOverlay = ({ overlayPosition, count }: ForTheStayOverlayProps) => {
  return (
    <div
      data-testid="forTheStayOverlay"
      className={cx(
        'pointer-events-none absolute top-1/2 w-40 -translate-y-1/2 overflow-hidden sm:w-60 md:w-80',
        {
          'inset-x-1/2 -translate-x-1/2': overlayPosition === 'center',
          'start-16': overlayPosition === 'left' && count > 1,
          'start-2': overlayPosition === 'left' && count === 1,
          'end-16': overlayPosition === 'right' && count > 1,
          'end-2': overlayPosition === 'right' && count === 1,
        }
      )}
    >
      <div className="h-24 w-full bg-[url('/modules/assets/img/brand/WW/backgrounds/for-the-stay.svg')] bg-contain bg-center bg-no-repeat" />
    </div>
  );
};
