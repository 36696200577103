import cx from 'classnames';

interface ForTheStayOverlayProps {
  overlayPosition: 'center' | 'left' | 'right';
}

export const ForTheStayOverlay = ({ overlayPosition }: ForTheStayOverlayProps) => {
  return (
    <div
      data-testid="forTheStayOverlay"
      className={cx(
        'pointer-events-none absolute top-0 w-40 -translate-y-full overflow-hidden pb-0 sm:w-60 sm:pb-4 md:w-80',
        {
          'inset-x-1/2 -translate-x-1/2': overlayPosition === 'center',
          'start-0': overlayPosition === 'left',
          'end-0': overlayPosition === 'right',
        }
      )}
    >
      <div className="h-24 w-full bg-[url('/modules/assets/img/brand/WW/backgrounds/for-the-stay.svg')] bg-contain bg-center bg-no-repeat" />
    </div>
  );
};
