import type { ReactNode } from 'react';

/**
 * Wrap components with a CSS class to stop OneLink translating the content
 */
export const OneLinkWrapper = ({
  children,
  componentParams,
}: {
  children: ReactNode;
  componentParams?: Record<string, unknown>;
}) => {
  const { oneLinkNoTx } = componentParams || {};

  return (
    <div data-testid="OneLinkWrapper" className={oneLinkNoTx ? 'OneLinkNoTx' : undefined}>
      {children}
    </div>
  );
};
