import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { useMemo } from 'react';

export type MVTAgentStatusProp = {
  [key: string]: boolean;
};

export const REMOVE_FLEXIBLE_DATES_AGENT_ID = 'a-88XVLKrnNI';
export const MY_FLEXIBLE_DATES_CLICK_GOAL = 'g-lNDlYVzRu9';
export const RESET_DATES = 'g-gjmxUjWSsN';
export const COUNTRY_BASED_POPULAR_DESTINATIONS_AGENT_ID = 'a-5CpNdJOJ7T'; //NHCSEARCH-5194

export const useGetMVTSelections = (agentStatus: MVTAgentStatusProp) => {
  //NHCSEARCH-5088 Remove Flexible dates when reset date is present
  const removeFlexibleDatesTestStatus = agentStatus.REMOVE_FLEXIBLE_DATES_AGENT_ID
    ? Status.OK
    : Status.PENDING;
  const { isLoaded: isRemoveFlexibleDatesTestLoaded, selection: removeFlexibleDatesSelection } =
    useConductricsSelection(REMOVE_FLEXIBLE_DATES_AGENT_ID, removeFlexibleDatesTestStatus);

  const { isRemoveFlexibleDatesDefault, isRemoveFlexibleDatesVariant } = useMemo(() => {
    const removeFlexibleDatSelections =
      isRemoveFlexibleDatesTestLoaded && removeFlexibleDatesTestStatus === Status.OK
        ? removeFlexibleDatesSelection?.choice?.toLowerCase()
        : '';
    return {
      isRemoveFlexibleDatesDefault: removeFlexibleDatSelections === 'a',
      isRemoveFlexibleDatesVariant: removeFlexibleDatSelections === 'b',
    };
  }, [
    isRemoveFlexibleDatesTestLoaded,
    removeFlexibleDatesSelection?.choice,
    removeFlexibleDatesTestStatus,
  ]);

  //NHCSEARCH-5194
  const countryBasedPopularDestinationsTestStatus =
    agentStatus.COUNTRY_BASED_POPULAR_DESTINATIONS_AGENT_ID ? Status.OK : Status.PENDING;
  const {
    isLoaded: isCountryBasedPopularDestinationsTestLoaded,
    selection: countryBasedPopularDestinationsSelection,
  } = useConductricsSelection(
    COUNTRY_BASED_POPULAR_DESTINATIONS_AGENT_ID,
    countryBasedPopularDestinationsTestStatus
  );

  const { isCountryPopularDestinationDefault, isCountryPopularDestinationVariant } = useMemo(() => {
    const countryBasedPopularDestinationsSelections =
      isCountryBasedPopularDestinationsTestLoaded &&
      countryBasedPopularDestinationsTestStatus === Status.OK
        ? countryBasedPopularDestinationsSelection?.choice?.toLowerCase()
        : '';
    return {
      isCountryPopularDestinationDefault: countryBasedPopularDestinationsSelections === 'a',
      isCountryPopularDestinationVariant: countryBasedPopularDestinationsSelections === 'b',
    };
  }, [
    countryBasedPopularDestinationsSelection?.choice,
    countryBasedPopularDestinationsTestStatus,
    isCountryBasedPopularDestinationsTestLoaded,
  ]);

  return {
    isRemoveFlexibleDatesTestLoaded,
    isRemoveFlexibleDatesDefault,
    isRemoveFlexibleDatesVariant,
    isCountryBasedPopularDestinationsTestLoaded,
    isCountryPopularDestinationDefault,
    isCountryPopularDestinationVariant,
  };
};
