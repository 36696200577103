type Props = React.PropsWithChildren<{
  theme: 'light' | 'dark' | '';
  enabled: boolean;
}>;

export function HamptonHexVisual({ theme, enabled, children }: Props) {
  if (enabled && theme === 'light') {
    return (
      <div className="relative">
        <svg
          aria-hidden="true"
          data-testid="hampton-hex-light"
          className="invisible absolute right-0 top-0 w-4/6 max-w-3xl md:visible"
          viewBox="0 0 770 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#fff"
            d="M631.553 167a283.534 283.534 0 0 1-76.876-10.601L0 0h769.553v139.138l-61.09 17.226a283.773 283.773 0 0 1-76.91 10.601V167Z"
          />
        </svg>

        <div className="relative">{children}</div>
      </div>
    );
  }

  if (enabled && theme === 'dark') {
    return (
      <div className="relative">
        <svg
          aria-hidden="true"
          data-testid="hampton-hex-dark"
          className="invisible absolute right-0 top-0 w-4/6 max-w-3xl md:visible"
          viewBox="0 0 770 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#002666"
            d="M631.553 167a283.534 283.534 0 0 1-76.876-10.601L0 0h769.553v139.138l-61.09 17.226a283.773 283.773 0 0 1-76.91 10.601V167Z"
          />
        </svg>

        <div className="relative">{children}</div>
      </div>
    );
  }

  return children;
}
