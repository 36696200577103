import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useState, useRef, useEffect, useContext } from 'react';
import type { ConductricsSelection } from '@dx-ui/framework-conductrics';

type PreviewConductricsAgents = Record<string, string | null>;

type PreviewConductricsAgentsContextValue = [
  PreviewConductricsAgents,
  Dispatch<SetStateAction<PreviewConductricsAgents>>
];
const defaultValue = {};
const noop = () => undefined;
const defaultContextValue: PreviewConductricsAgentsContextValue = [defaultValue, noop];

const PreviewConductricsAgentsContext = createContext(defaultContextValue);

const storageKey = 'cpmPreviewAgents';

export function PreviewConductricsAgentsProvider({ children }: { children: ReactNode }) {
  const state = useState<PreviewConductricsAgents>(defaultValue);
  const [conductricsAgentIds, setConductricsAgentsIds] = state;
  const initialized = useRef(false);

  useEffect(() => {
    try {
      const conductricsAgentsStorage = sessionStorage.getItem(storageKey);
      const previewConductricsAgents = conductricsAgentsStorage
        ? JSON.parse(conductricsAgentsStorage)
        : {};

      if (!initialized.current) {
        initialized.current = true;
        setConductricsAgentsIds(previewConductricsAgents);
      } else {
        sessionStorage.setItem(storageKey, JSON.stringify(conductricsAgentIds));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to save conductrics agent IDs to session storage', error);
    }
  }, [conductricsAgentIds, setConductricsAgentsIds]);

  return (
    <PreviewConductricsAgentsContext.Provider value={state}>
      {children}
    </PreviewConductricsAgentsContext.Provider>
  );
}

export function usePreviewConductricsAgents() {
  const context = useContext(PreviewConductricsAgentsContext);

  if (context === undefined) {
    throw new Error(
      `ConductricsAgentsContext is undefined. This hook can only be used in apps that are wrapped in ConductricsAgentsContextProvider.`
    );
  }

  return context;
}

export function convertPreviewAgentsToSelections(agents: PreviewConductricsAgents) {
  return Object.entries(agents)
    .filter(([, variation]) => typeof variation === 'string' && !!variation)
    .reduce<Record<string, ConductricsSelection>>(
      (accumulator, [agentId, variation]) => ({
        ...accumulator,
        [agentId]: {
          error: null,
          isLoaded: true,
          selection: {
            choice: variation ?? undefined,
          },
        },
      }),
      {}
    );
}
