import { TextualUnorderedListBlock, TextualUnorderedListItemBlock } from '@dx-ui/osc-textual-block';
import { useId } from 'react';
import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';

export interface TextBlockUnorderedListProps {
  /**
   * Is the component within the viewport?
   */
  isInView: boolean;
  /**
   * fade-in text animation
   */
  isAnimated: boolean;
  /**
   * An array of strings to render in our list.
   */
  items: Array<string>;
  /**
   * The alignment of the text.
   */
  textAlign?: 'left' | 'right' | 'center';
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
}

const TextBlockUnorderedListItem = ({
  markdown,
  brandComponentTheme,
}: {
  markdown: string;
  brandComponentTheme?: CmsBrandComponentTheme;
}) => {
  const id = useId();

  return (
    <TextualUnorderedListItemBlock brandComponentTheme={brandComponentTheme}>
      <CustomMarkdown
        id={`textblock-body-section-${id}`}
        isStaticContentPage={false}
        brandComponentTheme={brandComponentTheme}
      >
        {markdown}
      </CustomMarkdown>
    </TextualUnorderedListItemBlock>
  );
};

/**
 * The TextBlock Unordered List component.
 */
export const TextBlockUnorderedList = ({
  isInView,
  isAnimated = false,
  items,
  textAlign = 'left',
  brandComponentTheme,
}: TextBlockUnorderedListProps) => {
  if (items.length <= 0) {
    return null;
  }

  return (
    <TextualUnorderedListBlock
      textAlign={textAlign}
      isAnimated={isAnimated}
      isInView={isInView}
      brandComponentTheme={brandComponentTheme}
    >
      {items.map((item) => (
        <TextBlockUnorderedListItem
          key={item}
          markdown={item}
          brandComponentTheme={brandComponentTheme}
        />
      ))}
    </TextualUnorderedListBlock>
  );
};
