import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { usePartialBrPageContext } from '../adapters/hooks';
import type { BrPage, BrPageModel } from '../adapters/types';

const CpmMergedBrPageContext = createContext<BrPageModel | null>(null);

export function useCpmMergedBrPageContext() {
  const pageBr = usePartialBrPageContext();
  const cpmBrPageContext = useContext(CpmMergedBrPageContext);

  // Some components render straight away without needing to fetch, so don't throw if context is null
  if (!cpmBrPageContext) {
    return pageBr;
  }

  const mergedBrPage = Object.create(pageBr);

  mergedBrPage.model.page = {
    ...mergedBrPage.model.page,
    ...cpmBrPageContext.page,
  };

  return mergedBrPage as BrPage;
}

export function CpmMergedBrPageContextProvider({
  children,
  page,
}: {
  children: ReactNode;
  page: BrPageModel;
}) {
  return <CpmMergedBrPageContext.Provider value={page}>{children}</CpmMergedBrPageContext.Provider>;
}
