import * as React from 'react';
import type { CpmMappedPage } from './cpmMappedPage';

type MappingEngineContextValue = {
  mappedPage: CpmMappedPage;
};

const MappingContext = React.createContext<MappingEngineContextValue | null>(null);

export function MappingContextProvider({
  children,
  mappedPage,
}: {
  children: React.ReactNode;
  mappedPage: CpmMappedPage;
}) {
  const TypeForcedProvider: React.FC<{
    value: MappingEngineContextValue;
    children: React.ReactNode;
  }> = MappingContext.Provider as any; //eslint-disable-line @typescript-eslint/no-explicit-any

  const value = { mappedPage };
  return <TypeForcedProvider value={value}>{children}</TypeForcedProvider>;
}

export function useMappingContext(): MappingEngineContextValue {
  const val = React.useContext(MappingContext);

  if (val === null) {
    throw new Error('can not useMappingContext outside of MappingContext.Provider');
  } else {
    return val;
  }
}
