import {
  getDocumentOfTypeFromPageAndComponent,
  getFromPageByRef,
  modelsToRefs,
} from '../adapters/getters';
import type { CpmPageModel, Models, BrPage, ListData, BrComponent } from '../adapters/types';
import { mappingSchema, getBrComponentName, isMappedComponentName } from '../schema';
import type { CmsDataTypesIntersection, MappedComponent } from '../schema';
import { makeCmsComponentDocumentEnhancer } from '../transformers/enhancers';

function removeNullyFromArray<T>(xs: Array<T | null | undefined>): Array<T> {
  return xs.filter((x: T | null | undefined): x is T => !!x);
}

type $Ref = { $ref: string };
type NodeWithChildren = { children: $Ref[] };
type Page = CpmPageModel['page'];
type DocumentWithHeadline = {
  data: {
    headline: string;
  };
};

/**
 * Get a document by a reference ID
 */
const getDocumentByRef = (page: Page, $ref?: string) => {
  const ref = `${$ref}`.replace('/page/', '');
  return page[ref];
};

/**
 * Ensure a node has an array of child reference IDs (using the `children` property)
 */
const isNodeWithChildren = (node: unknown): node is NodeWithChildren => {
  return !!(
    node &&
    typeof node === 'object' &&
    'children' in node &&
    Array.isArray(node?.children) &&
    node.children.every((child: { $ref?: string }) => typeof child.$ref === 'string')
  );
};

/**
 * Ensure a node has headline
 */
const isDocumentWithHeadline = (node: unknown): node is DocumentWithHeadline => {
  return !!(
    node &&
    typeof node === 'object' &&
    'data' in node &&
    node.data &&
    typeof node.data === 'object' &&
    'headline' in node.data
  );
};

/**
 * Get the `main` content area document for the page
 */
const getMainDocument = (page: Page, rootDocument: unknown) => {
  if (!isNodeWithChildren(rootDocument)) {
    return;
  }

  const mainDocumentRef = rootDocument.children.find((child) => {
    const childDocument = getDocumentByRef(page, child.$ref);

    return childDocument && 'name' in childDocument && childDocument.name === 'main';
  });

  const mainDocument = getDocumentByRef(page, mainDocumentRef?.$ref);

  if (isNodeWithChildren(mainDocument)) {
    return mainDocument;
  }
};

function getItemsRefs(listData: ListData | null, componentBr: BrComponent) {
  const models = componentBr.getModels<Models>();
  const directRefs = modelsToRefs(models);
  const { itemsRef } = listData ?? { itemsRef: directRefs };

  return itemsRef ? removeNullyFromArray(itemsRef) : [];
}

/**
 * Traverses through the page model to find a component that has images that can be used for the SEO meta image
 */
export const getPagePrimaryImage = (pageBr: BrPage): string | undefined => {
  const validAssets = removeNullyFromArray(
    pageBr
      .getComponent('main')
      ?.getChildren()
      .filter((componentBr) => {
        const componentName = getBrComponentName(componentBr);
        if (!isMappedComponentName(componentName)) return false;

        // these are the only components we can presume will have SEO image compatable images
        return new Set(['Carousel', 'Full Width Image', 'Masthead Image']).has(componentName);
      })
      .map((componentBr) => {
        const componentName = getBrComponentName(componentBr);
        if (!isMappedComponentName(componentName)) return [];

        const componentSchema: MappedComponent = mappingSchema[componentName];
        const enhanceData = makeCmsComponentDocumentEnhancer<MappedComponent>(
          componentSchema,
          pageBr,
          // Stubbing the analytics function is fine here because we're only looking for images
          (linkUrl) => linkUrl || ''
        );

        // Grab all the documents attached to this component
        const listData =
          getDocumentOfTypeFromPageAndComponent<ListData>(
            ['ListDocument'],
            componentBr,
            pageBr
          )?.[0] ?? null;

        const itemsData = getItemsRefs(listData, componentBr)
          .map((ref) => getFromPageByRef<CmsDataTypesIntersection>(ref, pageBr))
          .filter((x: CmsDataTypesIntersection | null): x is CmsDataTypesIntersection => !!x);

        const instanceData =
          getDocumentOfTypeFromPageAndComponent<CmsDataTypesIntersection>(
            null,
            componentBr,
            pageBr
          )?.[0] ?? null;

        // run the documents through the enhancer
        const enhancedData = [...itemsData, instanceData]
          .filter(
            (data: CmsDataTypesIntersection | null): data is CmsDataTypesIntersection => !!data
          )
          .map((data) => enhanceData(data))

          // ignore any segmented documents
          .filter((data) => data.segmentIds.length === 0)

          // and extract the assets
          .map(({ cpmAssets }) => cpmAssets);

        return enhancedData;
      })
      .flat()
      .flat() ?? []
  ).map(({ aspectRatios }) => aspectRatios);

  // validAssets will now contain all useful assets, in page order.

  const firstAssetOfAspectRatio = validAssets.reduce(
    (acc, val) => ({
      ...acc,
      ...val,
    }),
    {}
  );

  return firstAssetOfAspectRatio['21x9']?.url ?? firstAssetOfAspectRatio['18x5']?.url;
};

/**
 * Getting Article title from the first Masthead component on the page
 */
export const getArticleTitle = (pageModel: CpmPageModel) => {
  const page = pageModel.page;

  const rootDoc = getDocumentByRef(page, pageModel.root.$ref);

  const mainDoc = getMainDocument(page, rootDoc);

  if (!mainDoc) {
    return '';
  }

  for (const containerDoc of mainDoc.children) {
    const container = getDocumentByRef(page, containerDoc?.$ref);

    if (container && 'name' in container && container.name === 'mastheadImage') {
      const documentRef = container.models?.document?.$ref;

      const document = getDocumentByRef(page, documentRef);

      return isDocumentWithHeadline(document) ? document.data.headline : '';
    }
  }
};
