import { useTabs } from './tabs.context';
import * as React from 'react';
import { useEffect } from 'react';
import cx from 'classnames';

type TabPanels = React.HTMLAttributes<HTMLDivElement>;

/**
 * The `TabPanels` component is used as a wrapper for the `TabPanel` components. It is important to use as it sets the count of components that helps set the focuse correctly.
 */
const TabPanels: React.FC<React.PropsWithChildren<TabPanels>> = ({
  children,
  className,
  ...rest
}) => {
  const { setCount } = useTabs();
  useEffect(() => {
    setCount(React.Children.count(children));
  }, [children, setCount]);

  return (
    <div className={cx('relative flex w-full', className)} {...rest}>
      {children}
    </div>
  );
};

TabPanels.displayName = 'TabPanels';

export { TabPanels };
export default TabPanels;
