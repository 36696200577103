import cx from 'classnames';
import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import type { TabsContext as TabsContextType } from './tabs.context';
import { TabsContext } from './tabs.context';
import { isRtl as isRTL } from '@dx-ui/utilities-get-language-direction';
import { sanitizeId } from './tabs.utils';

export type TabsBaseProps = {
  /** change the tabpanel on arrow key */
  changeWithArrow?: boolean;
  /** change the tabpanel on Up and Down keys. Works only if changeWithArrow is also true.   */
  useUpDownArrows?: boolean;
  /** id of tab that should be active by default */
  defaultActive?: string;
  /** callback with the id of the active tab */
  onTabChange?: (id?: string) => void;
  /** language code */
  lang?: string;
};

type Tabs = TabsBaseProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * `Tabs` is a container component that holds the tabs and their content. The set of Tab components are composed inside the Tabs component and have their own context. There should be a `TabButton` or a component that extends `TabButton` with an `id` prop that correlates to a `TabPanel` component.
 *
 * For a11y, there is a `changeWithArrow` prop that will change the tabpanel on arrow key, changing the default behavior to change the tab on enter key.
 *
 * ```jsx
 * <Tabs>
 *  <TabList>
 *    <TabButton id="tab1">Tab 1</TabButton>
 *    <TabButton id="tab2">Tab 2</TabButton>
 *  </TabList>
 *  <TabPanels>
 *    <TabPanel id="tab1">
 *      <p>Tab 1 content</p>
 *    </TabPanel>
 *    <TabPanel id="tab2">
 *      <p>Tab 2 content</p>
 *    </TabPanel>
 *  </TabPanels>
 * </Tabs>
 * ```
 */
export const Tabs = forwardRef<HTMLDivElement, Tabs>(
  (
    {
      changeWithArrow,
      useUpDownArrows,
      lang = 'en',
      defaultActive,
      onTabChange,
      className,
      children,
      ...rest
    },
    forwardedRef
  ) => {
    const [active, setActive] = useState(sanitizeId(defaultActive));
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const isRtl = isRTL(lang);
    const refs: HTMLButtonElement[] = React.useMemo(() => [], []);
    const addRef: TabsContextType['addRef'] = (ref) =>
      ref ? (refs.includes(ref) ? refs : refs.push(ref)) : refs;

    const changeActiveTab = React.useCallback(
      (id?: string) => {
        setActive(id);
        setActiveIndex(refs.findIndex((ref) => ref.id === id));

        if (onTabChange) {
          onTabChange(id);
        }
      },
      [onTabChange, refs]
    );

    const nextTabIndex = (currentIndex: number) =>
      currentIndex + 1 <= count - 1 ? currentIndex + 1 : 0;
    const previousTabIndex = (currentIndex: number) =>
      currentIndex - 1 >= 0 ? currentIndex - 1 : count - 1;

    const isModifiedKeyStroke = (e: React.KeyboardEvent<HTMLButtonElement>) =>
      Boolean(e.shiftKey || e.altKey || e.ctrlKey || e.metaKey);

    const onTabKeyDown: TabsContextType['onTabKeyDown'] = (e, id) => {
      if (isModifiedKeyStroke(e)) {
        return false;
      }

      const index = refs.findIndex((ref) => ref.id === id);
      let tabRef = 0;
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          if (e.key === 'ArrowUp' && !useUpDownArrows) {
            break;
          }
          e.preventDefault();
          tabRef = isRtl ? nextTabIndex(index) : previousTabIndex(index);
          if (changeWithArrow) {
            changeActiveTab(refs[tabRef]?.getAttribute('id') || id);
          }
          refs[tabRef]?.focus();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          if (e.key === 'ArrowDown' && !useUpDownArrows) {
            break;
          }
          e.preventDefault();
          tabRef = isRtl ? previousTabIndex(index) : nextTabIndex(index);
          if (changeWithArrow) {
            changeActiveTab(refs[tabRef]?.getAttribute('id') || id);
          }
          refs[tabRef]?.focus();
          break;
        case 'Enter':
        case ' ':
          changeActiveTab(id);
          break;
        case 'End':
          e.preventDefault();
          refs[count - 1]?.focus();
          break;
        case 'Home':
          e.preventDefault();
          refs[0]?.focus();
          break;
        // no default
      }
    };
    const onTabSelect: TabsContextType['onTabSelect'] = (id) => changeActiveTab(id);

    useEffect(() => {
      if (!defaultActive) {
        refs.length > 0 && changeActiveTab(refs[0]?.id);
      }
    }, [changeActiveTab, defaultActive, refs]);

    return (
      <TabsContext.Provider
        value={{
          active,
          activeIndex,
          addRef,
          count,
          onTabKeyDown,
          onTabSelect,
          setCount,
        }}
      >
        <div ref={forwardedRef} className={cx('relative', className)} {...rest}>
          {children}
        </div>
      </TabsContext.Provider>
    );
  }
);

Tabs.displayName = 'Tabs';

export default Tabs;
