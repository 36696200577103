export const NullDocumentBanner = ({ displayName }: { displayName: string }) => {
  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="bg-danger-alt border-border-alt flex flex-col items-center justify-center space-y-6 p-16 text-center font-bold">
      <h2 className="text-2xl" data-e2e="NullDocumentBanner">
        {displayName ? <code>{displayName}</code> : 'Unknown component'}
      </h2>

      <div>
        <p>Attached document has been deleted or is missing.</p>
        <p className="mt-2">Select a new document or this page will not publish.</p>
      </div>
    </div>
  );
};
