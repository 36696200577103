/**
Used to declare expected non-recoverable errors while mapping.

Use this when the error is:
- Part of normal operation
- Impossible to handle gracefully
- Should tell the content editor that they've done something wrong

An example would be an ImageHeadliner component with no Image attached
*/
export class CpmMappingError extends Error {
  constructor(message: string, options?: any) {
    super(message, options);
    this.name = 'CpmMappingError';
    this.message = message;
    Object.setPrototypeOf(this, CpmMappingError.prototype);
  }
}
