import type {
  Configuration as BrConfiguration,
  Reference as BrReference,
} from '@bloomreach/spa-sdk';
import type { ConductricsSelection } from '@dx-ui/framework-conductrics';
import type { MetricsInterface } from '@dx-ui/cpm-metrics';
import type { useBrComponentContext, usePartialBrPageContext } from '../adapters/hooks';
import type {
  BrPage,
  CpmPageModel,
  ListData,
  AddCampaignCodeToUrl,
  OscDomLink,
  CpmNavigationListDocument,
  ExperimentationConfiguration,
} from '../adapters/types';
import type { Issue } from '../hooks/use-issues';
import type { MappedComponentName, MappingSchema } from '../schema';
import type { EnhancedCmsComponentDocument } from '../transformers/enhancers';
import type { GuardedType, ValueOf } from '../typeHelpers';
import type { CpmMappedPage } from './cpmMappedPage';
import type {
  isAlignment,
  isBackgroundIllustration,
  isIllustrationBlock,
  isTextOverlay,
  isHeadingLevel,
  isPaddingAmount,
  isPosition,
} from './parseComponentParams';

type Reference = {
  ref?: BrReference;
};

export type UniversalComponentParams = Partial<{
  backgroundImage: boolean;
  backgroundIllustration: GuardedType<typeof isBackgroundIllustration>;
  illustration: GuardedType<typeof isIllustrationBlock>;
  backgroundParallax: boolean;
  topPadding: GuardedType<typeof isPaddingAmount>;
  bottomPadding: GuardedType<typeof isPaddingAmount>;
  borderTrim: boolean;
  oneLinkNoTx: boolean;
  anchorId: string;
  displayOption: string;
  display: string;
  theme: CmsBrandComponentTheme;
  textAlign: CmsAlignContent;
  textboxPosition: GuardedType<typeof isPosition>;
  imageDisplay: GuardedType<typeof isAlignment>;
  topHeadingLevel: GuardedType<typeof isHeadingLevel>;
  agentId?: string;
  variation?: string;
  text: GuardedType<typeof isTextOverlay>;
  orientation: CmsAlignContent;
}>;

export type FunctionMapOutputs<
  FunctionMap extends Record<string, (x: string | boolean | undefined) => string | boolean>
> = {
  [Prop in keyof FunctionMap]: ReturnType<FunctionMap[Prop]>;
};

export type ComponentParamsProperty<ComponentSchema extends ValueOf<MappingSchema>> =
  (ComponentSchema extends {
    componentParams: Record<string, (x: string | boolean | undefined) => string | boolean>;
  }
    ? FunctionMapOutputs<ComponentSchema['componentParams']>
    : Record<string, never>) &
    UniversalComponentParams;

export type CpmListData = Omit<ListData, 'links'> & { links: OscDomLink[] };

/**
 * The full data being passed to a mapping function
 *
 * Its type will change depending on the CPM Component being mapped over.
 * This ensures you can only map over data that will actually be available to
 * a specific CPM Component
 */
export type CpmMappingFunctionInput<ComponentName extends keyof MappingSchema> =
  (MappingSchema[ComponentName] extends { mappingKind: 'ListDocument' }
    ? {
        /** The underlying CMS list data */
        listData: CpmListData | null;
        /** The index of the current item being mapped */
        index: number;
        /** The total number of items being mapped */
        length: number;
      }
    : Record<string, never>) & {
    /** The instance of data being mapped over, this corresponds to an attached document in the CMS */
    data: EnhancedCmsComponentDocument & CpmNavigationListDocument & Reference;

    /** The Usage-specific data being passed in for use in all components. Provided to the MappingContext */
    mappedPage: CpmMappedPage;

    /** The underlying CMS page model */
    pageBr: ReturnType<typeof usePartialBrPageContext>;
    pageType: string;
    /** The underlying CMS component model */
    componentBr: ReturnType<typeof useBrComponentContext>;
    componentName: string;

    /** Any component-specific CMS parameters */
    componentParams: ComponentParamsProperty<MappingSchema[ComponentName]>;
    /** The name of the type of document being mapped over  */
    cmsDocumentType: MappingSchema[ComponentName]['cmsDocumentTypes'][number];

    /** Surface a non-terminal error to content editors  */
    addIssue: (issue: Issue) => void;
    /** Clear a non-terminal error to content editors  */
    clearIssue: (id: string) => void;

    /** Is the mapping function is running inside the Bloomreach editor **/
    isCPMEditor?: boolean;

    addCampaignCodeToUrl: AddCampaignCodeToUrl;
  };

export type CpmMandatoryMappingOutputFields =
  | {
      experimentationConfiguration?: ExperimentationConfiguration;
      links: OscDomLink[];
      segmentIds: string[];
    }
  | { filtered: false }
  | null;

/**
 * This function handles the logic for mapping from the BloomReach CMS data model to the usage specific data
 * model that you will pass to your visual components
 *
 * When isListData = false, this will be called once, with the data for the specific component
 * When isListData = true, this will be called multiple times, once for each item in the component
 *
 * @param data - the instance data to map
 * @param componentBr - data for the component, this will be the same for every call even when mapping items
 * @param mappedPage - data passed in from the MappingContextProvider to provide usage-specific information
 * @param issueCallbacks - functions to provide debug information when using the Editor Mode CMS. These are NoOp when running the live site
 * @param instanceMeta - metadata about the specific data instance being mapped over; including the actual cmsDocumentType & the index (when mapping items)
 *
 * @return - The mapped data
 */
export type CpmMappingFunction<
  ComponentName extends keyof MappingSchema,
  Output extends CpmMandatoryMappingOutputFields
> = (input: CpmMappingFunctionInput<ComponentName>) => Output;

// The types below allow us to preform better type narrowing inside CpmRender
type UnknownObject = Record<string, unknown>;

export type CpmListDocumentData<Output> = {
  data: never;
  items?: Output[];
  keyedItems: Record<string, Output>;
  listData: CpmListData | null;
  navigationList: never;
};

export type CpmDataDocumentData<Output> = {
  data: Output | null;
  items: never;
  listData: never;
  navigationList: never;
};

export type CpmNavigationListDocumentData<Output> = {
  data: never;
  items: never;
  listData: never;
  navigationList: Output | null;
};

export type CpmUnmappedData = {
  data: never;
  items: never;
  listData: never;
  navigationList: never;
};

/**
 * The data provided to a Cpm Compatible component
 * This will include the output of the component's mapData
 */
export type CpmMappedComponentData<
  ComponentName extends MappedComponentName,
  Output extends CpmMandatoryMappingOutputFields
> = {
  componentParams: ComponentParamsProperty<MappingSchema[ComponentName]>;
  pageType: string;
  componentName: string;
  mappedPage: CpmMappedPage;
  metrics: Partial<MetricsInterface>;
  isCPMEditor?: boolean;
  experimentationAgentIds: ExperimentationAgentIdsSet;
  experimentationAgents: ExperimentationAgents;
  mappingKind: 'ListDocument' | 'DataDocument' | 'NavigationListDocument' | 'Unmapped' | undefined;
} & (MappingSchema[ComponentName] extends { mappingKind: 'ListDocument' }
  ? CpmListDocumentData<Output>
  : MappingSchema[ComponentName] extends { mappingKind: 'DataDocument' }
  ? CpmDataDocumentData<Output>
  : MappingSchema[ComponentName] extends { mappingKind: 'NavigationListDocument' }
  ? CpmNavigationListDocumentData<Output>
  : MappingSchema[ComponentName] extends { mappingKind: 'Unmapped' }
  ? CpmUnmappedData
  : UnknownObject);

export type CpmComponentDefinition<
  ComponentName extends MappedComponentName,
  MappingOutput extends CpmMandatoryMappingOutputFields
> = {
  component: React.ComponentType<
    CpmMappedComponentData<
      ComponentName,
      ReturnType<CpmMappingFunction<ComponentName, MappingOutput>>
    >
  >;
  cpmComponentName: ComponentName;
  mapData: CpmMappingFunction<ComponentName, MappingOutput>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */

export type KeyedMappingDefinition = {
  [CmpntName in MappedComponentName as CmpntName]: CpmComponentDefinition<CmpntName, any>;
};

export type CpmPreMappedDataInstance<
  ComponentName extends MappedComponentName,
  MappingDefinition extends KeyedMappingDefinition
> = {
  componentName: ComponentName;
  mappedData: CpmMappedComponentData<
    ComponentName,
    ReturnType<MappingDefinition[ComponentName]['mapData']>
  >;
};

export type CpmServerBundle<MappingDefinition extends KeyedMappingDefinition> = {
  mappedPage: CpmMappedPage;
  contentPath: string;
  preMappedData: Record<
    string,
    Record<string, CpmPreMappedDataInstance<MappedComponentName, MappingDefinition>>
  >;
  experimentationAgentIds: ExperimentationAgentIds;
};

export type CpmClientBundle = {
  mappedPage: CpmMappedPage;
  contentPath: string;
  __cpmPageModel: CpmPageModel;
  cpmPage: BrPage;
  bloomReachConfiguration: BrConfiguration;
  experimentationAgentIds?: ExperimentationAgentIds;
};

export type CpmDataBundle<MappingDefinition extends KeyedMappingDefinition> =
  | CpmServerBundle<MappingDefinition>
  | CpmClientBundle;

export type IllustrationVariant = (typeof illustrationVariants)[number];
export const illustrationVariants = [
  'es-line-illustration',
  'hx-hexagon',
  'none',
  'wa-cocktail',
  'wa-feather',
  'wa-plate',
  'wa-stairwell',
  'ww-filigree',
] as const;

export type IllustrationBlockVariant = (typeof illustrationBlockVariant)[number];
export const illustrationBlockVariant = [
  'ey-accent',
  'ey-furniture',
  'hx-signature-default',
  'hx-signature-light',
  'hx-signature-dark',
  'ww-filigree-default',
  'ww-filigree-light',
  'ww-filigree-dark',
  'none',
];

export type ExperimentationAgentIdsSet = Set<string>;

export type ExperimentationAgentIds = Array<string>;

export type ExperimentationAgents = Record<string, ConductricsSelection>;

export type textOverlayVariant = (typeof textOverlayVariants)[number];

export const textOverlayVariants = ['forTheStay', 'none'];
