import { useMemo } from 'react';
import { getBaseUrl } from './constants';

export const getAssetPath = () => `${getBaseUrl()}/modules/assets`;

const getSvgPath = () => `${getAssetPath()}/svgs`;

export const getSvgAssetUrls = () => ({
  home: `${getSvgPath()}/guest/home.svg`,
  phone: `${getSvgPath()}/common/phone.svg`,
  search: `${getSvgPath()}/common/search.svg`,
  account: `${getSvgPath()}/guest/account.svg`,
});

export const getBrandSvg = (brandCode: string) => `${getSvgPath()}/logos/${brandCode}.svg`;

type SvgStyleProps = {
  size?: number;
  height?: number;
  width?: number;
};

export const useSvgStyle = ({ size = 0, width = 0, height = 0 }: SvgStyleProps) => {
  const h = size || height;
  const w = size || width;
  return useMemo(
    () => ({ height: `${h}px`, width: `${w}px`, marginTop: 5, marginInlineEnd: 19 }),
    [h, w]
  );
};
