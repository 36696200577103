import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './HeadingAnalyzer.module.css';

type HeadingNode = {
  headingLevel: number;
  index: number;
  text: string;
  children?: HeadingNode[];
  isMissing?: boolean;
};

const config = { childList: true, subtree: true };

export function HeadingAnalyzer() {
  const [headings, setHeadings] = useState<HeadingNode[] | undefined>();

  useEffect(() => {
    const container = document.querySelector('#__next');

    if (!container) {
      return;
    }

    setHeadings(createHeadingTree(container));

    const observer = new MutationObserver(() => setHeadings(createHeadingTree(container)));
    observer.observe(container, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!headings?.length) {
    return <p className="p-4">No headings detected</p>;
  }

  return (
    <div className="max-h-[30rem] min-h-40 overflow-scroll">
      <HeadingList>
        {headings.map((heading) => (
          <HeadingListItem key={heading.text} heading={heading} />
        ))}
      </HeadingList>
    </div>
  );
}

function HeadingList({ children }: { children: ReactNode }) {
  return (
    <ul className="relative pl-4 before:absolute before:left-2 before:top-0 before:h-full before:w-px before:bg-[#101010]">
      {children}
    </ul>
  );
}

function HeadingListItem({ heading }: { heading: HeadingNode }) {
  const handleClick = () => {
    const $el = document.querySelector(`[data-heading-analyzer-index="${heading.index}"]`);
    const outlineCssClass = styles.outline ?? '';

    $el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'center' });
    $el?.classList.add(outlineCssClass);

    setTimeout(() => {
      $el?.classList.remove(outlineCssClass);
    }, 4000);
  };

  const textStyle = cn('block text-left', { 'text-danger': heading.isMissing });
  const headingDescription = (
    <>
      <span className="font-bold">H{heading.headingLevel}:</span> {heading.text}
    </>
  );

  return (
    <li
      aria-label={`H${heading.headingLevel}: ${heading.text}`}
      className="relative px-4 py-2 before:absolute before:-left-2 before:top-5 before:h-px before:w-4 before:bg-[#101010]"
    >
      {heading.isMissing ? (
        <span className={textStyle}>{headingDescription}</span>
      ) : (
        <button className={textStyle} type="button" onClick={handleClick}>
          {headingDescription}
        </button>
      )}
      {heading.children?.length ? (
        <div className="mt-4">
          <HeadingList>
            {heading.children.map((child) => (
              <HeadingListItem key={child.text} heading={child} />
            ))}
          </HeadingList>
        </div>
      ) : null}
    </li>
  );
}

function createHeadingTree(doc: Document | Element) {
  const rootNode: HeadingNode = { headingLevel: 0, index: -1, text: '', children: [] };
  const tree = [rootNode];

  doc.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(($heading, index) => {
    if ($heading.getAttribute('data-heading-analyzer-ignore')) {
      return;
    }

    const headingLevel = parseInt($heading.tagName.slice(1));
    const node: HeadingNode = {
      headingLevel,
      index,
      text: $heading.textContent ?? 'Heading is missing text content!',
      children: [],
    };

    while (tree.length && tree[tree.length - 1]!.headingLevel >= headingLevel) {
      tree.pop();
    }

    while (tree.length < node.headingLevel) {
      const missingHeadingLevelNode: HeadingNode = {
        headingLevel: tree.length,
        index: -tree.length,
        text: 'Missing heading level!',
        children: [],
        isMissing: true,
      };
      tree[tree.length - 1]!.children?.push(missingHeadingLevelNode);
      tree.push(missingHeadingLevelNode);
    }

    $heading.setAttribute('data-heading-analyzer-index', `${index}`);

    tree[tree.length - 1]!.children?.push(node);
    tree.push(node);
  });

  return rootNode.children;
}
