import type * as React from 'react';
import { TabPanel } from '@dx-ui/osc-tabs';

type TabPanelProps = {
  tabPanelId: string;
  className?: string;
  children: React.ReactNode;
};

const VerticalTabPanel = ({ tabPanelId, className, children }: TabPanelProps) => (
  <TabPanel id={tabPanelId} key={tabPanelId} className={className}>
    {children}
  </TabPanel>
);

export { VerticalTabPanel };
export default VerticalTabPanel;
