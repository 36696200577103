import type { CpmMappedPage } from '@dx-ui/cpm-sdk';
import { capitalizeHyphen, getPageDetails, getPageType } from '@dx-ui/cpm-metrics';
import { getBrandRouteParams } from '@dx-ui/cpm-mapping-shared';

export function getBrandTaggingValues({
  analyticsTagging,
  languageCode,
  pageType,
  pathname,
}: CpmMappedPage) {
  const brandTagging = getPageType(pageType);
  const adobePageType = analyticsTagging.adobePageType || brandTagging.adobePageType;
  const postfix: string[] = [];

  if (analyticsTagging.primaryCategory) {
    postfix.push(capitalizeHyphen(analyticsTagging.primaryCategory));
  } else {
    postfix.push(brandTagging.primaryCategory);
  }

  // CMS is overriding page details
  if (analyticsTagging.pageDetailOne) {
    // Campaign code postfix only includes two "page detail" levels
    postfix.push(
      capitalizeHyphen(analyticsTagging.pageDetailOne),
      capitalizeHyphen(analyticsTagging.pageDetailTwo)
    );
  } else {
    // Fallback to brand tagging
    const { brandPath } = getBrandRouteParams(pathname, languageCode);
    const splitBrandPath = (brandPath ?? '').split('/').filter((item) => item !== '');
    const brandPageDetails = getPageDetails(splitBrandPath);

    postfix.push(brandPageDetails.pageDetail1, brandPageDetails.pageDetail2);
  }

  return {
    adobePageType,
    cidPostfix: postfix.filter((item) => item !== '').join('-'),
  };
}
