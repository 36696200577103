//TODO: unify with ErrorBlock
export const GenericError = ({
  description1,
  description2 = '',
}: {
  description1: string;
  description2: string;
}) => {
  return (
    <div className="m-4 flex h-screen flex-col items-start justify-start p-4 lg:items-center lg:justify-center">
      <h2 className="mb-10 text-2xl font-bold md:mb-20" data-e2e="pageSubTitle">
        Error: {description1}
      </h2>
      <h3>{description2}</h3>
    </div>
  );
};
