import type * as React from 'react';
import type { DialogOverlayProps } from '@reach/dialog';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import cx from 'classnames';
import { ControlClose } from '@dx-ui/osc-controls';
import { useTranslation } from 'next-i18next';

export type Dialog = {
  /** aria label for the modal */
  ariaLabel: string;
  /** The size of the modal */
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full' | 'container';
  title?: string;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  contentClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
  bottomContent?: React.ReactNode;
  /** Hides the close button and prevents the modal from closing when the user clicks outside the modal or presses the Escape key */
  preventCloseOnDismiss?: boolean;
} & DialogOverlayProps;

/**
 * Used to present content above the page and sets focus on first interactive element, which is the Close button if a title is provided.
 */
export const Dialog: React.FC<React.PropsWithChildren<Dialog>> = ({
  ariaLabel,
  children,
  contentClassName,
  //Reach UI definition is wrong. This will be fixed when v2 dialog fully ships
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onDismiss,
  title,
  className,
  size = 'md',
  bottomContent,
  preventCloseOnDismiss,
  ...rest
}) => {
  const showCloseButton = onDismiss && !preventCloseOnDismiss;

  return (
    <DialogOverlay
      allowPinchZoom={true}
      className="dialog-overlay overflow-hidden"
      {...(!preventCloseOnDismiss && { onDismiss })}
      {...rest}
    >
      <div className="h-full overflow-auto sm:py-4 lg:py-12">
        <DialogContent
          className={cx(
            'dialog-content my-0 flex flex-col',
            {
              'max-w-sm': size === 'sm',
              'max-w-md': size === 'md',
              'max-w-lg': size === 'lg',
              'max-w-xl': size === 'xl',
              'max-w-2xl': size === '2xl',
              'max-w-3xl': size === '3xl',
              'max-w-4xl': size === '4xl',
              'max-w-5xl': size === '5xl',
              'max-w-6xl': size === '6xl',
              'max-w-full': size === 'full',
              'w-full lg:max-w-[min(90%,calc(1440px-5rem))]': size === 'container',
            },
            contentClassName
          )}
          aria-label={ariaLabel}
        >
          {title && (
            <div className="border-border relative mb-2 border-b pb-3.5">
              <h2
                className="brand-wa:font-light brand-wa:text-text brand-wa:text-xl brand-wa:md:text-2xl brand-ou:text-primary mx-4 text-center font-bold"
                aria-label={ariaLabel}
                aria-live="polite"
                data-osc-product="osc-dialog-title"
              >
                {title}
              </h2>
              {showCloseButton ? <CloseButton onDismiss={onDismiss} /> : null}
            </div>
          )}
          {!title && showCloseButton ? (
            <div className="relative py-1">
              {showCloseButton ? <CloseButton onDismiss={onDismiss} /> : null}
            </div>
          ) : null}
          <div className={cx('p-1', className)}>{children}</div>
          {bottomContent}
        </DialogContent>
      </div>
    </DialogOverlay>
  );
};

function CloseButton({ onDismiss }: Pick<Dialog, 'onDismiss'>) {
  const { t } = useTranslation('osc-dialog');
  return (
    <ControlClose onClick={onDismiss} className="absolute -end-2 -top-1.5" label={t('close')} />
  );
}

export default Dialog;
