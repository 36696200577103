import * as React from 'react';

type Props = React.PropsWithChildren<{
  theme: 'light' | 'dark' | '';
  enabled: boolean;
}>;

export function HamptonHexVisual({ theme, enabled, children }: Props) {
  if (enabled && theme === 'light') {
    return (
      <React.Fragment>
        <svg
          aria-hidden="true"
          data-testid="hampton-hex-light"
          className="invisible absolute left-0 top-0 w-1/3 md:visible"
          viewBox="0 0 552 107"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V80.5744L56.1245 96.3995C81.1038 103.432 106.948 107 133 107V106.966C159.052 106.966 184.931 103.398 209.91 96.3648L551.656 0H0Z"
            fill="white"
          />
        </svg>

        {children}
      </React.Fragment>
    );
  }

  if (enabled && theme === 'dark') {
    return (
      <React.Fragment>
        <svg
          aria-hidden="true"
          data-testid="hampton-hex-dark"
          className="invisible absolute left-0 top-0 w-1/3 md:visible"
          viewBox="0 0 552 107"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.000488281V80.5749L56.1245 96.4C81.1038 103.433 106.948 107.001 133 107.001V106.966C159.052 106.966 184.931 103.398 209.91 96.3653L551.656 0.000488281H0Z"
            fill="#002666"
          />
        </svg>

        {children}
      </React.Fragment>
    );
  }

  return children;
}
