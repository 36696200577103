import type {
  ImagePointer,
  ImageData,
  ImageWithVariants,
  CmsImageVariantScreenSize,
  ImageVariantNames,
  ImageCompound,
  BrComponent,
  BrPage,
  BrReference,
  BaseData,
  Models,
  AssetVariants,
  Asset,
  AssetVariantNames,
} from './types';
import type { BrandImageVariantSize } from '@dx-ui/gql-types';
import type { CmsDocumentType } from '../schema';

export const getFromPageByRef = <T>(
  ref: string | BrReference | null,
  page: BrPage | null
): T | null => {
  if (ref === null) {
    return null;
  }
  if (page === null) {
    return null;
  }

  const content = page.getContent(ref);

  if (!content) {
    return null;
  }

  const data = content.getData() as T;

  return {
    ...data,
    ref,
  };
};

interface ImageVariant {
  _id: string;
  __typename: string;
  url: string;
  size: BrandImageVariantSize;
}

function mapVariantScreenSizeToBrandImageSize(
  name: CmsImageVariantScreenSize | null
): BrandImageVariantSize {
  switch (name) {
    case 'dxcms:mobile':
      return 'xs';
    case 'dxcms:tablet':
      return 'sm';
    case 'dxcms:desktop':
      return 'md';
    default:
      return 'md';
  }
}

export const getImageWithVariantsFromPage = (
  image: ImagePointer,
  variant: ImageVariantNames,
  page?: BrPage
): ImageWithVariants | null => {
  if (!image) {
    return null;
  }

  const content = getFromPageByRef<ImageData | null>(image.imageRef ?? null, page ?? null);

  if (!content) {
    return null;
  }

  const contentVariants = content?.image?.variants?.[variant];

  if (!contentVariants) {
    return null;
  }

  const variants = contentVariants
    .map((img) => {
      const _id = img.id;

      if (!_id) {
        return null;
      }

      return {
        _id,
        url: img.cdnLink,
        size: mapVariantScreenSizeToBrandImageSize(img.name ?? null),
        __typename: 'BrandImageVariant',
      };
    })
    .filter(Boolean) as ImageVariant[];

  if (variants.length === 0) {
    return null;
  }

  const firstVariant = variants[0];

  if (!firstVariant) {
    return null;
  }

  const _id = firstVariant._id;

  return {
    _id, // GridThreeSixNine uses id of first variant
    altText: content.altText ?? '',
    variants,
    __typename: 'BrandImage',
  };
};

export const makeGetImageForPage =
  (page: BrPage) =>
  (image: ImagePointer, variant: ImageVariantNames): ImageWithVariants | null =>
    getImageWithVariantsFromPage(image, variant, page);

export const getImageCompound = (
  image: ImagePointer,
  variant: ImageVariantNames,
  page?: BrPage
): ImageCompound | null => {
  if (!image?.imageRef) {
    return null;
  }

  const content = getFromPageByRef<ImageData | null>(image.imageRef, page ?? null);

  if (!content) {
    return null;
  }

  const compoundedImage = getImageWithVariantsFromPage(image, variant, page);

  if (compoundedImage === null) {
    return null;
  }

  return {
    _id: content.id,
    image: compoundedImage,
    expansionPanelImage: null,
    caption: image.caption ?? '',
    captionLink: image.captionLink ?? '',
    campaignId: image.campaignId ?? undefined,
  };
};

export const getAssetImage = (
  image: ImagePointer,
  page: BrPage,
  variantsSizes: readonly AssetVariantNames[]
) => {
  const ref = image?.assetRef || image?.imageRef;

  if (!ref) {
    return null;
  }

  const content = getFromPageByRef<ImageData | null>(ref, page);

  if (!content || !content.image?.variants) {
    return null;
  }

  // Only include required variants for component
  const variants: Record<keyof AssetVariants, Asset> = Object.entries(
    content.image.variants
  ).reduce((variants, [key, images]) => {
    const size = key as AssetVariantNames;

    if (!variantsSizes.includes(size) || !images.length) {
      return variants;
    }

    // Asset type images only ever have 1 item so drop the array
    variants[size] = images[0];

    return variants;
  }, {} as Record<keyof AssetVariants, Asset>);

  if (!Object.keys(variants).length) {
    return null;
  }

  return {
    variants,
    id: content.id,
    altText: content.altText ?? '',
    caption: image.caption ?? '',
    captionLink: image.captionLink ?? '',
    campaignId: image.campaignId,
  };
};

export function getDocumentOfTypeFromPageAndComponent<T extends BaseData>(
  documentTypes: CmsDocumentType[] | null,
  component: BrComponent,
  page: BrPage
): T[] | null {
  const models = component.getModels();

  if (documentTypes?.length) {
    const documentTypesWithPrefix = documentTypes.map((documentType) => `dxcms:${documentType}`);
    let data: T | null = null;

    //This is a slightly weird way of selecting that will return the *LAST*
    //matching element found. Once our e2e tests are more stable, we should review
    //if this is actually useful behaviour, or if we can just use a map here
    //and return the whole array

    for (const document of Object.values(models)) {
      if (document && document?.$ref) {
        const docData = page.getContent(document)?.getData();

        if (documentTypesWithPrefix.includes(docData?.contentType)) {
          data = docData as unknown as T;
        }
      }
    }

    if (data) {
      return [data];
    } else {
      return null;
    }
  } else {
    if (models.document) {
      return Object.values(models)
        .map((document) => {
          if (document && document?.$ref) {
            const docData = page.getContent(document)?.getData();

            return docData;
          } else {
            return null;
          }
        })
        .filter(Boolean) as unknown as T[];
    }
  }

  return null;
}

/**
 * Convert a document key to an integer so it can be sorted properly
 * @param key: document key e.g. "document" | "document1" | "document2"
 */
const convertDocumentKeyToInt = (key: string) => {
  // Remove "document" from the key so we can parse the number
  const value = key.replace('document', '0');
  return parseInt(value, 10);
};

export const modelsToRefs = (models: Models) => {
  return Object.keys(models)
    .sort((a, b) => (convertDocumentKeyToInt(a) < convertDocumentKeyToInt(b) ? -1 : 1))
    .map((modelKey) => models[modelKey]);
};

/**
 * Map document references to the document number for inline lists
 */
export const getDocumentKeyNumbersMap = (models: Models) => {
  const documentNumbers = Object.keys(models).reduce((keyNumbers, key) => {
    const $ref = models[key]?.$ref;

    if (!$ref) {
      return keyNumbers;
    }

    let documentNumber = convertDocumentKeyToInt(key);

    // "document" will be converted into 0, so we need to reassign it to 1
    if (documentNumber === 0) {
      documentNumber = 1;
    }

    return {
      ...keyNumbers,
      [$ref]: documentNumber,
    };
  }, {} as Record<string, number>);

  return new Map(Object.entries(documentNumbers));
};
