import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const GuestDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    guestId
    userName
    hhonors {
      hhonorsNumber
      isFamilyAndFriends
      isLongTenure10
      isSMBMember
      isTeamMember
      isTeamMemberSpouse
      isOwner
      isLongTenure20
      isOwnerHGV
      isOwnerHGVNew
      programAccountSummary(filter: {accountStatus: "active"}) {
        accountId
        accountName
      }
      summary {
        points: totalPointsFmt
        tier
        tierName
        totalPoints
        totalPointsFmt
      }
      packages {
        packageName
      }
    }
    personalinfo {
      name {
        firstName @toTitleCase
        lastName @toTitleCase
        nameFmt @toTitleCase
        nameLocalizations {
          firstName
          lastName
          nameFmt
        }
      }
      emails(filter: {preferred: true}) {
        emailAddressHashed
      }
    }
  }
}`,
  originalOpName: 'guest',
};
export function useGuestQuery<TData = Types.GuestQuery, TError = DXError>(
  variables: Types.GuestQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestQuery, TError, TData>({
    queryKey: [GuestDocument, variables],
    ...options,
  });
}
export function serverSideGuestQuery(
  queryClient: QueryClient,
  variables: Types.GuestQueryVariables,
  options?: FetchQueryOptions<Types.GuestQuery>
) {
  return queryClient.fetchQuery<Types.GuestQuery>({
    queryKey: [GuestDocument, variables],
    ...options,
  });
}
