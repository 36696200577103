import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

type Variations = Set<string>;
type Agents = Map<string, Variations>;
type ConductricsAgentsContextValue = [Agents, Dispatch<SetStateAction<Agents>>];

const noop = () => undefined;
const initialMap = new Map();
const defaultValue: ConductricsAgentsContextValue = [initialMap, noop];

const ConductricsAgentsContext = createContext(defaultValue);

export function ConductricsAgentsContextProvider({ children }: { children: ReactNode }) {
  const state = useState<Agents>(initialMap);

  return (
    <ConductricsAgentsContext.Provider value={state}>{children}</ConductricsAgentsContext.Provider>
  );
}

export function useConductricsAgentsContext() {
  const context = useContext(ConductricsAgentsContext);

  if (context === undefined) {
    throw new Error(
      `ConductricsAgentsContext is undefined. This hook can only be used in apps that are wrapped in ConductricsAgentsContextProvider.`
    );
  }

  return context;
}

export function registerMappedAgents(
  context: ConductricsAgentsContextValue,
  mappedAgentIds: Set<string>
) {
  const [agents, setAgents] = context;
  const nextAgents = cloneAgentsMap(agents);
  let modified = false;

  for (const av of mappedAgentIds) {
    const [agentId, variation] = av.split(':');

    if (!agentId || !variation) {
      continue;
    }

    const variations = nextAgents.get(agentId);

    if (!variations) {
      nextAgents.set(agentId, new Set([variation]));
      modified = true;
      continue;
    }

    if (!variations.has(variation)) {
      variations.add(variation);
      modified = true;
    }
  }

  if (modified) {
    setAgents(nextAgents);
  }
}

function cloneAgentsMap(agents: Agents) {
  const clonedMap = new Map();

  for (const [key, value] of agents) {
    const clonedVariations = new Set([...value]);

    clonedMap.set(key, clonedVariations);
  }

  return clonedMap;
}
