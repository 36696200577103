export const NewContentBanner = ({ displayName }: { displayName: string }) => {
  return (
    <div
      className="bg-info-alt border-border-alt relative flex flex-col items-center justify-center p-8 text-center"
      style={{ borderStyle: 'dashed', borderTopWidth: 2, padding: 64 }}
    >
      <h2 className="text-2xl font-bold" data-e2e="pageSubTitle">
        {displayName ? <code>{displayName}</code> : 'Unknown component'}
      </h2>

      <p>Please attach a document to this component.</p>
    </div>
  );
};
