import type { AssetVariantNames } from '../adapters/types';
import type { StructuredAsset, ImageAsset } from '../transformers/enhancers';

type StructuredAssetWithAspectRatioGuaranteed<AspectRatio extends AssetVariantNames> =
  StructuredAsset & {
    aspectRatios: Partial<Record<AssetVariantNames, ImageAsset>> & Record<AspectRatio, ImageAsset>;
  };

export function selectFirstAssetWithAspectRatio<AspectRatio extends AssetVariantNames>(
  aspectRatio: AssetVariantNames,
  cpmAssets: (StructuredAsset | undefined)[]
): StructuredAssetWithAspectRatioGuaranteed<AspectRatio> | null {
  for (const asset of cpmAssets) {
    if (asset?.aspectRatios?.[aspectRatio]) {
      return asset as StructuredAssetWithAspectRatioGuaranteed<AspectRatio>;
    }
  }

  return null;
}

export function selectLastAssetWithAspectRatio<AspectRatio extends AssetVariantNames>(
  aspectRatio: AssetVariantNames,
  cpmAssets: (StructuredAsset | undefined)[]
): StructuredAssetWithAspectRatioGuaranteed<AspectRatio> | null {
  const reversed = [...cpmAssets].reverse();
  for (const asset of reversed) {
    if (asset?.aspectRatios?.[aspectRatio]) {
      return asset as StructuredAssetWithAspectRatioGuaranteed<AspectRatio>;
    }
  }

  return null;
}
