export const isPortfolioRedirect = (segments: string[]) => {
  if (segments.length > 0) {
    const firstSegment = segments[0];

    if (
      firstSegment === 'all-inclusive' ||
      firstSegment === 'beach' ||
      firstSegment === 'brands' ||
      firstSegment === 'connecting-rooms' ||
      firstSegment === 'destination-guides' ||
      firstSegment === 'resorts' ||
      firstSegment === 'pet-friendly' ||
      firstSegment === 'new-hotels' ||
      firstSegment === 'travel' ||
      firstSegment === 'events' ||
      firstSegment === 'travel-savings' ||
      firstSegment === ''
    ) {
      return true;
    }

    return false;
  }

  return true;
};
