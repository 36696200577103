export const LivSmartAccent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 48"
    width="100%"
    height="auto"
    data-testid="livsmart-accent"
    aria-hidden="true"
  >
    <clipPath id="a">
      <path d="m0 0h1440v48h-1440z" />
    </clipPath>
    <path d="m0 0h1440v48h-1440z" fill="#4d583e" />
    <g clipPath="url(#a)">
      <path d="m-2.52-1.92h909.13v51.35h-909.13z" fill="#4d583e" />
      <path d="m932.71-13.4h419.13v75.19h-419.13z" fill="#4d583e" />
      <path d="m866.85 197h-6.05v-497.5h6.05z" fill="#643f56" />
      <path d="m477.39-188.45h440.59v310.33h-440.59z" fill="#e16435" />
      <path
        d="m714.8 120.14h-21.92c-4.91 0-9.63-1.95-13.1-5.42l-1.66-1.66c-6.22-6.21-7.22-15.93-2.4-23.27l34.74-52.98c2.77-4.22 3.7-9.4 2.56-14.32l-35.3-153.51c-.53-2.32-.62-4.72-.24-7.07l.9-5.65c1.25-7.82 7.33-13.99 15.13-15.35l11.51-2c6.1-1.06 12.32 1 16.59 5.48l29.89 31.39c3.04 3.19 7.12 5.2 11.51 5.65l67.36 7.01c4.1.43 8.23-.53 11.73-2.71l29.35-18.32c2.06-1.29 3.85-2.97 5.27-4.95l15.46-21.64c5.96-8.35 17.57-10.26 25.9-4.27l.96.69c2.33 1.67 4.23 3.86 5.57 6.4l8.35 15.81c2.05 3.88 2.65 8.37 1.71 12.65l-29.36 132.85c-.74 3.34-.54 6.83.58 10.06l29.23 84.43c.68 1.95 1.02 4 1.02 6.06 0 11.27-9.97 19.93-21.13 18.35l-16.71-2.36c-.82-.12-1.64-.18-2.47-.18l-181.04-1.19z"
        fill="#643f56"
      />
      <path
        d="m917.98-188.45c14.14 0 25.61 11.46 25.61 25.61v449.95h-51.21v-449.95c0-14.14 11.46-25.61 25.61-25.61z"
        fill="#f59e4f"
      />
      <path
        d="m1439.72 45.31c-56.72 15.99-89.56 52.88-108.51 88.36 30.66-16.64 71.56-44.53 108.51-88.36z"
        fill="#643f56"
      />
      <path
        d="m1204.44 385.71c-4.47-90.58-15.69-185.03-28.7-274.93-5.79-39.28-12.02-78.5-18.61-117.65-.65-3.83-.1-7.76 1.5-11.3 10.67-23.64 18.86-46.95 24.92-69.85 0-.03 0-.06 0-.1-6.98-43.49-7.52-96.92 8.73-156.48.49-1.8 1.02-3.61 1.54-5.42-2.33-17.87-5.68-35.24-9.88-52-31.69-126.45-110.15-219.67-139.2-250.89-4.48-4.81-12.52-2.27-13.47 4.23-45.61 309.2 77.36 487.07 112.85 530.98 2.19 2.71 3.6 5.95 4.11 9.4 5.96 40.13 11.54 80.31 16.65 120.56 11.16 89.64 20.48 183.96 23.14 274.07l.69 54.85h17.53l-1.79-55.46z"
        fill="#e16435"
      />
      <path
        d="m1252.72-374.49c-28.94 43.34-47.58 85.18-58.91 124.46 6.56 50.36 4.81 104.82-10.27 161.9 2.18 13.59 4.99 26.22 8.11 37.74 7.26 26.78 16.21 47.66 22.89 61.2 2.16 4.37 3.05 9.23 2.56 14.08-6.74 67.06-12.5 134.21-17.46 201.32-6.8 94.65-11.46 189.36-14.04 284.28h37.78c-2.76-117.63-1.97-236.31 1.76-354.01 1.46-42.6 3.25-85.2 5.39-127.79.26-5.19 2.06-10.17 5.19-14.32 1.4-1.86 2.9-3.89 4.49-6.09 21.74-30.21 58.89-92.28 66.83-172.23 6.13-61.69-5.12-134-54.32-210.54z"
        fill="#643f56"
      />
      <path
        d="m1347.33-44.64c-30.65 49.29-46.7 102.65-55.01 141.76-3.1 14.6-5.12 27.19-6.42 36.85-.48 3.58-1.6 7.04-3.26 10.26 0 .02-.02.03-.03.05-42.43 82.86-70.03 174.62-76.18 267.84-.25 6.48-.97 22.35-1.22 29.15h21.88c.06-6.52.11-21.71.17-28.17.21-5.97 1.21-22.13 1.48-28.15 5.9-75.74 26.14-150.5 57.04-221.13 3.15-7.2 8.9-12.92 16.14-15.98 7.84-3.32 17.84-7.96 29.28-14.17 18.95-35.48 51.8-72.37 108.51-88.36 41.9-49.7 78.71-119.87 89.68-217.42-88.85 16.29-145.72 69-182.07 127.46z"
        fill="#f59e4f"
      />
      <path
        d="m1510.68 37.21c-27.06-.7-50.55 2.35-70.96 8.1-36.95 43.83-77.85 71.72-108.51 88.36-15.97 29.9-22.08 58.77-24.29 73.29-.49 3.24-1.79 6.28-3.74 8.91-18.9 25.39-35.19 52.71-48.34 81.49-24.78 54.63-37.14 114.42-38.26 174.38h15.6c-.5-38.73 3.75-77.55 13.19-115.23 11.93-46.46 32.14-90.46 58.76-130.29 3.61-5.4 9.6-8.7 16.1-8.88 35.61-.95 129.35-18.9 195.91-171.51 1.74-4-1.11-8.5-5.46-8.61z"
        fill="#e16435"
      />
      <g fill="#f59e4f">
        <path d="m1207.57 441.27c-2.02-91.43-31.77-190.86-73.91-286.52-1.72-3.9-2.49-8.13-2.22-12.39 3.08-48.07-.09-226.23-198.64-346.63-1.88-1.14-4.31.32-4.18 2.51 12.59 218.75 146.95 321.86 189.06 348.97 4.58 2.95 8.18 7.19 10.26 12.22 39.09 94.76 65.96 192.67 66.03 281.85h13.59z" />
        <path d="m489.87-200.93c-14.14 0-25.61 11.46-25.61 25.61v225.94h18.28c6.59 0 11.93 5.34 11.93 11.93v17.71c0 6.59-5.34 11.93-11.93 11.93h-18.28v182.43h51.21v-449.94c0-14.14-11.47-25.61-25.61-25.61z" />
        <path d="m150.88-14.73c-14.77-8.37-33.17-6.98-46.51 3.62-7.38 5.86-16.53 9.06-25.96 9.06h-4.88c-13.51 0-26.18 6.54-34 17.55l-24.98 35.12h99.49l34.35-61.52c.75-1.34 1.59-2.62 2.49-3.84z" />
      </g>
      <path d="m439.64 47c-12.78-15.15-31.13-24.25-50.64-25.47v29.1h53.7z" fill="#90add2" />
      <path
        d="m158.92-9.99c3.62 2.15 7.54 3.75 11.63 4.73l78.96 18.88c2.07.49 4.07 1.16 6 1.95 14.13-3.04 29.12-1.85 42.96 3.92 10.34 4.31 21.55 6.15 32.72 5.38l48.31-3.33c3.18-.22 6.35-.21 9.49 0v-9.35c0-20.14-16.33-36.47-36.47-36.47h-11.58c-12.06 0-23.34-5.96-30.13-15.93l-13.21-19.38c-8.94-13.13-25.36-18.95-40.57-14.4l-13.74 4.11c-9.89 2.96-18.06 9.97-22.49 19.29-5.9 12.41-18.27 20.45-32 20.81l-9.51.25c-11.34.29-21.79 5.84-28.41 14.84.26.15.53.27.79.43l7.25 4.32z"
        fill="#643f56"
      />
      <path
        d="m255.52 15.56c-1.93-.79-3.93-1.45-6-1.95l-78.96-18.87c-4.1-.98-8.02-2.57-11.63-4.73l-7.25-4.32c-.26-.15-.53-.28-.79-.43-.9 1.22-1.74 2.5-2.49 3.84l-34.35 61.52h86.62l23.74-19.87c9.15-7.66 19.86-12.77 31.11-15.19z"
        fill="#90add2"
      />
      <path
        d="m379.51 21.53-48.31 3.33c-11.18.77-22.38-1.07-32.72-5.38-13.84-5.77-28.83-6.96-42.96-3.92 11.2 4.55 19.99 13.78 23.88 25.44l3.2 9.62h106.4v-29.1c-3.14-.2-6.31-.21-9.49 0z"
        fill="#f59e4f"
      />
      <path
        d="m255.52 15.56c-11.25 2.42-21.96 7.53-31.11 15.19l-23.74 19.87h81.93l-3.2-9.62c-3.89-11.66-12.68-20.89-23.88-25.44z"
        fill="#4d583e"
      />
    </g>
  </svg>
);
