import type { DigitalData } from '@dx-ui/config-metrics';

export const digitalData: DigitalData = {
  event: [],
  page: {
    attributes: {
      expType: 'New Hilton',
      siteExperience: 'R',
    },
    category: {
      experience: 'Browser',
      siteType: 'B',
    },
    pageInfo: {},
  },
  user: [],
};

export const defaultPageData = {
  brandCode: '',
  brandName: '',
  lang: 'en',
  pageName: 'Home',
  destinationUrl: '',
};
