import type { MappedComponentName } from '../schema';
import type {
  CpmMappedComponentData,
  CpmComponentDefinition,
  CpmMappingFunction,
  CpmMandatoryMappingOutputFields,
} from './types';

export function createCpmComponentDefinition<
  ComponentName extends MappedComponentName,
  MappingOutput extends CpmMandatoryMappingOutputFields
>(
  cpmComponentName: ComponentName,
  mapData: CpmMappingFunction<ComponentName, MappingOutput>,
  component: React.ComponentType<
    CpmMappedComponentData<
      ComponentName,
      ReturnType<CpmMappingFunction<ComponentName, MappingOutput>>
    >
  >
): CpmComponentDefinition<ComponentName, MappingOutput> {
  return {
    cpmComponentName,
    mapData,
    component,
  };
}
