import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';

export default createCpmComponentDefinition(
  'Experimentation',

  function mapData({ data }) {
    return data;
  },

  function Experimentation({ items, listData, componentParams }) {
    const filteredItems = useSegmentedItems(items ?? []);
    return (
      <>
        <p>{JSON.stringify(componentParams)}</p>
        {listData ? <p>{JSON.stringify(listData)}</p> : null}
        {filteredItems.length ? (
          <ul>
            {filteredItems.map((item) => (
              <li key={item.id}>{JSON.stringify(item)}</li>
            ))}
          </ul>
        ) : null}
      </>
    );
  }
);
