export const Goals = {
  SignInSuccess: 'g-IqzR2S0Ai5',
  JoinHiltonHonorsTAB: 'g-2Mx3NVgKEo',
  JoinForFreeCTA: 'g-YALuwfEhWZ',
  EngagementWithVideoHONORS: 'g-R6YcyFiTIc',
  HiltonHonorsAppTAB: 'g-ceePuT4jz0',
  EngagementWithVideoAPP: 'g-eji8qd4d2v',
  DownloadForFreeCTA: 'g-JvISPYafgq',
  SignInCTA: 'g-330wZxWuoO',
  HiltonGetaways: 'g-hilton-getaways',
  FindAHotelClick: 'g-DD3G7VqYaV',
  EditBrandSearchWidget: 'g-C6bnRUoR2d',
} as const;
