import { useCpmConductricsAgents } from '../mappingEngine/ConductricsWrapper';
import type { ExperimentationItem } from '../utils/filter-experimentation-items';
import { filterExperimentationItems } from '../utils/filter-experimentation-items';

export function useExperimentation<Item extends ExperimentationItem>(
  items: Item[],
  modifyItem?: (item: Item) => Item
) {
  const agents = useCpmConductricsAgents();
  const filtered = filterExperimentationItems(agents, items);

  if (!modifyItem) {
    return filtered;
  }

  return filtered.map(modifyItem);
}
