import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

export type PreviewSegmentIds = string[];

export type PreviewSegmentsContext = [
  PreviewSegmentIds,
  Dispatch<SetStateAction<PreviewSegmentIds>>
];
const noop = () => undefined;

const PreviewSegmentsContext = createContext<PreviewSegmentsContext>([[], noop]);

const storageKey = 'cpmPreviewSegments';

/**
 * Save filtered segments in browser session storage. We need to do this because the Bloomreach editor reloads the page
 * when components are added/removed which would reset the state.
 */
export function PreviewSegmentsContextProvider({ children }: { children: ReactNode }) {
  const state = useState<PreviewSegmentIds>([]);
  const [segmentIds, setSegmentIds] = state;
  const initialized = useRef(false);

  useEffect(() => {
    try {
      const segmentsStorage = sessionStorage.getItem(storageKey);
      const previewSegments = segmentsStorage ? JSON.parse(segmentsStorage) : [];

      if (!initialized.current) {
        initialized.current = true;
        setSegmentIds(previewSegments);
      } else {
        sessionStorage.setItem(storageKey, JSON.stringify(segmentIds));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to save preview segment IDs to session storage', error);
    }
  }, [segmentIds, setSegmentIds]);

  return (
    <PreviewSegmentsContext.Provider value={state}>{children}</PreviewSegmentsContext.Provider>
  );
}

export function usePreviewSegmentsContext() {
  const previewContentContext = useContext(PreviewSegmentsContext);

  if (previewContentContext === undefined) {
    throw new Error(
      `PreviewSegmentsContext is undefined. This component can only be used in apps that are wrapped in PreviewSegmentsContextProvider.`
    );
  }

  return previewContentContext;
}
