import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const BrandDocument = {
  operationName: 'brand_shopAvailOptions',
  operationString: `query brand_shopAvailOptions($brandCode: String!, $language: String!) {
  brand(brandCode: $brandCode, language: $language) {
    code
    name
    isPartnerBrand
    formalName
    url
    locationsUrl
    shopAvailOptions {
      maxNumRooms
    }
    socialMediaLinks {
      channel
      url
    }
    phone {
      number
      friendlyNumber
    }
  }
}`,
  originalOpName: 'brand',
};
export function useBrandQuery<TData = Types.BrandQuery, TError = DXError>(
  variables: Types.BrandQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandQuery, TError, TData>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}
export function serverSideBrandQuery(
  queryClient: QueryClient,
  variables: Types.BrandQueryVariables,
  options?: FetchQueryOptions<Types.BrandQuery>
) {
  return queryClient.fetchQuery<Types.BrandQuery>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}

export const BrandsDocument = {
  operationName: 'brands',
  operationString: `query brands($language: String!) {
  brands(language: $language) {
    code
    name
    isPartnerBrand
    formalName
    url
    locationsUrl
    socialMediaLinks {
      channel
      url
    }
    phone {
      number
      friendlyNumber
    }
  }
}`,
  originalOpName: 'Brands',
};
export function useBrandsQuery<TData = Types.BrandsQuery, TError = DXError>(
  variables: Types.BrandsQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandsQuery, TError, TData>({
    queryKey: [BrandsDocument, variables],
    ...options,
  });
}
export function serverSideBrandsQuery(
  queryClient: QueryClient,
  variables: Types.BrandsQueryVariables,
  options?: FetchQueryOptions<Types.BrandsQuery>
) {
  return queryClient.fetchQuery<Types.BrandsQuery>({
    queryKey: [BrandsDocument, variables],
    ...options,
  });
}

export const GetTranslatedCmsUrlsDocument = {
  operationName: 'getTranslatedCmsUrls',
  operationString: `query getTranslatedCmsUrls {
  featureConfigs(names: ["rule-ui-translate-cms"]) {
    name
    conditions
    config
  }
  featureToggles(names: ["enableInLanguageCMSLandingPages"]) {
    enabled
  }
}`,
  originalOpName: 'getTranslatedCmsUrls',
};
export function useGetTranslatedCmsUrlsQuery<
  TData = Types.GetTranslatedCmsUrlsQuery,
  TError = DXError
>(
  variables?: Types.GetTranslatedCmsUrlsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetTranslatedCmsUrlsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetTranslatedCmsUrlsQuery, TError, TData>({
    queryKey: [GetTranslatedCmsUrlsDocument, variables],
    ...options,
  });
}
export function serverSideGetTranslatedCmsUrlsQuery(
  queryClient: QueryClient,
  variables?: Types.GetTranslatedCmsUrlsQueryVariables,
  options?: FetchQueryOptions<Types.GetTranslatedCmsUrlsQuery>
) {
  return queryClient.fetchQuery<Types.GetTranslatedCmsUrlsQuery>({
    queryKey: [GetTranslatedCmsUrlsDocument, variables],
    ...options,
  });
}
