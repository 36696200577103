import cx from 'classnames';
import { useTranslation } from 'next-i18next';

type OverlayImageType = {
  /** content can only show if position is given */
  shouldAlignContentPosition?: boolean;
  /** Url for source image/illustration */
  imageUrl?: string;
  /** allows for additional custom classes */
  className?: string;
};

export const MastheadOverlayImage: React.FC<OverlayImageType> = ({
  shouldAlignContentPosition,
  imageUrl,
  className,
}) => {
  const [t] = useTranslation('cpm-mapping-shared');
  return (
    <div className="relative -end-8 bottom-0 z-0 -mt-10 ms-auto w-[327px] sm:-mt-32 sm:w-[491px] md:-mt-52 lg:mt-[-340px] lg:w-[703px] xl:mt-[-550px]">
      <img
        className={cx(
          {
            'h-64 w-[327px] rtl:scale-x-[-1] object-cover sm:h-96 sm:w-[491px] lg:h-[550px] lg:w-[703px]':
              shouldAlignContentPosition,
          },
          className
        )}
        src={imageUrl}
        alt={t('illustrationAltTextEY')}
      />
    </div>
  );
};

export default MastheadOverlayImage;
