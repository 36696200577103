import type * as React from 'react';
import type { TabButton as TabButtonType } from './tab.button';
import { TabButton } from './tab.button';

/**
 * The `TabListButton` component extends the `TabButton` component and is used as the default styles for the OSC component, but doesn't need to be used and the developer can provide their own `TabButton` component if they wish.
 */
const TabListButton: React.FC<React.PropsWithChildren<TabButtonType>> = ({ ...rest }) => (
  <TabButton
    className="hover:text-primary focus:text-primary grow cursor-pointer appearance-none whitespace-nowrap border-b-4 border-transparent px-4 py-2 font-bold md:whitespace-normal"
    activeClassName="!border-primary text-primary brand-es:!border-secondary"
    inactiveClassName="border-transparent text-text-alt"
    {...rest}
  />
);

export { TabListButton };
export default TabListButton;
