/**
 * This file contains aspect ratio and width/height measurements for each compound image type
 * used in the schema.ts file (located next to this file).
 *
 * Take care to update this file with any new "asset"/"image compound" types that are added or
 * the editor (dx-cpm-lite) won't be able to display images correctly
 */

import type { AssetVariants } from '../adapters/types';

type CompoundImageBreakpointSize = {
  aspectRatio: keyof AssetVariants;
  sizes: {
    height: number;
    width: number;
  };
};

type CompoundImageBreakpointSizes = {
  xs: CompoundImageBreakpointSize;
  sm: CompoundImageBreakpointSize;
  md: CompoundImageBreakpointSize;
};

export const compoundImageBreakpointSizes: Record<string, CompoundImageBreakpointSizes> = {
  collage: {
    xs: {
      aspectRatio: '1x1',
      sizes: {
        height: 375,
        width: 375,
      },
    },
    sm: {
      aspectRatio: '1x1',
      sizes: {
        height: 768,
        width: 768,
      },
    },
    md: {
      aspectRatio: '1x1',
      sizes: {
        height: 820,
        width: 820,
      },
    },
  },
  tabs: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 185,
        width: 320,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 340,
        width: 770,
      },
    },
    md: {
      aspectRatio: '3x2',
      sizes: {
        height: 750,
        width: 1045,
      },
    },
  },
  threeSixNineGrid: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 150,
        width: 225,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 150,
        width: 225,
      },
    },
    md: {
      aspectRatio: '3x2',
      sizes: {
        height: 230,
        width: 345,
      },
    },
  },
  fourXExpansionPanel: {
    xs: {
      aspectRatio: '16x9',
      sizes: {
        height: 209,
        width: 320,
      },
    },
    sm: {
      aspectRatio: '16x9',
      sizes: {
        height: 422,
        width: 767,
      },
    },
    md: {
      aspectRatio: '16x9',
      sizes: {
        height: 999,
        width: 1277,
      },
    },
  },
  halfAndHalf: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 233,
        width: 299,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 532,
        width: 684,
      },
    },
    md: {
      aspectRatio: '3x2',
      sizes: {
        height: 610,
        width: 784,
      },
    },
  },
  fullWidth: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 205,
        width: 320,
      },
    },
    sm: {
      aspectRatio: '18x5',
      sizes: {
        height: 350,
        width: 768,
      },
    },
    md: {
      aspectRatio: '18x5',
      sizes: {
        height: 565,
        width: 1920,
      },
    },
  },
  imageHeadliner: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 50,
        width: 75,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 65,
        width: 98,
      },
    },
    md: {
      aspectRatio: '3x2',
      sizes: {
        height: 65,
        width: 98,
      },
    },
  },
  threeSixNineExpansionPanel: {
    xs: {
      aspectRatio: '3x2',
      sizes: {
        height: 148,
        width: 227,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 242,
        width: 357,
      },
    },
    md: {
      aspectRatio: '3x2',
      sizes: {
        height: 374,
        width: 547,
      },
    },
  },

  fourXGrid: {
    xs: {
      aspectRatio: '3x4',
      sizes: {
        height: 250,
        width: 220,
      },
    },
    sm: {
      aspectRatio: '3x4',
      sizes: {
        height: 460,
        width: 400,
      },
    },
    md: {
      aspectRatio: '3x4',
      sizes: {
        height: 460,
        width: 400,
      },
    },
  },
  brandCarousel: {
    xs: {
      aspectRatio: '1x1',
      sizes: {
        height: 477,
        width: 650,
      },
    },
    sm: {
      aspectRatio: '3x2',
      sizes: {
        height: 495,
        width: 950,
      },
    },
    md: {
      aspectRatio: '21x9',
      sizes: {
        height: 505,
        width: 1920,
      },
    },
  },
};
