import * as React from 'react';

type Props = React.PropsWithChildren<{
  theme: 'light' | 'dark' | '';
  enabled: boolean;
}>;

export function HamptonHexVisual({ theme, enabled, children }: Props) {
  if (enabled && theme === 'light') {
    return (
      <React.Fragment>
        <svg
          data-testid="hampton-hex-light"
          className="invisible absolute inset-y-0 right-0 h-full md:visible"
          viewBox="0 0 184 712"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M184 712V0H131.52C128.978 4.8022 126.907 9.93313 125.382 15.3337L6.51498 436.321C2.19295 451.651 0 467.512 0 483.5H0.0213165C0.0213165 499.489 2.21426 515.371 6.5363 530.701L57.7289 712H184Z"
            fill="white"
          />
        </svg>

        {children}
      </React.Fragment>
    );
  }

  if (enabled && theme === 'dark') {
    return (
      <React.Fragment>
        <svg
          data-testid="hampton-hex-dark"
          className="invisible absolute inset-y-0 right-0 h-full md:visible"
          viewBox="0 0 184 712"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M184 712V0H131.52C128.978 4.8022 126.907 9.93313 125.382 15.3337L6.51498 436.321C2.19295 451.651 0 467.512 0 483.5H0.0213165C0.0213165 499.489 2.21426 515.371 6.5363 530.701L57.7289 712H184Z"
            fill="#002666"
          />
        </svg>

        {children}
      </React.Fragment>
    );
  }

  return children;
}
