import { useMemo, useEffect } from 'react';
import { env } from '@dx-ui/framework-env';
import { createMetrics, metricsConfig } from '@dx-ui/config-metrics';
import { defaultPageData, digitalData } from '../metrics/constants';
import { disableMetricsCookie } from '../utils/constants';
import ExtendedAdobeTagManager from './ExtendedAdobeTagManager';
import type { MetricsInterface } from '@dx-ui/cpm-metrics';
import type { CpmMappedPage } from '@dx-ui/cpm-sdk';
import type { BrandQuery } from '../generated/types';
import { isBrowser } from '@dx-ui/utilities-is-browser';

function isMetricsDisabled() {
  if (!env('TRACKING_SEED_FILE')) {
    return true;
  }
  if (isBrowser) {
    const localStorageItem = window.localStorage.getItem(disableMetricsCookie);
    if (localStorageItem) {
      try {
        return JSON.parse(localStorageItem);
      } catch {
        return false;
      }
    }
  }
  return false;
}

function useMetricsInstance(isCPMEditor: boolean): MetricsInterface {
  return useMemo(() => {
    if (!isCPMEditor) {
      const { api: metricsInstance } = createMetrics(
        metricsConfig({
          debug: /local|test/.test(env('APP_ENV')),
          Interface: ExtendedAdobeTagManager(env('APP_VER')),
          seedFile: isMetricsDisabled() ? '' : env('TRACKING_SEED_FILE'),
          server: !isBrowser,
          digitalData,
        })
      );

      return metricsInstance;
    } else {
      return {};
    }
  }, [isCPMEditor]);
}

export function useMetrics(
  language: string,
  fullBrandPath: string,
  mappedPage: CpmMappedPage,
  brandData: BrandQuery['brand'],
  isCPMEditor: boolean
) {
  const metricsInstance = useMetricsInstance(isCPMEditor);

  useEffect(() => {
    const pageData = {
      ...defaultPageData,
      ...(brandData?.formalName ? { brandName: brandData.formalName } : {}),
      ...(brandData?.url ? { destinationUrl: brandData.url } : {}),
      ...(mappedPage.seo.pageTitle ? { pageName: mappedPage.seo.pageTitle } : {}),
      brandCode: mappedPage.brandCode,
      lang: language,
      pageType: mappedPage.pageType,
      fullBrandPath,
      analyticsTaggingOverrides: mappedPage.analyticsTagging,
    };

    metricsInstance.setDefaultPageData?.([pageData]);

    metricsInstance.removePropertySearchInfo?.();
    // This loads the Adobe TM scripts
    metricsInstance.trackPageView?.();

    // We only want to run this effect once on purpose
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return metricsInstance;
}
