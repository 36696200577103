import { Chip } from './osc-chip';
import type { ChipProps } from './osc-chip';
import cx from 'classnames';

export type ActionChipProps = Omit<
  ChipProps,
  'as' | 'className' | 'inheritStyles' | 'preset' | 'variant'
> & {
  onClick: () => void;
  isSelected?: boolean;
};

export function ActionChip({
  iconName,
  isIconTrailing,
  label,
  onClick,
  isSelected,
  size,
}: ActionChipProps) {
  return (
    <button
      onClick={onClick}
      className={cx('border-hilton text-hilton rounded-full', {
        'bg-hilton text-text-inverse': isSelected,
      })}
      type="button"
    >
      <Chip
        as="span"
        iconName={iconName}
        isIconTrailing={isIconTrailing}
        size={size}
        label={label}
        shouldInheritStyles
      />
    </button>
  );
}

export default ActionChip;
