import { useRef, type ReactNode } from 'react';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import type { AspectRatio, TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { Link } from '@dx-ui/osc-link';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export type TCardCarouselItem = {
  /** Card Carousel item id */
  id: string;
  /** Image for Card Carousel Item */
  imageUrl: string;
  captionData?: TResponsiveImage['captionData'];
  /** Alt Text for Card Carousel Item Image */
  imageAltTxt: string;
  /** Headline for Card Carousel Item */
  headline?: string;
  /** Can be used as additional headline copy or a subheadline */
  shortDescription?: string;
  /** Content */
  longDescription?: string;
  /** Link/CTA */
  link?: Link & { experimentationConfiguration?: CmsExperimentationConfiguration };
  /** List of Audience Ids */
  segmentIds: string[];
  /** CMS document editor control **/
  cmsDocumentControl?: ReactNode;
  /* Translation CSS classes to add to each item */
  cmsTranslationClasses?: string;
  /** Adds ability to select color themes */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
  /** Used to determine different styles for within editorial component */
  isEditorialComponent?: boolean;
  /** CurrentCard Index + 1 */
  currentCard?: number;
  /** Total number of cards in Card Carousel */
  totalCards?: number;
  /** Adds a second, smaller border inside the other border */
  isInsideBorder?: boolean;
  /** Sets the tab index for the cards, A11y helper */
  tabIndex?: number;
};

export const DocsTCardCarouselItem: React.FC<React.PropsWithChildren<TCardCarouselItem>> = () =>
  null;

export const CardCarouselItem: React.FC<TCardCarouselItem> = (item) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  const {
    id,
    imageUrl,
    captionData,
    imageAltTxt,
    imageAspectRatio,
    headline,
    shortDescription,
    longDescription,
    link,
    brandComponentTheme,
    isEditorialComponent,
    isInsideBorder,
    currentCard,
    totalCards,
    tabIndex,
  } = item;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return item ? (
    <div
      className={cx(
        'relative flex h-full flex-col justify-between bg-transparent',
        item.cmsTranslationClasses,
        'card-carousel-item-container',
        {
          'card-carousel-item-container-dark': isDark,
          'p-2 sm:p-4 border-brand border-solid border': isInsideBorder,
        }
      )}
      data-testid={`card-carousel-item-${id}`}
      key={id}
    >
      <div className="flex flex-col">
        {(headline || shortDescription) && isEditorialComponent ? (
          <hgroup>
            <HeadingLevel
              headingLevelFallback={4}
              className={cx('heading-2xl', {
                '!text-text-inverse': isDark,
                'card-carousel-item-headline-text-light': isLight,
                'pb-2': !shortDescription,
              })}
            >
              {headline}
            </HeadingLevel>
            {shortDescription ? (
              <BrandTextBody
                className={cx('relative pt-3 !text-base font-bold', {
                  '!text-text-inverse': isDark,
                  'card-carousel-item-description-text-light': isLight,
                })}
                brandComponentTheme={brandComponentTheme}
              >
                {shortDescription}
              </BrandTextBody>
            ) : null}
          </hgroup>
        ) : null}

        {(headline || shortDescription) && !isEditorialComponent ? (
          <hgroup>
            <HeadingLevel
              headingLevelFallback={3}
              className={cx('heading-2xl sm:brand-wa:heading-3xl', {
                '!text-text-inverse': isDark,
                'card-carousel-item-headline-text-light': isLight,
                'pb-2': !shortDescription,
              })}
            >
              {headline}
            </HeadingLevel>
            {shortDescription ? (
              <BrandTextBody
                className={cx('relative pt-3 !text-base font-bold', {
                  '!text-text-inverse': isDark,
                  'card-carousel-item-description-text-light': isLight,
                })}
                brandComponentTheme={brandComponentTheme}
              >
                {shortDescription}
              </BrandTextBody>
            ) : null}
          </hgroup>
        ) : null}

        <div ref={ref} className="relative order-first mb-3">
          <ResponsiveImage
            id={`card-carousel-item-img-${id}`}
            aspectRatio={imageAspectRatio}
            imageUrl={imageUrl}
            altText={imageAltTxt}
            width={rect?.width ?? 0}
            captionData={captionData}
            className="image-corner-radius"
          />
          <p
            aria-hidden
            className="text-text-inverse bg-bg-inverse absolute bottom-0 right-0 select-none overflow-hidden px-2 py-1 text-sm opacity-80 sm:text-base rtl:left-0 rtl:right-auto"
          >
            {currentCard} / {totalCards}
          </p>
        </div>

        {longDescription ? (
          <BrandTextBody
            className={cx('relative z-10 mb-2 !text-lg', {
              '!text-text-inverse': isDark,
              'card-carousel-item-description-text-light': isLight,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {longDescription}
          </BrandTextBody>
        ) : null}
      </div>
      <div>
        {link ? (
          <Link
            className="text-base"
            isNewWindow={link.isNewWindow}
            url={link.url}
            showNewWindowIcon={true}
            underline={true}
            data-testid="testLinkTheme"
            tabIndex={tabIndex}
            anchorClassName={cx(
              "focus:text-primary-alt hover:text-primary-alt after:absolute after:inset-0 after:z-0 after:content-[''] hover:underline hover:decoration-[3px] focus:underline focus:decoration-[3px] focus:!shadow-none",
              {
                'text-primary': !isDark && !isLight,
                'text-text-inverse hover:text-text-inverse focus:text-text-inverse hover:decoration-text-inverse focus:decoration-text-inverse':
                  isDark,
                'card-carousel-item-link-anchor-light': isLight,
              }
            )}
            data-conductrics-goal={link.experimentationConfiguration?.goal}
            data-conductrics-value={link.experimentationConfiguration?.value}
          >
            {link.label}
          </Link>
        ) : null}
      </div>

      {item.cmsDocumentControl}
    </div>
  ) : null;
};

export default CardCarouselItem;
