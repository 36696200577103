import type { IconNames, EYIconNames, ESIconNames, HPIconNames } from '@dx-ui/osc-icon';

export const brandIconMapper: {
  [key: string]:
    | { brandCode: 'EY'; name: (typeof EYIconNames)[number] }
    | {
        brandCode: 'ES';
        name: (typeof ESIconNames)[number];
        invertedName: (typeof ESIconNames)[number];
      }
    | {
        brandCode: 'HP';
        name: (typeof HPIconNames)[number];
        invertedName: (typeof HPIconNames)[number];
      };
} = {
  livsmartBreakfast: { brandCode: 'EY', name: 'breakfast' },
  livsmartFitness: { brandCode: 'EY', name: 'fitness' },
  livsmartGrill: { brandCode: 'EY', name: 'grill' },
  livsmartKitchen: { brandCode: 'EY', name: 'kitchen' },
  livsmartLaundry: { brandCode: 'EY', name: 'laundry' },
  livsmartPetFriendly: { brandCode: 'EY', name: 'pet-friendly' },
  livsmartStudio: { brandCode: 'EY', name: 'studio' },
  embassyBreakfast: { brandCode: 'ES', name: 'breakfast', invertedName: 'breakfast_inverted' },
  embassyFitness: { brandCode: 'ES', name: 'fitness', invertedName: 'fitness_inverted' },
  embassyReception: { brandCode: 'ES', name: 'drink', invertedName: 'drink_inverted' },
  hamptonHexagon: { brandCode: 'HP', name: 'hex', invertedName: 'hex_inverted' },
} as const;

export const iconMapper: {
  [key: string]: (typeof IconNames)[number];
} = {
  twentyFourHourMemberService: 'clock',
  access: 'access',
  account: 'user',
  airlineMiles: 'airport',
  amazonBox: 'amazon',
  americanExpress: 'credit-card',
  benefitsStar: 'star-circle',
  carRental: 'car-rental',
  chooseYourRoom: 'choose-your-room',
  complimentaryBreakfast: 'hot-breakfast',
  deviceApp: 'mobile',
  diamond: 'diamond',
  digitalCheckin: 'app',
  digitalKey: 'digital-key',
  dining: 'dining',
  eliteRolloverNights: 'award',
  fifthNightFree: 'free-nights',
  freeWifi: 'wifi',
  giftPoints: 'gift',
  honorsDiscount: 'price',
  honorsOffers: 'event',
  lateCheckout: 'check-out',
  loungeAccess: 'lounge',
  lyftRides: 'lyft',
  onPropertyRewards: 'rewards',
  orderAhead: 'order-ahead',
  points: 'points',
  pointsMoney: 'points-and-money',
  poolPoints: 'pool-points',
  pointsPromotions: 'points-promotions',
  premiumWifi: 'wifi',
  roomGuarantee: 'check-in',
  roomUpgrade: 'room-upgrade',
  secondGuest: 'users',
  transferPoints: 'transfer',
  waterBottle: 'water-bottle',
  hotelStays: 'hotel',
  convertPoints: 'convert-points',
  buyPoints: 'buy-points',
  meetingEvents: 'meeting',
  hgv: 'keys',
  allInclusiveSpaDiscount: 'spa',
  cookie: 'cookie',
};
