import type * as React from 'react';
import { createContext, useContext } from 'react';

type TabsContext = {
  active?: string;
  activeIndex: number;
  addRef: (ref: HTMLButtonElement | null) => void;
  count: number;
  onTabKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>, value?: string) => void;
  onTabSelect: (value?: string) => void;
  setCount: (value: number) => void;
};

const TabsContext = createContext<TabsContext>({
  activeIndex: 0,
  count: 0,
} as TabsContext);

const useTabs = () => useContext(TabsContext);

export { TabsContext, useTabs };
export default TabsContext;
