import { useRouter } from 'next/router';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export const useQueryStrings = () => {
  const { query: nextJsQuery } = useRouter();

  const additional = isBrowser
    ? Object.fromEntries(new URLSearchParams(window.location.search).entries())
    : {};

  return {
    ...nextJsQuery,
    ...additional,
  };
};

export const useSingleInstanceQueryStrings = (): { [x: string]: string | undefined } => {
  const queryStrings = useQueryStrings();

  return Object.entries(queryStrings).reduce(
    (acc, [key, val]) =>
      typeof val === 'string' || typeof val === 'undefined'
        ? {
            ...acc,
            [key]: val,
          }
        : acc,
    {}
  );
};
