import cx from 'classnames';
import type { Link } from '@dx-ui/osc-link';
import { BrandLink } from '@dx-ui/osc-brand-buttons';

export interface TextBlockCTAProps {
  /**
   * Is the component within the viewport?
   */
  isInView: boolean;
  /**
   * fade-in text animation
   */
  isAnimated: boolean;
  /**
   * The link object that defines the CTA.
   */
  link: Link & { experimentationConfiguration?: CmsExperimentationConfiguration };
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
}

/**
 * The TextBlock CTA component.
 */
export const TextBlockCTA = ({
  link,
  isInView,
  isAnimated = false,
  brandComponentTheme,
}: TextBlockCTAProps) => {
  if (!link) {
    return null;
  }

  const isDark = brandComponentTheme === 'dark';

  return (
    <div
      className={cx('mt-2 xl:mt-4', {
        'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
          isAnimated,
        'translate-y-4': !isInView && isAnimated,
        'delay-700 opacity-100 translate-y-0': isInView && isAnimated,
      })}
    >
      <BrandLink
        label={link.label}
        isNewWindow={link.isNewWindow}
        showNewWindowIcon={link.isNewWindow}
        url={link.url}
        brandComponentTheme={brandComponentTheme}
        data-conductrics-goal={link.experimentationConfiguration?.goal}
        data-conductrics-value={link.experimentationConfiguration?.value}
        anchorClassName={cx(
          'brand-qq:!bg-primary brand-qq:text-text-inverse brand-qq:!border-none brand-qq:py-4 brand-qq:px-8',
          {
            'brand-qq:!bg-bg-light brand-qq:!text-primary brand-qq:hover:bg-bg-light': isDark,
          }
        )}
      />
    </div>
  );
};
