import type { Configuration } from '@bloomreach/spa-sdk';
import { env } from '@dx-ui/framework-env';

type HttpClient = Configuration['httpClient'];
type HttpRequest = Parameters<HttpClient>[0];
type HttpResponse = ReturnType<HttpClient>;

export const makeClientBrConfiguration = (contentPath: string): Configuration => {
  const [_, channelName, ...pathSlugs] = contentPath.split('/');
  const path = '/' + pathSlugs.join('/');

  // Related to editor mode preview, not dx-preview header, however same effect
  const queryString = contentPath.split('?')[1];
  const previewSlug = new URLSearchParams(queryString).get('preview');

  const endpoint = [
    env('CMS_BASE_URL'),
    channelName,
    ...(previewSlug ? ['/', previewSlug] : []),
    '/resourceapi',
  ].join('');

  return {
    debug: true, // gives console.debug messages, might be useful in editor
    endpoint: `${endpoint}?_maxreflevel=0`,
    httpClient,
    request: { path },
  } satisfies Configuration;
};

export const httpClient: Configuration['httpClient'] = async ({
  method,
  url: _url,
  headers: requestHeaders,
  data: requestData,
}: HttpRequest): HttpResponse => {
  const url = new URL(_url);
  const params = new URLSearchParams(url.search);

  // We need *all* references when adding/removing documents from components
  if (params.get('_hn:type') === 'component-rendering') {
    params.delete('_maxreflevel');
  }

  url.search = params.toString();

  const headers = Object.fromEntries(
    Object.entries(requestHeaders ?? {}).map(([key, val]) => [key, val + ''])
  );

  const fetchResponse = await fetch(url, {
    method,
    headers,
    credentials: 'include',
    body: requestData ? requestData : undefined,
  });

  const data = await fetchResponse.json();

  return { data };
};
