import { generateCpmAppInterface } from '@dx-ui/cpm-sdk';
import { sharedMappingSpec, namespaces } from '@dx-ui/cpm-mapping-shared';

import Footer from './mapping/Footer';
import Header from './mapping/Header';
import SearchWidget from './mapping/SearchWidget';

export { Footer, Header, SearchWidget, namespaces };

export const mappingSpec = {
  ...sharedMappingSpec,
  [Footer.cpmComponentName]: Footer,
  [Header.cpmComponentName]: Header,
  [SearchWidget.cpmComponentName]: SearchWidget,
};

export const { fetchServerCpmPage, CpmRenderer } = generateCpmAppInterface(mappingSpec);

export type CpmServerData = Exclude<Awaited<ReturnType<typeof fetchServerCpmPage>>, null>;
