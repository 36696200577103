import type * as React from 'react';
import cx from 'classnames';

import { getEffectClassNames } from '../utils/get-effect-classnames';
import { BackgroundIllustration } from './brand/parallax-illustration';

/**
 * Themes a component by wrapping it in a div containing custom classes that override CSS props.
 *
 * Reimplementation of dx-hotels-ui BrandComponentThemeWrapper
 */
const BrandComponentThemeInline: React.FC<{
  componentParams: Record<string, unknown>;
  componentClassName?: string;
  brandCode: string;
  children: React.ReactNode;
  backgroundIllustration?: React.ComponentProps<typeof BackgroundIllustration>;
}> = ({
  children,
  componentClassName = '',
  brandCode,
  backgroundIllustration,
  componentParams: { theme = '', backgroundImage, anchorId, topPadding, bottomPadding },
}) => {
  const effectClassNames = getEffectClassNames({
    backgroundImage: typeof backgroundImage === 'boolean' ? backgroundImage : false,
  });

  const id = typeof anchorId === 'string' && anchorId !== '' ? anchorId : undefined;
  const isLight = theme === 'light';
  const isDark = theme === 'dark';

  return (
    <div
      id={id}
      data-testid="BrandComponentThemeInline"
      className={cx(
        {
          'bg-transparent brand-wa:bg-bg-light': componentClassName === 'header',
          'bg-bg-dark': brandCode && isDark,
          'bg-bg-light': brandCode !== 'WA' && isLight,
          'brand-lx:bg-bg': brandCode === 'LX' && isLight && componentClassName === 'hero-image',
          'brand-ou:bg-secondary brand-gu:bg-quarternary': !isDark && !isLight,
          'pt-36': topPadding === 'small',
          'pt-48': topPadding === 'medium',
          'pt-64': topPadding === 'large',
          'pb-36': bottomPadding === 'small',
          'pb-48': bottomPadding === 'medium',
          'pb-64': bottomPadding === 'large',
        },
        'brand-wa:bg-transparent relative',
        componentClassName,
        componentClassName === 'collage'
          ? 'brand-ol:bg-bg brand-qq:overflow-hidden brand-qq:py-6 brand-qq:md:py-8 brand-qq:lg:py-20'
          : null
      )}
    >
      {backgroundIllustration ? (
        <BackgroundIllustration
          isParallax={backgroundIllustration.isParallax}
          variant={backgroundIllustration.variant}
        />
      ) : null}
      {effectClassNames ? <div className={effectClassNames} /> : null}
      <div className="relative">{children}</div>
    </div>
  );
};

export default BrandComponentThemeInline;
