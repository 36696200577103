import type * as React from 'react';
import cx from 'classnames';

type TabProps = React.HTMLAttributes<HTMLDivElement>;

const VerticalTab = ({ className, ...rest }: TabProps) => (
  <div className={cx('relative', className)} {...rest} />
);

export { VerticalTab };
export default VerticalTab;
