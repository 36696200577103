import { useState, useEffect } from 'react';

/**
 * Returns an array of segments IDs that should be used for targeting
 * when the `segmentIds` query param exists in the URL, otherwise
 * returns `undefined`.
 */
export function usePreviewSegmentsFromUrl() {
  const [previewSegmentIds, setPreviewSegmentIds] = useState<string[]>([]);

  // We need to check client side because targeting only runs on the client
  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const segments = params.get('segmentIds');

      if (segments) {
        setPreviewSegmentIds(segments.split(','));
      }
    } catch {
      // We don't need to handle errors
    }
  }, []);

  return previewSegmentIds;
}
