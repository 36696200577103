import type { MouseEvent, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { sendReward, useConductricsSelection } from '@dx-ui/framework-conductrics';
import type { ExperimentationAgents } from './types';

const fallbackValue = {};
export const CpmConductricsAgentsContext = createContext<ExperimentationAgents>(fallbackValue);

export function CpmConductricsAgentsProvider({
  agentIds,
  children,
}: {
  agentIds: string[];
  children: ReactNode;
}) {
  if (!agentIds.length) {
    return (
      <CpmConductricsAgentsContext.Provider value={fallbackValue}>
        {children}
      </CpmConductricsAgentsContext.Provider>
    );
  }

  return <ConductricsFetcher agentIds={agentIds}>{children}</ConductricsFetcher>;
}

export function useCpmConductricsAgents() {
  const context = useContext(CpmConductricsAgentsContext);

  if (!context) {
    throw new Error(
      '`useCpmConductricsAgents` must be used within a `CpmConductricsAgentsProvider`'
    );
  }

  return context;
}

function ConductricsFetcher({ agentIds, children }: { agentIds: string[]; children: ReactNode }) {
  const agents = useConductricsSelection(agentIds.map((agentId) => ({ agentId })));
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement> | Event) => {
    if (!(event.target instanceof Element)) {
      return;
    }

    const interactiveElement = event.target.closest<HTMLButtonElement | HTMLAnchorElement>(
      'a, button'
    );

    if (!interactiveElement) {
      return;
    }

    const goal = interactiveElement.dataset.conductricsGoal;
    const value = interactiveElement.dataset.conductricsValue;

    if (goal) {
      sendReward(goal, castToNumber(value));
    }
  }, []);

  useEffect(() => {
    let wrapperRefValue: HTMLElement | null = null;

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('click', handleClick, { passive: true });
      wrapperRefValue = wrapperRef.current;
    }

    return () => {
      if (wrapperRefValue) {
        wrapperRefValue.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick]);

  return (
    <CpmConductricsAgentsContext.Provider value={agents}>
      <div ref={wrapperRef}>{children}</div>
    </CpmConductricsAgentsContext.Provider>
  );
}

function castToNumber(value?: string | number) {
  const v = Number(value);
  return Number.isFinite(v) ? v : undefined;
}
