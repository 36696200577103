import { useId } from 'react';
import { TextualDescriptionBlock } from '@dx-ui/osc-textual-block';
import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';

export interface TextBlockDescriptionProps {
  /**
   * The text for the description component.
   */
  body: string;
  className?: string;
  /**
   * Is the component within the viewport?
   */
  isInView: boolean;
  /**
   * fade-in text animation
   */
  isAnimated: boolean;
  /**
   * The alignment of the text.
   */
  textAlign?: 'left' | 'right' | 'center';
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
}

/**
 * The TextBlock Description component.
 */
export const TextBlockDescription = ({
  body,
  isInView,
  isAnimated = false,
  textAlign = 'left',
  brandComponentTheme,
}: TextBlockDescriptionProps) => {
  const id = useId();

  if (!body) {
    return null;
  }

  return (
    <TextualDescriptionBlock
      brandComponentTheme={brandComponentTheme}
      textAlign={textAlign}
      isInView={isInView}
      isAnimated={isAnimated}
    >
      <CustomMarkdown
        id={`textblock-body-section-${id}`}
        isStaticContentPage={false}
        brandComponentTheme={brandComponentTheme}
      >
        {body}
      </CustomMarkdown>
    </TextualDescriptionBlock>
  );
};
