import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

type Segments = Record<string, Array<string>>;
export type DispatchSegmentsUpdate = Dispatch<SetStateAction<Segments>>;

export type SegmentsContext = [Segments, DispatchSegmentsUpdate];
const noop = () => undefined;

const SegmentationContext = createContext<SegmentsContext>([{}, noop]);

/**
 * Store segments + component/document names for the CPM editor so that it can filter them
 */
export function SegmentationContextProvider({ children }: { children: ReactNode }) {
  const state = useState<Segments>({});

  return <SegmentationContext.Provider value={state}>{children}</SegmentationContext.Provider>;
}

export function useSegmentsContext() {
  const context = useContext(SegmentationContext);

  if (context === undefined) {
    throw new Error(
      `SegmentsContext is undefined. This component can only be used in apps that are wrapped in SegmentationContextProvider.`
    );
  }

  return context;
}
