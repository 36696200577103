import type { ComponentParamsProperty, mappingSchema } from '@dx-ui/cpm-sdk';
import type { GetCompanyByAccountIdQuery } from '@dx-ui/cpm-mapping-shared';
import type { SpecialRates, SpecialRatesKey } from '@dx-ui/osc-special-rates';
import { isSpecialRatesKey } from '@dx-ui/osc-special-rates';
import type { AdditionalQSParameters } from '@dx-ui/osc-shop-form';
import { isAdditionalQsParameterKey } from '@dx-ui/osc-shop-form';

import type { SortByValues, TripAdvisorRatings } from '@dx-ui/framework-uri-builder';
import { isSortByValue, isTripAdvisorRating } from '@dx-ui/framework-uri-builder';

type AdditionalQSKey = keyof AdditionalQSParameters;
type AdditionalQSValue = AdditionalQSParameters[AdditionalQSKey];

function normalizeQSKey(key: string): AdditionalQSKey | null {
  switch (key) {
    case 'f-amenityIds':
    case 'f_amenityIds':
      return 'f_amenityIds';
    case 'f-attributeIds':
    case 'f_attributeIds':
      return 'f_attributeIds';
    case 'f-brandCodes':
    case 'f_brandCodes':
      return 'f_brandCodes';
    case 'f-price':
    case 'f_price':
      return 'f_price';
    case 'f-tripAdvisorRatings':
    case 'f_tripAdvisorRatings':
      return 'f_tripAdvisorRatings';
    case 'spec_plan':
    case 'specPlan':
      return 'specPlan';
    default:
      if (isAdditionalQsParameterKey(key)) {
        return key;
      } else {
        return null;
      }
  }
}

function makeIfQSKeyMatches<Key extends AdditionalQSKey, Value>(
  key: string,
  value: string,
  keyMatch: Key,
  makeValue: (v: string) => Value
): Partial<Record<Key, Value>> {
  if (key === keyMatch) {
    const val = makeValue(value);
    if (val !== undefined) {
      return { [keyMatch]: val } as Record<Key, Value>;
    }
  }
  return {};
}

function makeIfSpecialRatesKeyMatches<Key extends SpecialRatesKey, Value>(
  key: string,
  value: string,
  keyMatch: Key,
  makeValue: (v: string) => Value
): Partial<Record<Key, Value>> {
  if (key === keyMatch) {
    const val = makeValue(value);
    if (val !== undefined) {
      return { [keyMatch]: val } as Record<Key, Value>;
    }
  }
  return {};
}

function makeBool(value: string): boolean | undefined {
  return value !== null && value !== undefined ? Boolean(value) : undefined;
}

function makeString(value: string): string | undefined {
  return value;
}

function makeNumber(value: string): number | undefined {
  const parsed = Number(value);
  if (Number.isNaN(parsed)) {
    return undefined;
  } else {
    return parsed;
  }
}

function makeStringArray(value: string): Array<string> | undefined {
  return value?.split(',');
}

function makeSpecPlan(value: string | null | undefined): string[] | undefined {
  if (typeof value === 'string') {
    return value.split(',');
  } else {
    return undefined;
  }
}

function makeFPrice(value: string): [number, number] | undefined {
  function isFPrice(
    value: AdditionalQSValue | number[]
  ): value is AdditionalQSParameters['f_price'] {
    return (
      Array.isArray(value) &&
      value.length === 2 &&
      typeof value[0] === 'number' &&
      typeof value[1] === 'number'
    );
  }

  const parsed = value.split(',').map(Number);
  if (isFPrice(parsed)) {
    return parsed;
  } else {
    return undefined;
  }
}

function makeTripAdvisorRatings(value: string): TripAdvisorRatings | undefined {
  if (isTripAdvisorRating(value)) {
    return value;
  } else {
    return undefined;
  }
}

function makeSortByValues(value: string): SortByValues | undefined {
  if (isSortByValue(value)) {
    return value;
  } else {
    return undefined;
  }
}

function injectQSVal<Key extends AdditionalQSKey>(
  key: Key,
  val: string,
  additionalQSParameters: AdditionalQSParameters
): AdditionalQSParameters {
  return {
    ...additionalQSParameters,
    ...makeIfQSKeyMatches(key, val, 'adjoiningRoomStay', makeBool),
    ...makeIfQSKeyMatches(key, val, 'availableHotelsOnly', makeBool),
    ...makeIfQSKeyMatches(key, val, 'brandCode', makeString),
    ...makeIfQSKeyMatches(key, val, 'cid', makeString),
    ...makeIfQSKeyMatches(key, val, 'content', makeString),
    ...makeIfQSKeyMatches(key, val, 'displayCurrency', makeString),
    ...makeIfQSKeyMatches(key, val, 'f_amenityIds', makeStringArray),
    ...makeIfQSKeyMatches(key, val, 'f_attributeIds', makeStringArray),
    ...makeIfQSKeyMatches(key, val, 'f_brandCodes', makeStringArray),
    ...makeIfQSKeyMatches(key, val, 'f_price', makeFPrice),
    ...makeIfQSKeyMatches(key, val, 'f_tripAdvisorRatings', makeTripAdvisorRatings),
    ...makeIfQSKeyMatches(key, val, 'fromId', makeString),
    ...makeIfQSKeyMatches(key, val, 'maxPoints', makeNumber),
    ...makeIfQSKeyMatches(key, val, 'redeemPts', makeBool),
    ...makeIfQSKeyMatches(key, val, 'requestedRatesOnly', makeBool),
    ...makeIfQSKeyMatches(key, val, 'sortBy', makeSortByValues),
    ...makeIfQSKeyMatches(key, val, 'specPlan', makeSpecPlan),
    ...makeIfQSKeyMatches(key, val, 'specialRatesTokens', makeString),
    ...makeIfQSKeyMatches(key, val, 'token', makeStringArray),
  };
}

function injectSpecialRatesValue<Key extends SpecialRatesKey>(
  key: Key,
  val: string,
  specialRates: Partial<SpecialRates>
): Partial<SpecialRates> {
  return {
    ...specialRates,
    ...makeIfSpecialRatesKeyMatches(key, val, 'aaaRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'aarpRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'corporateCode', makeString),
    ...makeIfSpecialRatesKeyMatches(key, val, 'employeeRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'friendsAndFamilyRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'governmentOrMilitaryRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'groupCode', makeString),
    ...makeIfSpecialRatesKeyMatches(key, val, 'ownerHGVRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'ownerVIPRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'promoCode', makeString),
    ...makeIfSpecialRatesKeyMatches(key, val, 'redeemPts', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'requestedRatesOnly', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'searchedRatePlanDescription', makeString),
    ...makeIfSpecialRatesKeyMatches(key, val, 'seniorRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'smbRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'travelAgentId', makeString),
    ...makeIfSpecialRatesKeyMatches(key, val, 'travelAgentRate', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'useOfferId', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'usePnd', makeBool),
    ...makeIfSpecialRatesKeyMatches(key, val, 'useRequestedRatesOnly', makeBool),
  };
}

export function mapDataToSpecialRates(
  componentParams: ComponentParamsProperty<(typeof mappingSchema)['Search Widget']>,
  corporateAccount?: GetCompanyByAccountIdQuery | null
) {
  const {
    deeplinkParameter1,
    deeplinkParameterValue1,
    deeplinkParameter2,
    deeplinkParameterValue2,
    deeplinkParameter3,
    deeplinkParameterValue3,
    aaaRate,
    aarpRate,
    governmentOrMilitaryRate,
    redeemPts,
    seniorRate,
    travelAgentRate,
  } = componentParams;
  const corpAccount = corporateAccount?.altCorporateAccount;

  let additionalQS: AdditionalQSParameters = {};
  let specialRates: Partial<SpecialRates> = {
    aaaRate,
    aarpRate,
    governmentOrMilitaryRate,
    redeemPts,
    seniorRate,
    travelAgentRate,
  };

  const deepLinkParams = {
    [deeplinkParameter1]: deeplinkParameterValue1,
    [deeplinkParameter2]: deeplinkParameterValue2,
    [deeplinkParameter3]: deeplinkParameterValue3,
  };

  if (corpAccount) {
    const corporateId = corpAccount.partner?.corporateId;
    const pnd = corpAccount.pnd;

    if (corporateId && pnd) {
      specialRates.usePnd = true;
      specialRates.pnd = {
        name: corporateId,
        pnd,
      };
    }

    // `f-amenityIds` param is returned in `corporateAccount.queryParams` from
    // the GraphQL response for gated pages. The CMS can override this value via
    // deeplink params below.
    if (corpAccount.queryParams) {
      const params = new URLSearchParams(corpAccount.queryParams);
      for (const [rawKey, rawVal] of params.entries()) {
        if (isSpecialRatesKey(rawKey)) {
          specialRates = injectSpecialRatesValue(rawKey, rawVal, specialRates);
        }

        const qsKey = normalizeQSKey(rawKey);
        if (qsKey) {
          additionalQS = injectQSVal(qsKey, rawVal, additionalQS);
        }
      }
    }
  }

  for (const [rawKey, rawVal] of Object.entries(deepLinkParams)) {
    const key = normalizeQSKey(rawKey);
    if (key) {
      additionalQS = injectQSVal(key, rawVal, additionalQS);
    }
  }

  return {
    additionalQS,
    specialRates,
  };
}
