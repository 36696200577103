import * as React from 'react';

type Props = React.PropsWithChildren<{
  theme: 'light' | 'dark' | '';
  enabled: boolean;
}>;

export function EditorialHamptonHexVisual({ theme, enabled, children }: Props) {
  if (enabled && theme === 'light') {
    return (
      <React.Fragment>
        <svg
          data-testid="editorial-hexagon-light"
          viewBox="0 0 1440 259"
          fill="none"
          className="invisible absolute inset-x-0 bottom-0 md:visible"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1440 258.333V192.488L794.802 10.3286C770.497 3.47664 745.349 0 720 0V0.0338135C694.65 0.0338135 669.469 3.51042 645.163 10.3624L0 192.521V258.333H1440Z"
            fill="white"
          />
        </svg>
        {children}
      </React.Fragment>
    );
  }

  if (enabled && theme === 'dark') {
    return (
      <React.Fragment>
        <svg
          data-testid="editorial-hexagon-dark"
          viewBox="0 0 1440 259"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="invisible absolute inset-x-0 bottom-0 md:visible"
        >
          <path
            d="M1440 258.333V192.488L794.802 10.3286C770.497 3.47664 745.349 0 720 0V0.0338135C694.65 0.0338135 669.469 3.51042 645.163 10.3624L0 192.521V258.333H1440Z"
            fill="#002666"
          />
        </svg>
        {children}
      </React.Fragment>
    );
  }

  return children;
}
