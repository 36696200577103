import { useMemo, useState, useEffect } from 'react';
import type Cookies from 'universal-cookie';

import { TMS_LIVE_COOKIE_NAME, TMS_DEV_COOKIE_NAME, isProdApp } from '../utils/constants';

import type { TSegmentedItem } from '../utils';
import { getFilteredItems, getUnsegmentedItems } from '../utils';
import { useCookies } from '@dx-ui/cpm-sdk';
import { usePreviewSegments } from '../Context';

const getSegmentCookie = (cookies: Cookies) => {
  let cookie = '';
  if (!isProdApp) {
    cookie = cookies.get(TMS_DEV_COOKIE_NAME);
  }
  if (!cookie) {
    cookie = cookies.get(TMS_LIVE_COOKIE_NAME);
  }
  return cookie;
};

export const useSegments = () => {
  /*
   * This must use a combo of useState and useEffect, rather than useMemo.
   *
   * This is because the server does not have segment information, so will
   * always render the page unsegmented
   *
   * When the client does its hydrate render, it has segment information right from initial render.
   * So if you useMemo, it will hydrate in a segmented state.
   * React would therefore not know to update the DOM, because the client render doesn't
   * know anything has changed
   *
   * https://reactjs.org/docs/react-dom.html#hydrate
   *
   */
  const [segments, setSegments] = useState<string[]>([]);

  const cookies = useCookies();

  useEffect(() => {
    const tms: string = getSegmentCookie(cookies) || '';
    setSegments(
      tms
        .split(',')
        .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
        .map((entry) => entry.split('=')[1] || '')
    );
  }, [cookies]);

  return segments;
};

type UseSegmentedItemsOptions = {
  /**
   * Will make sure we don't exceed the number of unsegmented items by adding segmented ones in
   * This is useful for grids (4XGrid, Grid369, etc), where the number of items must be the same, whether we're segmented or not
   */
  maintainUnsegmentedCount?: boolean;
};

export function useSegmentedItems<T extends TSegmentedItem>(
  items: T[],
  options: UseSegmentedItemsOptions = {}
): T[] {
  const cookieSegments = useSegments();
  const previewSegments = usePreviewSegments();
  const segments = previewSegments.length ? previewSegments : cookieSegments;

  const numItems = options.maintainUnsegmentedCount
    ? getUnsegmentedItems(items).length
    : items.length;

  const filteredItems = useMemo(
    () => getFilteredItems({ items, segments, numItems }),
    [items, numItems, segments]
  );

  return filteredItems;
}
