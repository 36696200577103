import * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';
import * as RadixAccordion from '@radix-ui/react-accordion';

export type HeadingLevel = 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type Item = {
  /**
   * Content that will be displayed when the accordion is expanded
   */
  content: React.ReactNode;
  /** Trigger text to display hidden content
   *
   * Defaults to “Open”
   */
  collapsedButtonLabel?: string | React.ReactNode;
  /** Screen-reader only trigger text to display hidden content
   */
  collapsedButtonAccessibleLabel?: string;
  /**
   * Applies to Accordion when it is not expanded
   */
  collapsedClassName?: string;
  /**
   * Screen-reader only trigger text to hide displayed content
   */
  expandedButtonAccessibleLabel?: string;
  /** Trigger text to hide displayed content
   *
   * Defaults to “Close”
   */
  expandedButtonLabel?: string | React.ReactNode;
  /**
   * Applies to Accordion when it is expanded
   */
  expandedClassName?: string;
  /**
   * Styles for the wrapper(div) of the accordion button
   */
  buttonWrapperClassName?: string;
  /** A stable key to identify the accordion */
  key: string;
};

export type AccordionBase = {
  /** Classes apply to trigger button
   *
   * To style accordion content, apply relevant classes directly to passed-in children
   */
  className?: string;
  /** Accordion item including labels and content */
  items: Item[];
  /** Passed into `<section />` element that contains children
   */
  containerClassName?: string;
  /**
   * Optional heading level that should wrap the Accordion Trigger. Defaults to a span if undefined.
   */
  headingLevel?: HeadingLevel;
  /**
   * Controls the fill color for the indicator icon
   */
  iconIndicatorFillColor?: string;
  /**
   * Applies color when expanded
   */
  iconIndicatorExpandedFillColor?: string;
  /**
   * Controls whether children should be unmounted when accordion is collapsed
   */
  shouldUnmountChildrenWhenCollapsed?: boolean;
  /**
   * Controls accordion variant type, single or stacked.  Defaults to single.
   *
   * Single - only one accordion can be expanded at a time (other accordion items will collapse)
   *
   * Multiple - multiple accordion items can be expanded at the same time. (other accordion items will remain expanded)
   */
  variant?: 'single' | 'stacked';
};

export type AccordionProps = AccordionBase & React.ComponentProps<typeof RadixAccordion.Root>;

const setInitialExpandedAccordions = (items: Item[], defaultValue?: string | string[]) => {
  if (typeof defaultValue === 'string') {
    const isExpanded = defaultValue === items[0]?.key;
    return [isExpanded];
  }
  return Array.from({ length: items.length }, (_, idx) => {
    return Boolean(defaultValue?.includes(items?.[idx]?.key ?? ''));
  });
};
/**
 *
 * An Accordion component based off of Radix UI's Accordion component. This component is used to display a list of items that can be expanded or collapsed to show or hide content. Root level component that rest props will pass to is <Accordion.Root />.
 * @see {@link Radix UI Accordion Documentation} https://www.radix-ui.com/primitives/docs/components/accordion
 */
export const Accordion = ({
  className,
  items,
  containerClassName,
  iconIndicatorFillColor,
  iconIndicatorExpandedFillColor,
  // eslint-disable-next-line @typescript-eslint/unbound-method -- This is a bug with radix definition using : void sytnax.
  onValueChange,
  variant = 'single',
  shouldUnmountChildrenWhenCollapsed = false,
  headingLevel,
  ...rest
}: AccordionProps) => {
  const [uncontrolledExpandedAccordion, setUncontrolledExpandedAccordion] = React.useState(
    setInitialExpandedAccordions(items, rest.defaultValue)
  );

  const isUncontrolledComponent = !rest?.value;

  const { i18n, t } = useTranslation('osc-accordion');

  const isStackedAccordion = variant === 'stacked';
  const handleIconType = isStackedAccordion ? 'arrowhead-down-circle' : 'arrowhead-small-down';
  const iconSize = isStackedAccordion ? 'lg' : 'md';

  /**
   *
   * Value can be string | string[] if `type` prop is `single` or `multiple` respectively.
   */
  const handleValueChange = (values: string | string[]) => {
    if (typeof values === 'string') {
      isUncontrolledComponent &&
        setUncontrolledExpandedAccordion([!uncontrolledExpandedAccordion[0]]);
      (onValueChange as (value: string) => void)?.(values);
    } else {
      isUncontrolledComponent &&
        setUncontrolledExpandedAccordion(
          uncontrolledExpandedAccordion.map((_, idx) => values.includes(items?.[idx]?.key ?? ''))
        );
      (onValueChange as (value: string[]) => void)?.(values);
    }
  };

  if (!items.length) return null;

  return (
    <RadixAccordion.Root
      {...rest}
      dir={i18n?.dir()}
      {...(rest.type === 'single' && { collapsible: true })}
      onValueChange={handleValueChange}
    >
      {items.map((item, idx) => {
        const isExpanded = isUncontrolledComponent
          ? uncontrolledExpandedAccordion[idx]
          : typeof rest?.value === 'string'
          ? rest?.value === item.key
          : !!rest?.value?.includes(item.key);

        const buttonLabel = isExpanded
          ? item?.expandedButtonLabel ?? t('close')
          : item?.collapsedButtonLabel ?? t('open');

        const accessibleButtonLabel = isExpanded
          ? item?.expandedButtonAccessibleLabel ?? item?.expandedButtonLabel ?? t('close')
          : item?.collapsedButtonAccessibleLabel ?? item?.collapsedButtonLabel ?? t('open');

        const iconVariant = isStackedAccordion && isExpanded ? 'solid' : 'regular';
        const shouldRenderChildren =
          !isExpanded && shouldUnmountChildrenWhenCollapsed ? false : true;

        return (
          <RadixAccordion.Item
            key={item.key}
            value={item.key}
            className={item.buttonWrapperClassName}
          >
            <RadixAccordion.Header asChild={!!headingLevel}>
              <TriggerWrapper as={headingLevel}>
                <RadixAccordion.Trigger asChild>
                  <button
                    type="button"
                    className={cx('items-center', className, {
                      [item?.expandedClassName as string]: isExpanded && !!item?.expandedClassName,
                      [item?.collapsedClassName as string]:
                        !isExpanded && !!item?.collapsedClassName,
                    })}
                    data-osc={`accordion-trigger-${item.key}`}
                  >
                    <span aria-hidden="true">{buttonLabel}</span>
                    <span className="sr-only">{accessibleButtonLabel}</span>
                    <span
                      className={cx(
                        'accordion-indicator-wrapper duration-150 ease-in-out motion-safe:transition',
                        {
                          '-rotate-180': isExpanded,
                        }
                      )}
                      data-osc="accordion-indicator-wrapper"
                    >
                      <Icon
                        name={handleIconType}
                        variant={iconVariant}
                        size={iconSize}
                        className={cx(iconIndicatorFillColor, {
                          [iconIndicatorExpandedFillColor as string]:
                            isExpanded && !!iconIndicatorExpandedFillColor,
                        })}
                      />
                    </span>
                  </button>
                </RadixAccordion.Trigger>
              </TriggerWrapper>
            </RadixAccordion.Header>
            <RadixAccordion.Content className={containerClassName}>
              {shouldRenderChildren ? item.content : null}
            </RadixAccordion.Content>
          </RadixAccordion.Item>
        );
      })}
    </RadixAccordion.Root>
  );
};

function TriggerWrapper({
  as: Wrapper = 'span',
  children,
  ...rest
}: {
  as?: HeadingLevel | 'span';
  children: React.ReactNode;
}) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

export default Accordion;
