type GetAspectRatioUrlParams = {
  /** Aspect ratio based URL from CMS */
  src?: string | null;
  /** Carousel container width */
  width: number;
};

const BREAK_POINTS = Object.freeze({
  small: 640,
  medium: 768,
  large: 1024,
  extraLarge: 1280,
  twoExtraLarge: 1440,
});

export type BreakPoints = keyof typeof BREAK_POINTS;

/**
 * Returns a set of aspect ratio based URLs (with 2x and 3x options) bucketed by breakpoints
 * @param src Aspect ratio based URL from CMS
 * @param width desired image width
 * @returns imgUrl, twoXimgUrl, threeXImgUrl
 */
export const getAspectRatioUrl = ({ src, width }: GetAspectRatioUrlParams) => {
  if (!src) return { imgUrl: null, twoXimgUrl: null, threeXImgUrl: null };

  const srcUrl = new URLSearchParams(src);

  const cw = srcUrl.has('cw') ? parseInt(srcUrl.get('cw') as string) : null;
  const ch = srcUrl.has('ch') ? parseInt(srcUrl.get('ch') as string) : null;

  // If a crop width or height is not present in the URL, return null as we cant do the math to return the appopriate img src. Contact the CMS team in case we see broken assets
  if (!cw || !ch) return { imgUrl: null, twoXimgUrl: null, threeXImgUrl: null };

  const aspectRatioVariants = Object.entries(BREAK_POINTS).reduce(
    (accum, [breakPointName, breakPointWidth]) => {
      const imgUrl = `${src}&rw=${breakPointWidth}&rh=${Math.round((breakPointWidth * ch) / cw)}`;

      const twoXimgUrl = `${src}&rw=${breakPointWidth * 2}&rh=${
        Math.round((breakPointWidth * ch) / cw) * 2
      }`;

      const threeXImgUrl = `${src}&rw=${breakPointWidth * 3}&rh=${
        Math.round((breakPointWidth * ch) / cw) * 3
      }`;

      return { ...accum, [breakPointName]: { imgUrl, twoXimgUrl, threeXImgUrl } };
    },
    {} as Record<BreakPoints, { imgUrl: string; twoXimgUrl: string; threeXImgUrl: string }>
  );

  switch (true) {
    case width <= BREAK_POINTS.small:
      return aspectRatioVariants.small;

    case width > BREAK_POINTS.small && width <= BREAK_POINTS.medium:
      return aspectRatioVariants.medium;

    case width > BREAK_POINTS.medium && width <= BREAK_POINTS.large:
      return aspectRatioVariants.large;

    case width > BREAK_POINTS.large && width <= BREAK_POINTS.extraLarge:
      return aspectRatioVariants.extraLarge;

    default:
      return aspectRatioVariants.twoExtraLarge;
  }
};
