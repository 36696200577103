import { Spinner } from './spinner';
import { Dialog, DialogTitle } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';

type SpinnerDialogProps = {
  /* Whether the dialog is open or closed */
  isOpen: boolean;
  /* Loading text provided to the dialog, defaults to Please wait... */
  text?: string;
};

export function SpinnerDialog({ isOpen, text }: SpinnerDialogProps) {
  const { t } = useTranslation('osc-spinner');
  return (
    <Dialog isOpen={isOpen} hasChildrenWithTitle dialogClassName="w-48">
      <div className="flex flex-col items-center justify-center gap-4">
        <Spinner size="xl" />
        <DialogTitle>{text || t('loadingText')}</DialogTitle>
      </div>
    </Dialog>
  );
}

SpinnerDialog.displayName = 'SpinnerDialog';

export default SpinnerDialog;
