import cx from 'classnames';
import type * as React from 'react';
import { forwardRef } from 'react';
import { useTabs } from './tabs.context';
import { sanitizeId } from './tabs.utils';

type TabPanel = React.HTMLAttributes<HTMLDivElement>;

/**
 * The `TabPanel` component is used to render the content of the `Tab` component. It sets up a11y properties for the best compliance with the WCAG 2.0 accessibility guidelines.
 */
const TabPanel = forwardRef<HTMLDivElement, TabPanel>(
  ({ id, className, ...rest }, forwardedRef) => {
    const { active } = useTabs();
    const safeId = sanitizeId(id);
    const isActive = active === safeId;

    return (
      <div
        id={`tab-panel-${safeId}`}
        aria-labelledby={safeId}
        aria-hidden={!isActive}
        role="tabpanel"
        tabIndex={0}
        className={cx('w-full', className, {
          hidden: !isActive,
        })}
        ref={forwardedRef}
        {...rest}
      />
    );
  }
);

TabPanel.displayName = 'TabPanel';

export { TabPanel };
export default TabPanel;
