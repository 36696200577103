import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { Spacer } from '@dx-ui/osc-spacer';
import { LivSmartAccent } from './illustrations/livsmart-accent';
import { LivSmartFurniture } from './illustrations/livsmart-furniture';
import { EnterpriseFiligree } from './illustrations/enterprise-filigree';
import { HamptonSignature } from './illustrations/hampton-signature';

export default createCpmComponentDefinition(
  'Illustration Block',

  function mapComponentData({ data }) {
    return data;
  },

  function IllustrationBlock({ componentParams }) {
    if (componentParams.illustration) {
      switch (componentParams.illustration) {
        case 'ey-accent':
          return (
            <Spacer>
              <LivSmartAccent />
            </Spacer>
          );

        case 'ey-furniture':
          return (
            <Spacer>
              <LivSmartFurniture />
            </Spacer>
          );

        case 'hx-signature-default':
          return (
            <Spacer>
              <HamptonSignature
                backgroundColor="white"
                fillColor="#0047B5"
                testId="hampton-signature-default"
              />
            </Spacer>
          );

        case 'hx-signature-light':
          return (
            <Spacer>
              <HamptonSignature
                backgroundColor="#BDD4E5"
                fillColor="#0047B5"
                testId="hampton-signature-light"
              />
            </Spacer>
          );

        case 'hx-signature-dark':
          return (
            <Spacer>
              <HamptonSignature
                backgroundColor="#002666"
                fillColor="#0047B5"
                testId="hampton-signature-dark"
              />
            </Spacer>
          );

        case 'ww-filigree-default':
          return (
            <Spacer styles={{ backgroundColor: '#F0E9E6' }}>
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.1"
                testId="enterprise-filigree-default"
              />
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.1"
                testId="enterprise-filigree-default"
              />
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.1"
                testId="enterprise-filigree-default"
              />
            </Spacer>
          );

        case 'ww-filigree-light':
          return (
            <Spacer styles={{ backgroundColor: '#F0E9E6' }}>
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.35"
                testId="enterprise-filigree-light"
              />
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.35"
                testId="enterprise-filigree-light"
              />
              <EnterpriseFiligree
                fillColor="#F0E9E6"
                opacity="0.35"
                testId="enterprise-filigree-light"
              />
            </Spacer>
          );

        case 'ww-filigree-dark':
          return (
            <Spacer styles={{ backgroundColor: '#002F61' }}>
              <EnterpriseFiligree
                fillColor="#002F61"
                opacity="1"
                testId="enterprise-filigree-dark"
              />
              <EnterpriseFiligree
                fillColor="#002F61"
                opacity="1"
                testId="enterprise-filigree-dark"
              />
              <EnterpriseFiligree
                fillColor="#002F61"
                opacity="1"
                testId="enterprise-filigree-dark"
              />
            </Spacer>
          );

        case 'none':
          return null;

        default:
          return null;
      }
    }
  }
);
