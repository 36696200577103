import { type RefObject } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { BrandButton } from '@dx-ui/osc-brand-buttons';

export type BackToTopProps = {
  /** The main tag reference */
  mainRef: RefObject<HTMLElement | null>;
  /**
   * CMS ONLY: Dark color theme or Light color theme.
   */
  brandComponentTheme?: CmsBrandComponentTheme;
};

/**
 * Back to Top adds a button just above the footer, when clicked the focus moves back to the top of the page. Targets the h1, if present, or to a ref within `<main>`.
 *
 * If your pages don't consistently have an h1, add `ref={mainRef}` to your `<main>` element, [see Brand/CPM implementation](https://gitlab.svc-m.hhc.hilton.com/pac/hiw/dx-ui/-/blob/main/libs/cpm/cpm-mapping-brands/src/components/Layout.tsx#L77).
 */
export const BackToTop = ({ mainRef, brandComponentTheme }: BackToTopProps) => {
  const [t] = useTranslation('osc-back-to-top');

  const isReducedMotion = getIsReducedMotion();

  const focusFirstElement = () => {
    if (isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: isReducedMotion ? 'instant' : 'smooth',
      });
    }

    if (mainRef && mainRef.current) {
      const main = mainRef.current;
      let headingSelected = false;

      const focusableHeadings = main.querySelectorAll('h1');
      const focusNoHeadings = document.getElementById('skipped');

      for (let i = 0; i < focusableHeadings.length; i++) {
        if (
          isBrowser &&
          focusableHeadings[i]?.getAttribute('display') !== 'none' &&
          focusableHeadings[i]?.getAttribute('visibility') !== 'hidden'
        ) {
          (focusableHeadings[i] as HTMLElement)?.focus({ preventScroll: true });
          headingSelected = true;

          break;
        }
      }
      if (!headingSelected) {
        (focusNoHeadings as HTMLElement).focus({ preventScroll: true });
      }
    }
  };

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      className={cx(
        'brand-gu:bg-quarternary brand-ou:bg-secondary relative flex justify-center p-11',
        {
          'bg-bg-light': isLight,
          'bg-bg-dark': isDark,
        }
      )}
    >
      <BrandButton
        onClick={focusFirstElement}
        label={t('backToTop')}
        iconProps={{
          name: 'arrowhead-up-circle',
          size: 'sm',
          variant: 'solid',
        }}
      />
    </div>
  );
};

export default BackToTop;
