import { useBrComponentContext } from '../adapters/hooks';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';

/**
 * This hook decides if the Bloomreach manage content button should be rendered
 */
export function useHideManageContentButton() {
  const page = useCpmMergedBrPageContext();
  const component = useBrComponentContext();
  const componentName = component.getName();
  const document = page.getDocument<{
    model: {
      data: {
        hidePageHeader: boolean | null;
        hidePageFooter: boolean | null;
      };
    };
  }>();

  if (componentName === 'Header') {
    return document?.model.data.hidePageHeader === true;
  }

  if (componentName === 'Footer') {
    return document?.model.data.hidePageFooter === true;
  }

  return false;
}
