import { Image } from './utils';
import type { CaptionProps } from '@dx-ui/osc-caption';
import type { ReactNode } from 'react';
import cx from 'classnames';

export type OffsetImage = { alt: string; src: string; captionData?: CaptionProps };

export interface TOffset {
  image: { small: OffsetImage; large: OffsetImage };
  headline: ReactNode;
  /**
   * Content and images are displayed in the reverse order
   */
  isInversePattern?: boolean;
  /** Add Tailwind classes to root element */
  className?: string;
}

export const Offset: React.FC<React.PropsWithChildren<TOffset>> = ({
  image,
  headline,
  children,
  isInversePattern = false,
  className = '',
}) => {
  return (
    <div
      className={cx('relative', {
        [className]: !!className,
      })}
    >
      <div
        className={cx('grid gap-8 md:grid-rows-3 md:gap-6 lg:gap-8', {
          'md:grid-cols-[2fr_1fr]': isInversePattern,
          'md:grid-cols-[1fr_2fr]': !isInversePattern,
        })}
      >
        <div
          className={cx('self-end md:translate-y-20 lg:translate-y-14 xl:translate-y-0', {
            'md:order-2': isInversePattern,
          })}
        >
          {headline}
        </div>
        <div
          className={cx('md:row-span-2 md:self-end', {
            'md:order-1': isInversePattern,
          })}
        >
          {image?.large ? <Image {...image.large} aspectRatio="4:2" /> : null}
        </div>
        <div
          className={cx('self-center md:row-span-2', {
            'md:order-3': isInversePattern,
          })}
        >
          {image?.small ? <Image {...image.small} aspectRatio="4:2" /> : null}
        </div>
        <div className={cx({ 'md:order-4': isInversePattern })}>{children}</div>
      </div>
    </div>
  );
};

export default Offset;
