import { createCpmComponentDefinition, shouldRenderExperiment } from '@dx-ui/cpm-sdk';
import Accordion from './Accordion';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useSegmentedItems } from '../../hooks/use-segments';
import cx from 'classnames';
import { Markdown } from '@dx-ui/osc-markdown';
import { useRouter } from 'next/router';
import { Link } from '@dx-ui/osc-link';

interface FaqItem {
  question?: string;
  answerParagraph?: string[];
  answerOrderedList?: string[];
  answerUnOrderedList?: string[];
  // TODO: CPM-1799 - translate FAQ links inside of the SDK
  link?: {
    linkUrl?: string;
    linkLabel?: string;
    adaDescription?: string;
    isNewWindow?: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  }[];
}

interface FaqBlock {
  faq?: FaqItem[];
}

export interface AccordionContentBlock {
  heading?: string;
  description?: string;
  unorderedList?: string[];
  orderedList?: string[];
  // TODO: CPM-1799 - translate FAQ links inside of the SDK
  links?: {
    linkUrl: string;
    linkLabel: string;
    adaDescription?: string;
    isNewWindow: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  }[];
}

const remapFaqContentToDetails = (faqContent: FaqBlock[]) => {
  return faqContent.flatMap((block, blockIndex) =>
    Array.isArray(block.faq)
      ? block.faq.map((faqItem, faqIndex) => {
          const content: AccordionContentBlock[] = [];

          if (Array.isArray(faqItem.answerParagraph)) {
            faqItem.answerParagraph.forEach((paragraph) => {
              content.push({ description: paragraph });
            });
          }

          if (Array.isArray(faqItem.answerOrderedList)) {
            content.push({ orderedList: faqItem.answerOrderedList });
          }

          if (Array.isArray(faqItem.answerUnOrderedList)) {
            content.push({ unorderedList: faqItem.answerUnOrderedList });
          }

          if (Array.isArray(faqItem.link)) {
            content.push({
              // TODO: CPM-1799 - translate FAQ links inside of the SDK
              links: faqItem.link.map((link) => ({
                linkUrl: link.linkUrl ?? '',
                linkLabel: link.linkLabel ?? '',
                adaDescription: link.adaDescription,
                isNewWindow: Boolean(link.isNewWindow),
                experimentationConfiguration: link.experimentationConfiguration,
              })),
            });
          }

          return {
            key: `faq_${blockIndex}_${faqIndex}`,
            collapsedButtonLabel: faqItem.question ?? '',
            expandedButtonLabel: faqItem.question ?? '',
            content,
          };
        })
      : []
  );
};

export default createCpmComponentDefinition(
  'Accordion',

  function mapComponentData({ data }) {
    return {
      key: `acc${data.id}`,
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      collapsedButtonLabel: data.headline || data.label || data.title || '',
      expandedButtonLabel: data.headline || data.label || data.title || '',
      content: data.contentBlock,
      isFaq: data.contentType === 'dxcms:Faq',
      faqContent: data.subTopic ? remapFaqContentToDetails(data.subTopic) : [],
    };
  },

  function CpmAccordion({
    items = [],
    listData,
    componentParams,
    mappedPage: { brandCode },
    experimentationAgents,
  }) {
    const filteredItems = useSegmentedItems(items || []);

    const isDark = componentParams.theme === 'dark';
    const isLight = componentParams.theme === 'light';
    const { locale = 'en' } = useRouter();
    const origin = typeof window !== 'undefined' ? window.location.origin : '';
    const markdownProps = { language: locale, origin };

    const renderContentBlock = (block: AccordionContentBlock) => {
      const contentType = block.heading
        ? 'heading'
        : block.description
        ? 'description'
        : block.unorderedList
        ? 'unorderedList'
        : block.orderedList
        ? 'orderedList'
        : block.links
        ? 'links'
        : '';

      switch (contentType) {
        case 'description':
          return (
            <Markdown key={`description_${block.description}`} {...markdownProps}>
              {block.description!}
            </Markdown>
          );
        case 'heading':
          return (
            <div key={`heading_${block.heading}`} className="mb-2 text-xl font-bold">
              <Markdown {...markdownProps}>{block.heading!}</Markdown>
            </div>
          );
        case 'unorderedList':
          return Array.isArray(block.unorderedList) ? (
            <ul key={`ul_${block.unorderedList[0]}`} className="ms-7 list-disc">
              {block.unorderedList.map((listItem) => (
                <li key={listItem}>
                  <Markdown {...markdownProps}>{listItem}</Markdown>
                </li>
              ))}
            </ul>
          ) : null;

        case 'orderedList':
          return Array.isArray(block.orderedList) ? (
            <ol className="ms-7 list-decimal" key={`ol_${block.orderedList[0]}`}>
              {block.orderedList.map((listItem) => (
                <li key={listItem}>
                  <Markdown {...markdownProps}>{listItem}</Markdown>
                </li>
              ))}
            </ol>
          ) : null;

        case 'links':
          return Array.isArray(block.links)
            ? block.links.map((link) => (
                <div key={link.linkUrl}>
                  <Link
                    url={link.linkUrl}
                    adaDescription={link.adaDescription}
                    isNewWindow={link.isNewWindow}
                    data-conductrics-goal={link.experimentationConfiguration?.goal}
                    data-conductrics-value={link.experimentationConfiguration?.value}
                  >
                    {link.linkLabel}
                  </Link>
                </div>
              ))
            : null;

        default:
          return null;
      }
    };

    if (filteredItems[0]?.isFaq && filteredItems[0]?.faqContent) {
      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration || 'none',
          }}
        >
          <Accordion
            headline={listData?.headline || undefined}
            description={listData?.description || undefined}
            brandComponentTheme={componentParams.theme}
            details={filteredItems[0].faqContent.map((faqDetail) => ({
              ...faqDetail,
              content: (
                <div
                  className={cx('border-border border-b p-6', {
                    'text-text-inverse': isDark,
                    'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
                  })}
                >
                  {faqDetail.content.map((content) => {
                    if (content.links) {
                      content.links = content.links.filter((link) =>
                        shouldRenderExperiment(
                          experimentationAgents,
                          link.experimentationConfiguration
                        )
                      );
                    }

                    return renderContentBlock(content);
                  })}
                </div>
              ),
            }))}
          />
        </BrandComponentThemeInline>
      );
    } else {
      const details = filteredItems.map((item) => ({
        ...item,
        content: (
          <div
            className={cx('border-border border-b p-6', {
              'text-text-inverse': isDark,
              'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
            })}
          >
            {item?.content?.map(renderContentBlock)}
          </div>
        ),
      }));

      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration || 'none',
          }}
        >
          <Accordion
            details={details}
            headline={listData?.headline || undefined}
            description={listData?.description || undefined}
            brandComponentTheme={componentParams.theme}
          />
        </BrandComponentThemeInline>
      );
    }
  }
);
