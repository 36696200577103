import type * as React from 'react';

import type { KeyedMappingDefinition } from './types';

import { makeFetchServerCpmPage } from './fetchServerCpmPage';
import type { CpmRendererProps } from './CpmRenderer';
import { makeCpmRenderer } from './CpmRenderer';

export function generateCpmAppInterface<Definitions extends KeyedMappingDefinition>(
  definitions: Partial<Definitions>
): {
  fetchServerCpmPage: ReturnType<typeof makeFetchServerCpmPage<Definitions>>;
  CpmRenderer: React.FC<CpmRendererProps<Definitions>>;
} {
  const CpmRenderer = makeCpmRenderer<Definitions>(definitions);
  const fetchServerCpmPage = makeFetchServerCpmPage<Definitions>(definitions);

  return {
    fetchServerCpmPage,
    CpmRenderer,
  };
}
