/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AdobeTagManager } from '@dx-ui/config-metrics';
import type {
  DefaultPageDataProps,
  TErrorPageDataProps,
  TPageDetailsProps,
  TTrackGenericAction,
  TTextHeadlinerProps,
  TBrandShowcaseProps,
  TCarouselNavigationProps,
  TFullWidthImageProps,
  TGridItemProps,
  TTabItemProps,
  AlertDataLinkProps,
  AlertDataProps,
  TUserDataProps,
  TPageName,
  TSearchPageWidgetSubmissionProps,
  Metrics,
} from './types';
import {
  createPropertySearchDateInfoString,
  createPropertySearchRateCodeString,
  createPropertySearchCriteriaString,
  parseStringyBool,
} from './utils';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export const defaultPageData = {
  brandCode: '',
  brandName: '',
  lang: 'en',
  pageName: 'Home',
  destinationUrl: '',
};

const capitalize = (s: string) => s && s.charAt(0).toUpperCase() + s.slice(1);

const getBrand = ({ brandCode: receivedBrandCode, brandName: receivedBrandName }: any) => {
  const hiltonName = 'Hilton';
  let isHiltonPortfolio = false;
  let brandCode = receivedBrandCode;
  let brandName = receivedBrandName;
  // need to override Hilton Portfolio to use Hilton values for analytics
  if (brandCode === 'WW') {
    isHiltonPortfolio = true;
    brandCode = 'HI';
    brandName = hiltonName;
  }
  brandName = brandName || hiltonName;

  return { brandCode, brandName, isHiltonPortfolio };
};

export const getPageDetails = (brandPath: string[]) => {
  const pageDetails = {
    pageDetail1: '',
    pageDetail2: '',
    pageDetail3: '',
  };
  if (!brandPath || !brandPath.length) return pageDetails;
  brandPath.forEach((pageDetail, index) => {
    (pageDetails as any)[`pageDetail${index + 1}`] = capitalizeHyphen(pageDetail);
  });
  return pageDetails;
};

export const getPageType = (contentType: string) => {
  switch (contentType) {
    case 'article':
      return {
        primaryCategory: 'Content',
        adobePageType: 'Topic',
        trackEventType: 'articlePageView',
      };
    case 'articleCrawl':
      return {
        primaryCategory: 'Content',
        adobePageType: 'Topic',
        trackEventType: 'articleCrawlPageView',
      };
    case 'articleHub':
      return {
        primaryCategory: 'Content',
        adobePageType: 'Topic',
        trackEventType: 'articleHubPageView',
      };
    case 'eventsLanding':
      return {
        primaryCategory: 'Events',
        adobePageType: 'Landing',
        trackEventType: 'eventsLandingPageView',
      };
    case 'brandLanding':
      return {
        primaryCategory: 'Brand',
        adobePageType: 'Landing',
        trackEventType: 'brandLandingPageView',
      };
    case 'brandHomepage':
      return {
        primaryCategory: 'Brand',
        adobePageType: 'Home',
        trackEventType: 'brandPageView',
      };
    case 'eventsOffer':
      return {
        primaryCategory: 'Events',
        adobePageType: 'Offer',
        trackEventType: 'eventsOfferPageView',
      };
    case 'offerLanding':
      return {
        primaryCategory: 'Offers',
        adobePageType: 'Landing',
        trackEventType: 'offerLandingPageView',
      };
    default:
      return {
        primaryCategory: 'Home',
        adobePageType: 'Other',
        trackEventType: 'brandPageView',
      };
  }
};

export const capitalizeHyphen = (input: string): string => {
  if (!input) return '';
  return input.replace(/(^|[\s-])\S/g, (match: string) => match.toUpperCase());
};

export const hyphenize = (input?: string): string => {
  return (input || '').replace(/\s+/g, '-');
};

const getDynamicTagName = (fullBrandArr: string[], isHiltonPortfolio: boolean) => {
  let dynamicData = '';
  if (isHiltonPortfolio && !fullBrandArr.length) {
    dynamicData = ':Portfolio';
    return dynamicData;
  }
  if (!fullBrandArr || !fullBrandArr.length) return dynamicData;
  fullBrandArr.forEach((desc) => {
    if (desc.length) dynamicData += `:${capitalizeHyphen(desc)}`;
  });
  return dynamicData;
};

const getPageName = ({
  lang,
  brandName = 'Multibrand',
  primaryCategory,
  adobePageType,
  splitBrandPath,
  isHiltonPortfolio,
  brandCode,
}: TPageName) => {
  const dynamicTagName = getDynamicTagName(splitBrandPath, isHiltonPortfolio);
  return `Browser:${(lang || 'en').toUpperCase()}:${
    brandCode ? brandCode : brandName
  }:${primaryCategory}:${adobePageType}${dynamicTagName}`;
};

type PageDetail = 'pageDetail1' | 'pageDetail2' | 'pageDetail3';

/**
 * Allow CPM to override the page level detail vales if they are set in the CMS.
 * Note: When CMS provides values, this function will replace spaces with hyphens
 * BUT NOT capitalize the values.
 * This is to support corp advantage/preferred rates pages.
 *
 * The existing implementation remains the same (capitalize + hyphenate).
 */
const getDetailsForPage = (
  pageDetailLevels: (string | undefined)[],
  originalFullBrandPath: string
) => {
  const replacementPageDetails = pageDetailLevels
    .map((segment) => {
      if (!segment) {
        return '';
      }

      return encodeURIComponent(segment.replace(/\s+/g, '-'));
    })
    .filter((segment) => !!segment);

  if (replacementPageDetails.length > 0) {
    const details = replacementPageDetails.reduce((_details, value, index) => {
      return {
        ..._details,
        [`pageDetail${index + 1}`]: hyphenize(value),
      };
    }, {}) as Record<PageDetail, string>;

    return {
      splitBrandPath: replacementPageDetails,
      ...details,
    };
  }

  const splitBrandPath = originalFullBrandPath.split('/').filter((item) => item !== '');

  return {
    splitBrandPath,
    ...getPageDetails(splitBrandPath),
  };
};

/**
 * Allow CPM to override the page tracking type instead of relying on pageType
 */
const getTrackingEventType = ({ pageType, analyticsTaggingOverrides }: DefaultPageDataProps) => {
  const { trackEventType: legacyTrackEventType } = getPageType(pageType ?? '');
  return analyticsTaggingOverrides?.trackEventType || legacyTrackEventType;
};

type Constructor<T> = new (...args: any[]) => T;
type AdobeInterface = typeof AdobeTagManager;
export interface ExtendedAdobeTagManager extends AdobeInterface, Metrics {}

export function extendAdobeTagManager<Base extends Constructor<AdobeTagManager>>(
  Base: Base,
  appVersion: string
): ExtendedAdobeTagManager {
  return class ExtendedAdobeTagManager extends Base {
    public setDefaultPageData(props: DefaultPageDataProps) {
      this.mergedProps = {
        ...defaultPageData,
        ...(this.mergedProps || {}),
        ...props,
      };
      this.setDataLayer();
    }

    setPageData() {
      const trackEventType = getTrackingEventType(this.mergedProps);
      this['_resetEvents']();

      this.trackEvent(trackEventType, null, false);
    }

    public trackUserLoggedIn({ hhonorsNumber, tierName, points, goUserTypes }: TUserDataProps) {
      if (hhonorsNumber) {
        this['_set']('user[0].profile[0].profileInfo.profileID', hhonorsNumber);
        this['_set']('user[0].profile[0].profileInfo.pointsBalance', points);
        this['_set']('user[0].profile[0].profileInfo.rewardsTier', tierName);
        this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-in');

        if (goUserTypes && goUserTypes.length) {
          this['_set']('user[0].profile[0].attributes.goUserType', goUserTypes.join(','));
        } else {
          this['_set']('user[0].profile[0].attributes.goUserType', '');
        }
      } else {
        this['_set']('user[0].profile[0].profileInfo.profileID', '');
        this['_set']('user[0].profile[0].profileInfo.pointsBalance', '');
        this['_set']('user[0].profile[0].profileInfo.rewardsTier', '');
        this['_set']('user[0].profile[0].attributes.goUserType', '');
        this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-out');
      }
      this.trackEvent('trackUserLoggedIn', null, true);
    }

    public setPageInfo(key: string, value: string[] | string) {
      this['_set'](`page.pageInfo.${key}`, Array.isArray(value) ? value.join(',') : value);
    }

    public setPageDetails({ pageDetail, detailIdx = 1 }: TPageDetailsProps) {
      this['_set'](`page.pageInfo.pageDetail${detailIdx}`, pageDetail);
    }

    public setAttributes(valuesByAttributes: { [key: string]: string | Record<string, unknown> }) {
      const attributes = Object.keys(valuesByAttributes);
      attributes.forEach((attribute) => {
        this['_set'](attribute, valuesByAttributes[attribute]);
      });
    }

    public trackEvent(
      eventName: string,
      params: Record<string, unknown> | null = null,
      shouldAddEvent = false
    ) {
      void this['track'](eventName, params, shouldAddEvent);
      if (!shouldAddEvent) {
        this['_resetEvents']();
      }
    }

    private isBrandPage() {
      return !this.mergedProps.ctyhocn;
    }

    public trackPageView() {
      const trackEventType = getTrackingEventType(this.mergedProps);
      this['_resetEvents']();
      this.trackEvent(this.isBrandPage() ? trackEventType : 'propertyPageView', null, true);
    }

    /* This method would unset the digitalData page attribute `propertySearchDateInfo` which is set by the shared metrics package by default,
     analytics does not want this property to be set on page load */
    public removePropertySearchInfo() {
      return (
        this.digitalData?.page?.attributes?.propertySearchDateInfo &&
        this._unset('page.attributes.propertySearchDateInfo')
      );
    }

    public trackTextHeadlinerClick({
      textHeadlinerNumber,
      itemNumber,
      totalItems,
      leftArrow,
      rightArrow,
    }: TTextHeadlinerProps) {
      this['_set'](
        'page.attributes.actionDetail',
        `Carousel:${
          this.isBrandPage() ? 'Brand' : 'Property'
        }:TextHeadliner:Set:${textHeadlinerNumber}:${
          leftArrow ? 'Leftarrow' : rightArrow ? 'Rightarrow' : ''
        }`
      );
      this['_set']('page.pageInfo.gridPosition', `${itemNumber}:${totalItems}`);
      this['_set']('event.eventInfo.eventAction', 'textHeadlinerClick');

      this.trackEvent('textHeadliner', null, true);
    }

    trackBrandShowcaseClick({
      brandShowcaseNumber,
      itemNumber,
      totalItems,
      brandName,
    }: TBrandShowcaseProps) {
      this.setAttributes({
        'page.attributes.actionDetail': `Tile:Brand:Brandshowcase:${brandShowcaseNumber}:${brandName}`,
        'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
        'event.eventInfo.eventAction': 'BrandShowcaseClick',
      });
      this.trackEvent('brandShowcase', null, true);
    }

    trackCarouselNavigationClick({
      navigationClick,
      carouselNumber,
      index,
      totalDots,
    }: TCarouselNavigationProps) {
      const label = navigationClick;
      this.setAttributes({
        'page.attributes.actionDetail': `Carousel:Brand:ImageCarousel:${carouselNumber}:${label}`,
        'page.attributes.imageCarouselNumber': `${index + 1}:${totalDots}`,
        'event.eventInfo.eventAction': 'brandCarouselClick',
      });
      this.trackEvent('brandCarousel', null, true);
    }

    trackFullWidthImageClick({ fullWidthImageNumber, label }: TFullWidthImageProps) {
      this.setAttributes({
        'page.attributes.actionDetail': `Tile:Brand:FullWidthImage:${fullWidthImageNumber}:${label}`,
        'page.pageInfo.gridPosition': '',
        'event.eventInfo.eventAction': 'BrandFullWidthImageClick',
      });
      this.trackEvent('brandFullWidthImage', null, true);
    }

    trackGridItemClick({ itemNumber, totalItems, label, gridNumber, is4XGrid }: TGridItemProps) {
      if (is4XGrid) {
        this.setAttributes({
          'page.attributes.actionDetail': `Tile:Brand:4XImage:${gridNumber}:${label}`,
          'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
          'event.eventInfo.eventAction': 'Brand4XTileClick',
        });
        this.trackEvent('brand4XTile', null, true);
      } else {
        this.setAttributes({
          'page.attributes.actionDetail': `Tile:Brand:369Image:${gridNumber}:${label}`,
          'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
          'event.eventInfo.eventAction': 'Brand369TileClick',
        });
        this.trackEvent('brand369Tile', null, true);
      }
    }

    trackTabItemClick({ label, tabComponentNumber, itemNumber, totalItems }: TTabItemProps) {
      this.setAttributes({
        'page.attributes.actionDetail': `Tab:Brand:${tabComponentNumber}:${label}`,
        'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
        'event.eventInfo.eventAction': 'brandTabClick',
      });
      this.trackEvent('brandTab', null, true);
    }

    trackAlertLinkClick({ text, linkLabel }: AlertDataLinkProps) {
      this.setAttributes({
        'page.attributes.actionDetail': `Modal:Brand:Alert:${text}:${linkLabel}`,
        'event.eventInfo.eventAction': 'brandAlertModalLink',
      });
      this.trackEvent('brandAlertModalLink', null, true);
    }

    trackAlertClick({ text }: AlertDataProps) {
      this.setAttributes({
        'page.attributes.actionDetail': `Modal:Brand:Alert:${text}`,
        'event.eventInfo.eventAction': 'brandAlertModal',
      });
      this.trackEvent('brandAlertModal', null, true);
    }

    trackSearchPageWidgetSubmission(searchData: TSearchPageWidgetSubmissionProps) {
      const rooms = searchData?.rooms || [];
      this.setAttributes({
        'page.attributes.search': searchData,
        'page.attributes.propertySearchCountry': searchData?.country,
        'page.attributes.propertySearchLocation': searchData?.location,
        'page.attributes.propertySearchType': searchData.searchType,
        'page.attributes.propertySearchDateInfo': createPropertySearchDateInfoString({
          arrivalDate: searchData?.dates?.from,
          departureDate: searchData?.dates?.til,
        }),
        'page.attributes.propertySearchRateCode': createPropertySearchRateCodeString({
          ...searchData?.analyticsSpecialRates,
          groupCode: searchData?.rates?.groupCode,
          corporateCode: searchData?.rates?.corpAccount,
          promoCode: searchData?.rates?.promoCode,
        }),
        'page.attributes.propertySearchCriteria': createPropertySearchCriteriaString({
          numRooms: (Array.isArray(rooms) && rooms.length) || 1,
          numAdults: (Array.isArray(rooms) && rooms.length > 0 && rooms[0]?.adults.length) || 1,
          numChildren: (Array.isArray(rooms) && rooms.length > 0 && rooms[0]?.children.length) || 0,
          isFlexDatesEnabled: parseStringyBool(searchData?.dates.flexDates) || false,
          isUsePoints: searchData?.analyticsSpecialRates.isUsePoints,
          isSpecialRatesUsed:
            Object.values(searchData?.analyticsSpecialRates).some(Boolean) ||
            !!searchData?.rates?.promoCode ||
            !!searchData?.rates?.groupCode ||
            !!searchData?.rates?.corpAccount,
          distance: searchData?.distance ?? 0,
        }),
      });
      this.trackEvent('portfolioSearchCriteria', null, true);
    }

    /**
     * @method trackGenericAction
     * @param {string} eventName - defines the name of the event given to Adobe Tag Manager.
     * @param {string} actionDetail - gives more information about the event.
     *
     * Available to set common pieces of data used between analytics functions
     *
     */
    public trackGenericAction({ eventName = '', actionDetail = '' }: TTrackGenericAction) {
      this.setDataLayer();
      this['_set']('page.attributes.actionDetail', actionDetail);
      this.trackEvent(eventName, null, true);
    }

    public setErrorPageData(props: TErrorPageDataProps) {
      const mergedProps = { ...defaultPageData, ...props };
      const { brandCode } = mergedProps;
      const { errorCode } = props;
      let { errorName } = props;

      errorName = errorName || '';

      this['_set']('event.eventInfo.eventAction', 'error');
      this['_set']('page.category.primaryCategory', 'error');
      this['_set']('page.category.subSection', `error:${errorName}`);
      this['_set']('page.pageInfo.error', errorCode);
      this['_set']('page.pageInfo.pageName', `${brandCode}:error:${errorName}`);

      this.trackEvent('errorPageView', null, false);
      this.trackEvent('errorEvent', null, false);
    }

    /**
     *
     * @method _addPageBottom
     * Overriding _addPageBottom to prevent running server side
     */
    public _addPageBottom() {
      if (isBrowser && document && document.body) {
        const { body } = document;
        const script = document.createElement('script');
        // Lets add to page so Adobe consultant knows we've added the pageBottom() call.
        const scriptContent = `
         "use strict";
         var _satellite = window._satellite;
         if (_satellite) {
           document.write = document.body.appendChild
           _satellite.pageBottom();
         }
       `;
        script.text = scriptContent;
        return body.appendChild(script);
      }
    }

    private mergedProps: DefaultPageDataProps = {} as any;

    private setDataLayer = () => {
      const {
        ctyhocn = '',
        country = '',
        lang,
        destinationUrl = '',
        template = '',
        fullBrandPath,
        pageType,
        analyticsTaggingOverrides,
      } = this.mergedProps;
      const { pageDetail1, pageDetail2, pageDetail3, splitBrandPath } = getDetailsForPage(
        [
          analyticsTaggingOverrides?.pageDetailOne,
          analyticsTaggingOverrides?.pageDetailTwo,
          analyticsTaggingOverrides?.pageDetailThree,
        ],
        fullBrandPath ?? ''
      );
      const { brandCode, brandName, isHiltonPortfolio } = getBrand(this.mergedProps);
      const { primaryCategory: legacyPrimaryCategory, adobePageType: legacyPageType } = getPageType(
        pageType ?? ''
      );
      const primaryCategory = analyticsTaggingOverrides?.primaryCategory || legacyPrimaryCategory;
      const adobePageType = analyticsTaggingOverrides?.adobePageType || legacyPageType;
      // CPM-1583 - use brand code over brand name for ALL page names
      const brandCodeForPageName = analyticsTaggingOverrides?.brandCode || brandCode;

      this['_set']('page.attributes.expTheme', capitalize(template));
      this['_set']('page.attributes.propertySearchCountry', country);
      this['_set']('page.attributes.version', appVersion);

      this['_set']('page.category.brand', brandCode);
      this['_set']('page.category.siteName', brandName);
      this['_set']('page.category.primaryCategory', primaryCategory);
      if (!this.isBrandPage()) {
        this['_set']('page.category.subSection', primaryCategory);
        this['_set']('page.category.subSubSection', '');
        this['_set']('product[0].productInfo.productID', ctyhocn.toUpperCase());
      } else {
        this['_set']('page.attributes.expType', 'New Hilton');
      }

      this['_set']('page.pageInfo.destinationURL', destinationUrl);
      this['_set']('page.pageInfo.language', lang);
      this['_set']('page.pageInfo.pageDetail1', pageDetail1);
      this['_set']('page.pageInfo.pageDetail2', pageDetail2);
      this['_set']('page.pageInfo.pageDetail3', pageDetail3);
      this['_set'](
        'page.pageInfo.pageName',
        getPageName({
          lang,
          brandName,
          primaryCategory,
          adobePageType,
          splitBrandPath,
          isHiltonPortfolio,
          brandCode: brandCodeForPageName,
        })
      );
      this['_set']('page.pageInfo.pageTitle', document.title);
      this['_set']('page.pageInfo.pageType', adobePageType);
    };
  } as any;
}
