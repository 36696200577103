/**
type asserts that the provided value is an object with a property, but makes no guarantee on
the property's type
*/
export function hasProp<Prop extends string>(x: unknown, prop: Prop): x is Record<Prop, unknown> {
  return !!(x && typeof x === 'object' && prop in x);
}

/**
Gets a property from an unknown value.
If the value is an object, a string prop will return the property with that name
If the value is an array, an integer prop will return the property at that index
If the value is not an object that contains the specified prop, will return null
*/
export function getProp(x: unknown, prop: string | number): unknown {
  if (typeof prop === 'string') {
    if (hasProp(x, prop)) {
      return x[prop];
    }
  } else {
    if (x && Array.isArray(x)) {
      return x[prop];
    }
  }

  return null;
}

/**
Recursivly traverses a value, returning the value at that path, if it exists
*/
export function getPath(x: unknown, path: (string | number)[]): unknown {
  let acc = x;
  for (const prop of path) {
    acc = getProp(acc, prop);
  }
  return acc;
}
