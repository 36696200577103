import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import type { StructuredAsset } from '@dx-ui/cpm-sdk';

import { HalfAndHalf as HalfAndHalfOsc } from '@dx-ui/osc-marketing';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import cx from 'classnames';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useSegmentedItems } from '../../hooks/use-segments';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { HamptonHexVisual } from './HamptonHexVisual';

const odd = (index: number) => index * 2 + 1;
const even = (index: number) => index * 2;

export function getForcedIndex(
  index: number,
  imageDisplay: 'left' | 'right' | 'alternate' | 'round' | 'none'
) {
  const isOddIndex = imageDisplay === 'right';
  const isEvenIndex = imageDisplay === 'left';

  return isOddIndex ? odd(index) : isEvenIndex ? even(index) : index;
}

const transformImages = (imagesArray: StructuredAsset[]) => {
  return imagesArray.map((image) => ({
    alt: image.altText || '',
    url: image.aspectRatios['3x2']?.url || '',
    captionData:
      image.caption || image.captionLink
        ? {
            caption: image.caption || '',
            captionLink: image.captionLink || '',
          }
        : undefined,
  }));
};

export default createCpmComponentDefinition(
  'Half & Half',

  function mapData({ data, componentParams: { imageDisplay }, index, mappedPage }) {
    // in order to lean all of the components left or right, we can force index
    // of all items to be either odd or even
    const forcedIndex = getForcedIndex(index, imageDisplay);
    const asset = data.cpmAssets[0];
    const assets = data.cpmAssets;
    const filteredAssets =
      assets?.filter((image): image is StructuredAsset => image !== undefined) || [];
    const carouselImages = assets ? transformImages(filteredAssets) : undefined;

    const imageUrl = asset?.aspectRatios['3x2']?.url ?? '';
    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;
    const imageAltText = asset?.altText ?? '';
    const videoData = data?.videos?.[0];
    const video = videoData
      ? {
          source: videoData.source || '',
          title: videoData?.name || '',
          url: videoData.url,
        }
      : undefined;

    const multimedia = data?.multimedia?.length
      ? mapMultimediaListToVideoProps({ multimedia: data.multimedia, mappedPage })
      : undefined;

    const link = data.link;

    return {
      $ref: data.ref?.$ref,
      imageUrl,
      captionData,
      imageAltText,
      carouselImages,
      index: forcedIndex,
      segmentIds: data?.segmentIds ?? [],
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      headline: data?.headline ?? undefined,
      description: data?.shortDescription ?? undefined,
      cmsTranslationClasses: data.cmsTranslationClasses,
      media: {
        multimedia,
        video,
      },
      link:
        link?.url && link.label
          ? {
              label: link.label,
              url: link.url,
              isNewWindow: link.isNewWindow || false,
              adaDescription: link.adaDescription ?? '',
              experimentationConfiguration: link.experimentationConfiguration,
            }
          : undefined,
    };
  },

  function HalfAndHalf({ items = [], listData: data, componentParams, mappedPage: { brandCode } }) {
    const createManageContentButton = useCreateManageContentButton();
    const filteredItems = useSegmentedItems(items, { maintainUnsegmentedCount: true });
    const isLXR = brandCode === 'OL';

    if (!filteredItems.length) {
      return null;
    }

    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const isDark = componentParams?.theme === 'dark';
    const isLight = componentParams?.theme === 'light';

    const hexEnabled =
      brandCode === 'HP' &&
      componentParams.backgroundIllustration === 'hx-hexagon' &&
      !!componentParams?.theme;

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <HamptonHexVisual
          theme={componentParams.theme ?? ''}
          enabled={brandCode === 'HP' && componentParams.backgroundIllustration === 'hx-hexagon'}
        >
          {data?.headline || data?.description ? (
            <div
              className={cx('container p-6', {
                'text-left': componentParams.listTextAlign === 'left',
                'text-center': componentParams.listTextAlign === 'center',
                'text-right': componentParams.listTextAlign === 'right',
              })}
            >
              {data?.headline && (
                <BrandTextHeader
                  className={cx({
                    'text-text-inverse': isDark,
                    'brand-ht:text-text-inverse': isLight,
                  })}
                >
                  {data?.headline}
                </BrandTextHeader>
              )}

              {data?.description && (
                <BrandTextBody
                  className={cx('font-sans', {
                    'text-text-inverse': isDark,
                    'brand-ht:text-text-inverse': isLight,
                  })}
                  brandComponentTheme={componentParams.theme}
                >
                  {data?.description}
                </BrandTextBody>
              )}
            </div>
          ) : null}
          {itemsWithManageContentButton.map((item) => {
            return (
              <AnimateRevealItem
                key={`${item.index}-${item?.headline}`}
                delay={0}
                animationType="fade-in-up"
                isAnimated={brandCode === 'GU'}
              >
                <HalfAndHalfOsc
                  brandCode={hexEnabled ? brandCode : undefined}
                  brandComponentTheme={hexEnabled ? componentParams.theme : undefined}
                  id={data?.id ?? ''}
                  hasParallax={isLXR}
                  hasImageCarousel={componentParams?.display === 'imageCarousel'}
                  {...item}
                />
              </AnimateRevealItem>
            );
          })}
        </HamptonHexVisual>
      </BrandComponentThemeInline>
    );
  }
);
