import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk';
import type { BrComponent, BrPage } from './types';
import { useContext } from 'react';

function makeBloomContextHook<T>(context: React.Context<T | undefined>, name: string): () => T {
  return () => {
    const extracted = useContext(context);

    if (extracted === undefined) {
      throw new Error(`Invalid Context call for "${name}"`);
    } else {
      return extracted;
    }
  };
}

export const useBrComponentContext = makeBloomContextHook<BrComponent>(
  BrComponentContext,
  'BrComponentContext'
);

export const usePartialBrPageContext = makeBloomContextHook<BrPage>(BrPageContext, 'BrPageContext');

/**
 * Access context without throwing if it doesn't exist
 */
function makeBloomContextHookSafe<T>(
  context: React.Context<T | undefined> | undefined
): () => T | undefined {
  return () => {
    if (context) {
      return useContext(context);
    } else {
      return undefined;
    }
  };
}

/**
 * Hook to access Bloomreach context (if it exists).
 */
export const useBrPageContextSafe = makeBloomContextHookSafe<BrPage>(BrPageContext);
