import { useIsClient } from 'usehooks-ts';

import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { CpmMappingError, createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import VideoPoster from './VideoPoster';
import { useSegmentedItems } from '../../hooks/use-segments';

export default createCpmComponentDefinition(
  'Video Poster',
  function mapData({ data, mappedPage }) {
    const video = data?.videos?.[0];
    const multimedia = data?.multimedia;
    const link = data.link;
    const attachedContent = {
      link: link?.url ? link : undefined,
      description: data.longDescription,
      headline: data.headline,
    };

    if (multimedia?.length) {
      const firstMultimedia = multimedia[0];
      const multimediaCaption = firstMultimedia?.captionLink;
      const multimediaCaptionData =
        multimediaCaption?.linkLabel && multimediaCaption?.linkUrl
          ? {
              caption: multimediaCaption.linkLabel,
              captionLink: multimediaCaption.linkUrl,
            }
          : undefined;

      return {
        $ref: data.ref?.$ref,
        id: data.id,
        segmentIds: data.segmentIds,
        experimentationConfiguration: data.experimentationConfiguration,
        links: data.links,
        ...mapMultimediaListToVideoProps({ multimedia, mappedPage }),
        attachedContent,
        captionData: multimediaCaptionData,
      };
    }

    if (video) {
      if (video.source !== 'cloudinary') {
        throw new CpmMappingError(
          `Video Poster only supports Cloudinary videos, but this document's video source is set to "${video.source}" (${data.contentType} - ${data.displayName})`
        );
      }

      const { caption, captionLink, url: videoUrl, transcript } = video;
      const captionData = caption && captionLink ? { caption, captionLink } : undefined;

      return {
        $ref: data.ref?.$ref,
        id: data.id,
        segmentIds: data.segmentIds,
        experimentationConfiguration: data.experimentationConfiguration,
        links: data.links,
        videoUrl,
        //The "transcript" field in the CMS is being used as an interim measure.
        //https://jira.hilton.com/browse/NHCBP-5914 will be a better long term solution
        altText: transcript,
        captionData,
        attachedContent,
      };
    }

    if (!video && !multimedia) {
      throw new CpmMappingError(
        `Video Poster must have a video or multimedia asset (${data.contentType} - ${data.displayName})`
      );
    }

    return {
      $ref: data.ref?.$ref,
      id: data.id,
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      videoUrl: '',
    };
  },

  function VideoPosterCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);

    // the VideoPoster fails to load the video asset on initial render
    // Triggering a re-render causes the asset to be loaded correctly
    const isClient = useIsClient();

    const data = filteredItems[0];

    if (!data) {
      return null;
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <VideoPoster
          {...data}
          key={String(isClient)}
          brandComponentTheme={componentParams.theme}
          shouldAnimateReveal={brandCode === 'GU'}
        />
      </BrandComponentThemeInline>
    );
  }
);
