/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  BrComponent as BrComponentPoorlyTyped,
  BrPage as BrPagePoorlyTyped,
} from '@bloomreach/react-sdk';
import type { Configuration, Page, PageModel } from '@bloomreach/spa-sdk';
import type { BrMapping } from './types';

export const BrComponent = BrComponentPoorlyTyped as any as React.FC<{ path: string }>;
export const BrPage = BrPagePoorlyTyped as any as React.FC<{
  page: PageModel | Page;
  children: React.ReactNode;
  configuration: Configuration;
  mapping: BrMapping;
}>;
