import { HeroImage } from '@dx-ui/osc-hero-image';
import { HeroVideo } from '@dx-ui/osc-hero-video';
import { HeroTextOverlay } from '@dx-ui/osc-hero-text-overlay';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

import type { OscDomLink } from '@dx-ui/cpm-sdk';
import { CpmMappingError, createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { ScrollAnimation } from '@dx-ui/osc-scroll-animation';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import { useSegmentedItems } from '../../hooks/use-segments';
import type { VideoPlayerProps } from '@dx-ui/osc-video-player';
import { MastheadOverlayImage } from './Masthead-overlay-image';
import { ForTheStayOverlay } from './ForTheStayOverlay';

type VideoHeroProps = Pick<
  Partial<React.ComponentProps<typeof HeroVideo>>,
  'posterImageUrl' | 'videoCaptionData' | 'altText'
> &
  Partial<VideoPlayerProps>;

type VideoScrollAnimationProps = Partial<
  NonNullable<React.ComponentProps<typeof ScrollAnimation>['video']>
>;

type VideoData = VideoHeroProps & VideoScrollAnimationProps;

export default createCpmComponentDefinition(
  'Masthead Image',

  function mapData({ data, componentParams, addIssue, clearIssue, mappedPage }) {
    function makeContent() {
      const markdown = data?.markdownEditor;

      if (markdown) {
        return {
          markdown,
          cta: data?.link,
        } as { markdown: string; cta: OscDomLink | null };
      }

      if ((data?.contentBlock?.length ?? 0) > 0) {
        return {
          headline: data.contentBlock?.[0]?.heading ?? '',
          description: data.contentBlock?.[1]?.description ?? '',
          cta: data?.link || undefined,
        } as { headline: string; description: string; cta: OscDomLink | null };
      }

      return undefined;
    }

    const { link } = data;
    const asset = data.cpmAssets[0];
    const multimedia = data.multimedia;
    const video = data.videos?.[0];
    let videoData: VideoData = {};

    if (multimedia?.length) {
      const firstMultimedia = multimedia[0];
      const multimediaCaption = firstMultimedia?.captionLink;
      const multimediaCaptionData =
        multimediaCaption?.linkLabel && multimediaCaption?.linkUrl
          ? {
              caption: multimediaCaption.linkLabel,
              captionLink: multimediaCaption.linkUrl,
            }
          : undefined;

      videoData = mapMultimediaListToVideoProps({ multimedia, mappedPage });
      videoData.videoCaptionData = multimediaCaptionData;
    }

    if (video) {
      if (video.source !== 'cloudinary') {
        throw new CpmMappingError(
          `Masthead only supports "cloudinary" as a video source (${data.contentType} - ${data.displayName})`
        );
      }

      const { caption, captionLink, transcript } = video;

      videoData.videoUrl = video.url;
      //The "transcript" field in the CMS is being used as an interim measure.
      //https://jira.hilton.com/browse/NHCBP-5914 will be a better long term solution
      videoData.altText = transcript;
      videoData.videoName = video.name ?? undefined;
      videoData.videoCaptionData = caption && captionLink ? { caption, captionLink } : undefined;
    }

    if (!asset && !(video || multimedia)) {
      addIssue({
        id: data.id,
        message: `"${data.name || data.displayName}" has no image or video - please attach one`,
      });
    } else {
      clearIssue(data.id);
    }

    const content = makeContent();

    return {
      ...videoData,
      $ref: data.ref?.$ref,
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      headline: data.headline,
      longDescription: data?.longDescription,
      shortDescription: data?.shortDescription,
      id: data.id,
      imageAltText: asset?.altText ?? '',
      image: {
        desktopUrl: componentParams.scrollingAnimation
          ? asset?.aspectRatios['16x9']?.url ?? ''
          : asset?.aspectRatios['18x5']?.url ?? '',
        mobileUrl: asset?.aspectRatios['3x2']?.url ?? '',
      },
      aspectRatios: {
        desktop: videoData?.videoUrl ? ('21:9' as const) : ('18:5' as const),
        // We need to set the mobile aspect ratio manually or the video won't render properly
        mobile: '3:2' as const,
      },
      content,
      captionData: asset
        ? {
            captionLink: asset.captionLink,
            caption: asset.caption,
          }
        : {},
      link: link?.url ? link : null,
      badgeImageUrl: data.badgeUrl ?? null,
      badgeImageAltText: 'alt text', //once added we can hook up the appropriate alt text, this field is currently not in CMS
      staticDisplay: componentParams.display === 'static',
      textOverlayDisplay: componentParams.display === 'textOverlay',
      alignContent: componentParams?.textboxPosition || componentParams?.textAlign || undefined,
      brandComponentTheme: componentParams.theme,
      isAnimated: componentParams.animation,
      hasHorizontalLine: componentParams.horizontalLine,
    };
  },

  function MastheadImageCpm({ items = [], componentParams, mappedPage: { brandCode, pageType } }) {
    const filteredItems = useSegmentedItems(items);

    const {
      scrollingAnimation,
      theme,
      backgroundParallax,
      backgroundIllustration,
      textboxPosition,
    } = componentParams;

    const isHomepage = pageType === 'brandHomepage';
    const isEY = brandCode === 'EY';

    const data = filteredItems[0];

    if (!data) {
      return null;
    }

    if (scrollingAnimation && !data.videoUrl && data.image.desktopUrl) {
      return (
        <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
          <ScrollAnimation
            headline={data?.headline}
            shortDescription={data.shortDescription}
            longDescription={data?.longDescription || undefined}
            content={data.content}
            image={{
              altText: data.imageAltText,
              ...data.image,
            }}
            textAlign={data.alignContent}
            brandComponentTheme={theme}
          />
        </BrandComponentThemeInline>
      );
    }
    if (scrollingAnimation && data.videoUrl) {
      return (
        <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
          <ScrollAnimation
            headline={data?.headline}
            shortDescription={data.shortDescription}
            longDescription={data?.longDescription || undefined}
            content={data.content}
            video={{
              videoUrl: data.videoUrl,
              videoName: data.videoName,
              isAutoPlay: data.isAutoPlay,
              markupSchemas: data.markupSchemas,
            }}
            textAlign={data.alignContent}
            brandComponentTheme={theme}
          />
        </BrandComponentThemeInline>
      );
    }
    if (data.staticDisplay && data.videoUrl) {
      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: backgroundParallax,
            variant: backgroundIllustration,
          }}
        >
          <HeroVideo
            headline={data.headline}
            shortDescription={data.shortDescription}
            longDescription={data.longDescription}
            link={data.link}
            brandComponentTheme={data.brandComponentTheme}
            videoGroup={data.videoGroup}
            videoLabel={data.videoLabel}
            videoName={data.videoName}
            videoUrl={data.videoUrl}
            altText={data.altText}
            posterImageUrl={data.posterImageUrl}
            captionTracks={data.captionTracks}
            audioTracks={data.audioTracks}
            transcriptTracks={data.transcriptTracks}
            isAutoPlay={data.isAutoPlay}
            alignContent={data.alignContent}
            isAnimated={data.isAnimated || brandCode === 'GU'}
            hasHorizontalLine={data.hasHorizontalLine}
            badgeImageUrl={data.badgeImageUrl}
            badgeImageAltText={data.badgeImageAltText}
            videoCaptionData={data.videoCaptionData}
            markupSchemas={data.markupSchemas}
            illustrationOverlay={
              isEY && isHomepage ? (
                <MastheadOverlayImage
                  shouldAlignContentPosition={textboxPosition === 'left'}
                  imageUrl="/modules/assets/img/brand/EY/backgrounds/EY-masthead-illustration.svg"
                />
              ) : null
            }
          />
        </BrandComponentThemeInline>
      );
    }

    if (data.textOverlayDisplay && data.image) {
      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: backgroundParallax,
            variant: backgroundIllustration,
          }}
        >
          <HeroTextOverlay
            headline={data.headline}
            shortDescription={data.shortDescription}
            longDescription={data.longDescription}
            link={data.link}
            captionData={data.captionData}
            brandComponentTheme={data.brandComponentTheme}
            image={data.image}
            imageAltText={data.imageAltText}
            alignContent={data.alignContent}
            isAnimated={data.isAnimated}
            badgeImageUrl={data.badgeImageUrl}
            badgeImageAltText={data.badgeImageAltText}
            hasGalleryButton={false}
          />
        </BrandComponentThemeInline>
      );
    }

    return (
      <BrandComponentThemeInline
        componentClassName="hero-image"
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: backgroundParallax,
          variant: backgroundIllustration,
        }}
      >
        <HeroImage
          headline={data.headline}
          shortDescription={data.shortDescription}
          longDescription={data.longDescription}
          link={data.link}
          captionData={data.captionData}
          brandComponentTheme={data.brandComponentTheme}
          image={data.image}
          imageAltText={data.imageAltText}
          alignContent={data.alignContent}
          isAnimated={data.isAnimated || brandCode === 'GU'}
          hasHorizontalLine={data.hasHorizontalLine}
          badgeImageUrl={data.badgeImageUrl}
          badgeImageAltText={data.badgeImageAltText}
          hasGalleryButton={false}
          aspectRatios={data.aspectRatios}
          illustrationOverlay={
            isEY && isHomepage ? (
              <MastheadOverlayImage
                shouldAlignContentPosition={textboxPosition === 'left'}
                imageUrl="/modules/assets/img/brand/EY/backgrounds/EY-masthead-illustration.svg"
              />
            ) : null
          }
          forTheStayIllustration={
            componentParams.text === 'forTheStay' ? (
              <ForTheStayOverlay overlayPosition={componentParams.orientation ?? 'left'} />
            ) : null
          }
        />
      </BrandComponentThemeInline>
    );
  }
);
