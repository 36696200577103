import type { HeadingLevel } from '@dx-ui/osc-heading-level';
import type { MappingSchema } from '../schema';
import type { ValueOf } from '../typeHelpers';
import {
  type UniversalComponentParams,
  type ComponentParamsProperty,
  type IllustrationVariant,
  type IllustrationBlockVariant,
  type textOverlayVariant,
  illustrationVariants,
  illustrationBlockVariant,
  textOverlayVariants,
} from './types';

export function isBoolean(x: unknown): x is boolean {
  return x === true || x === false;
}

export function isString(x: unknown): x is string {
  return typeof x === 'string';
}

export function isAlignment(x: unknown): x is 'left' | 'right' | 'alternate' | 'round' | 'none' {
  return x === 'left' || x === 'right' || x === 'alternate' || x === 'round' || x === 'none';
}

export function isPosition(x: unknown): x is 'left' | 'right' | 'center' {
  return x === 'left' || x === 'right' || x === 'center';
}

export function isTheme(x: unknown): x is 'light' | 'dark' {
  return x === 'light' || x === 'dark';
}

export function isOrientation(x: unknown): x is 'horizontal' | 'vertical' {
  return x === 'horizontal' || x === 'vertical';
}

export function isFullWidthMediaDisplay(
  x: unknown
): x is
  | 'hxHexagonBlue'
  | 'hxHexagonDarkBlue'
  | 'hxHexagonDarkOrange'
  | 'hxHexagonDarkPurple'
  | 'hxHexagonDarkRed'
  | 'hxHexagonRed'
  | 'mediaOnly'
  | 'mediaWithCopy' {
  return (
    x === 'hxHexagonBlue' ||
    x === 'hxHexagonDarkBlue' ||
    x === 'hxHexagonDarkOrange' ||
    x === 'hxHexagonDarkPurple' ||
    x === 'hxHexagonDarkRed' ||
    x === 'hxHexagonRed' ||
    x === 'mediaOnly' ||
    x === 'mediaWithCopy'
  );
}

export function isImageDisplay(
  x: unknown
): x is 'static' | 'textOverlay' | 'imageCarousel' | 'fullCarousel' {
  return x === 'static' || x === 'textOverlay' || x === 'imageCarousel' || x === 'fullCarousel';
}

export function isTabDisplay(
  x: unknown
): x is 'text' | 'textAndIcon' | 'textAndlogo' | 'icon' | 'logo' {
  return x === 'text' || x === 'textAndIcon' || x === 'textAndlogo' || x === 'icon' || x === 'logo';
}

export function isEditorialDisplay(x: unknown): x is 'default' | 'snippet' | 'withCarousel' {
  return x === 'default' || x === 'snippet' || x === 'withCarousel';
}

export function isBackgroundIllustration(x: unknown): x is IllustrationVariant {
  for (const valid of illustrationVariants) {
    if (valid === x) return true;
  }
  return false;
}

export function isIllustrationBlock(x: unknown): x is IllustrationBlockVariant {
  for (const valid of illustrationBlockVariant) {
    if (valid === x) return true;
  }
  return false;
}

export function isTextOverlay(x: unknown): x is textOverlayVariant {
  for (const valid of textOverlayVariants) {
    if (valid === x) return true;
  }
  return false;
}

export function isPaddingAmount(x: unknown): x is 'default' | 'small' | 'medium' | 'large' {
  return x === 'default' || x === 'small' || x === 'medium' || x === 'large';
}

export function makeParamValidator<T, DefaultValue>(
  validator: (x: unknown) => x is T,
  defaultValue: DefaultValue
) {
  return function doValidation(value: unknown): T | DefaultValue {
    if (validator(value)) {
      return value;
    } else {
      return defaultValue;
    }
  };
}

function isCmsHeadingLevel(x: unknown): x is 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' {
  return x === 'h1' || x === 'h2' || x === 'h3' || x === 'h4' || x === 'h5' || x === 'h6';
}

export function convertTopHeadingLevelToInteger(cmsHeadingLevel: unknown) {
  if (isCmsHeadingLevel(cmsHeadingLevel)) {
    return parseInt(cmsHeadingLevel.replace('h', ''));
  }
}

function isInteger(x: unknown): x is number {
  return Number.isInteger(x);
}

export function isHeadingLevel(x: unknown): x is HeadingLevel {
  if (isInteger(x)) {
    return x > 0 && x < 7;
  }

  return false;
}

function sanitizeUniversalParams(
  params: Partial<Record<string, string | boolean>>
): UniversalComponentParams {
  function validOrUndefined<T>(validator: (x: unknown) => x is T, value: unknown): T | undefined {
    return makeParamValidator(validator, undefined)(value);
  }

  return {
    backgroundImage: validOrUndefined(isBoolean, params.backgroundImage),
    backgroundParallax: validOrUndefined(isBoolean, params.backgroundImage),
    backgroundIllustration: validOrUndefined(
      isBackgroundIllustration,
      params.isBackgroundIllustration
    ),
    topPadding: validOrUndefined(isPaddingAmount, params.topPadding),
    bottomPadding: validOrUndefined(isPaddingAmount, params.bottomPadding),
    borderTrim: validOrUndefined(isBoolean, params.borderTrim),
    oneLinkNoTx: validOrUndefined(isBoolean, params.oneLinkNoTx),

    anchorId: validOrUndefined(isString, params.anchorId),
    displayOption: validOrUndefined(isString, params.displayOption),
    display: validOrUndefined(isString, params.display),

    theme: validOrUndefined(isTheme, params.theme),

    textAlign: validOrUndefined(isPosition, params.textAlign),
    textboxPosition: validOrUndefined(isPosition, params.textAlign),
    imageDisplay: validOrUndefined(isAlignment, params.imageDisplay),

    topHeadingLevel: validOrUndefined(
      isHeadingLevel,
      convertTopHeadingLevelToInteger(params.topHeadingLevel)
    ),

    agentId: validOrUndefined(isString, params.agentId),
    variation: validOrUndefined(isString, params.variation),
  };
}

function stripUndefineds<T>(x: T): T {
  return JSON.parse(JSON.stringify(x));
}

export function parseComponentParams<ComponentSchema extends ValueOf<MappingSchema>>(
  componentSchema: ComponentSchema,
  componentParamsRaw: Partial<Record<string, string | boolean>>
): ComponentParamsProperty<ComponentSchema> {
  for (const [key, value] of Object.entries(componentParamsRaw)) {
    // Core+ returns 16 'documentN' keys, mostly with the value "". They can safely be deleted

    // matches keys like 'document', 'document1', 'document5', 'document12' etc
    const isDocumentKey = /^document([0-9]*)$/;
    if (isDocumentKey.test(key) && value === '') delete componentParamsRaw[key];

    // A lot of 'oneLinkNoTxN' are returned to exclude child items from translation. These keys
    // can be removed when the value is `false`.

    // matches keys like 'oneLinkNoTx', 'oneLinkNoTx1', 'oneLinkNoTx5', 'oneLinkNoTx15' etc
    const isOneLinkExcludeFromTranslationKey = /^oneLinkNoTx([0-9]*)$/;

    if (isOneLinkExcludeFromTranslationKey.test(key) && value === false) {
      delete componentParamsRaw[key];
    }
  }

  const universalParams = sanitizeUniversalParams(componentParamsRaw);

  const componentSpecificParams =
    'componentParams' in componentSchema
      ? Object.fromEntries(
          Object.entries(componentSchema.componentParams).map(([key, parser]) => [
            key,
            parser(componentParamsRaw[key]),
          ])
        )
      : {};

  return stripUndefineds({
    ...universalParams,
    ...componentSpecificParams,
  } as ComponentParamsProperty<ComponentSchema>);
}
