/* eslint-disable @typescript-eslint/no-explicit-any */

interface MakePipe {
  <A, B, C, D>(f1: (data: A) => B, f2: (data: B) => C, f3: (data: C) => D): (value: A) => D;

  <A, B, C, D, E>(f1: (data: A) => B, f2: (data: B) => C, f3: (data: C) => D, f4: (data: D) => E): (
    value: A
  ) => E;

  <A, B, C, D, E, F>(
    f1: (data: A) => B,
    f2: (data: B) => C,
    f3: (data: C) => D,
    f4: (data: D) => E,
    f5: (data: E) => F
  ): (value: A) => F;

  <A, B, C, D, E, F, G>(
    f1: (data: A) => B,
    f2: (data: B) => C,
    f3: (data: C) => D,
    f4: (data: D) => E,
    f5: (data: E) => F,
    f6: (data: F) => G
  ): (value: A) => G;

  <A, B, C, D, E, F, G, H>(
    f1: (data: A) => B,
    f2: (data: B) => C,
    f3: (data: C) => D,
    f4: (data: D) => E,
    f5: (data: E) => F,
    f6: (data: F) => G,
    f7: (data: G) => H
  ): (value: A) => H;
}

export const makePipe: MakePipe =
  (...fns: ((data: any) => any)[]) =>
  (value: any) =>
    fns.reduce((acc, fn) => fn(acc), value);

export function objectMap<Keys extends string | number | symbol, In, Out>(
  obj: Record<Keys, In>,
  fn: (before: In) => Out
): Record<Keys, Out> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]: any) => [key, fn(val)] as any)
  ) as any;
}

export function objectMapWithKey<Keys extends string | number | symbol, In, Out>(
  obj: Record<Keys, In>,
  fn: (key: Keys, before: In) => Out
): Record<Keys, Out> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]: any) => [key, fn(key, val)] as any)
  ) as any;
}

/**
 * getServersideProps has some (insane) demand that your returned data may not contain `undefined`
 * this is the fastest way to strip those values
 */
export function removeUndefineds<T>(x: T): T {
  return JSON.parse(JSON.stringify(x));
}

export function makeWriteable<T>(x: readonly T[]): T[] {
  return x as any;
}
