export type TSegmentedItem = {
  segmentIds?: string[];
};

type TgetFilteredItems<TProps> = {
  items: (TProps & TSegmentedItem)[];
  segments: string[];
  numItems?: number;
};

export const getUnsegmentedItems = <T extends TSegmentedItem>(items: Array<T>): Array<T> =>
  items?.filter((item) => item.segmentIds?.length === 0 || !item.segmentIds) || [];

export const getFilteredItems = <T>({ segments, items, numItems }: TgetFilteredItems<T>) => {
  const filteredItems = items?.filter((item) => {
    // don't filter if content is not segmented
    if ((item.segmentIds?.length ?? 0) <= 0) {
      return true;
    }
    return segments?.filter((segmentId) => item?.segmentIds?.includes(segmentId))?.length > 0;
  });

  return numItems ? filteredItems?.slice(0, numItems) : filteredItems;
};
