import { useEffect, useState } from 'react';
import cx from 'classnames';
import { DialogWithContent } from '@dx-ui/osc-marketing';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import DynamicGridItem, { type TDynamicGridItem } from './dynamic-grid-item';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';

type ListItemButtonElement = HTMLButtonElement | null;
export type VariantTypes = 'card';

export type TDynamicGrid = {
  id: string;
  /** An array of TDynamicGridItem objects */
  items: TDynamicGridItem[];
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
  /** The aspect ratio for the modal item image */
  modalImageAspectRatio?: AspectRatio;
  /** If true then vertically stack items for mobile, instead of horitzontally scrolling */
  isMobileVerticalStack?: boolean;
  /** Headline for Dynamic Grid */
  listHeadline?: string;
  /** Content as a component, such as Markdown, or text */
  listDescription?: JSX.Element | string;
  /** Text alignment for CMS controls */
  textAlign?: 'left' | 'right' | 'center';
  /** Add Tailwind classes to root element */
  className?: string;
  /** Dialog component requires ariaLabel */
  ariaLabel?: string;
  /** Callback handler for Read Message event  */
  onViewItem?: (id: string) => void;
  /** Callback for modal state opening or closing */
  onToggleModal?: (isOpen: boolean) => void;
  /** Theme variable for dark/light theming */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Enables reveal animations */
  isAnimated?: boolean;
  /** New card variant added for Enterprise refresh */
  variant?: VariantTypes;
};

/**
 * DynamicGrid creates a grid of items with an image and title that opens a modal when clicked. Grids with either be a 3x grid (item counts of 3, 5, 6, or 9) or a 4x grid (item counts of 4, 7, or 8).
 * Recommended grid item counts of 3 to 9.
 */
export const DynamicGrid: React.FC<TDynamicGrid> = ({
  imageAspectRatio,
  modalImageAspectRatio = '16:9',
  items: baseItems = [],
  isMobileVerticalStack = false,
  className = '',
  listHeadline,
  listDescription,
  textAlign = 'left',
  onViewItem,
  onToggleModal,
  isAnimated = false,
  id: dynamicGridId,
  brandComponentTheme,
  variant,
  ...props
}) => {
  const [activeId, updateActiveId] = useState('');
  const [isModalOpen, updateModalOpen] = useState(false);
  const [snapClasses, setSnapClasses] = useState('');
  const [activeListItem, setActiveListItem] = useState<ListItemButtonElement>();
  const items = baseItems?.filter((item) => Boolean(item));
  const count = items.length;

  useEffect(() => {
    // delay appying the snap classes, otherwise snapping can occur before the user scrolls
    const timer = setTimeout(() => setSnapClasses('snap snap-x snap-px-4 snap-mandatory'), 500);
    return () => clearTimeout(timer);
  }, []);

  if (!count) {
    return null;
  }

  const openModal = (id: string, el: ListItemButtonElement) => {
    updateActiveId(id);
    el && setActiveListItem(el);
    onToggleModal && onToggleModal(true);
    onViewItem && onViewItem(id);
    updateModalOpen(true);
  };
  const closeModal = () => {
    onToggleModal && onToggleModal(false);
    updateModalOpen(false);
    activeListItem && setTimeout(() => activeListItem.focus());
  };
  const activeItem: TDynamicGridItem | undefined =
    items?.find((i) => i.id === activeId) || items?.[0];

  const is369 = count % 3 === 0 || count === 5;
  const is4x = !is369 && count > 2 && (count % 2 === 0 || count === 7);
  const is2x = !is369 && (is4x || count % 2 === 0);
  const isOther = !is369 && !is4x && !is2x;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <section>
      <div
        className={cx('container relative overflow-hidden py-8 pe-0 lg:py-10', {
          [className]: !!className,
        })}
        data-testid="dynamicGridWrap"
        id={dynamicGridId}
      >
        {listHeadline && (
          <BrandTextHeader
            className={cx('dynamic-grid-header', mapTextAlignToClassname(textAlign), {
              '!text-text-inverse': isDark,
              '!dynamic-grid-header-light': isLight,
            })}
          >
            {listHeadline}
          </BrandTextHeader>
        )}
        {listDescription && (
          <BrandTextBody
            className={cx(
              'dynamic-grid-description pe-8 sm:pe-6',
              mapTextAlignToClassname(textAlign),
              {
                'text-text-inverse': isDark,
                '!dynamic-grid-descrption-light': isLight,
              }
            )}
            brandComponentTheme={brandComponentTheme}
            {...props}
          >
            {listDescription}
          </BrandTextBody>
        )}

        <div className="size-full">
          <div
            data-testid="dynamicGridItemsWrap"
            className={cx(
              snapClasses,
              'grid-wrapper -mx-4 grid gap-4 overflow-hidden overflow-x-visible px-4 py-2 md:-mx-8 md:pe-1 md:ps-8',
              `auto-cols-[85%] sm:me-0 sm:w-full sm:grid-flow-row sm:overflow-x-hidden sm:pe-0`,
              isMobileVerticalStack ? 'grid-flow-row grid-cols-1' : 'grid-flow-col',
              is369 ? 'sm:grid-cols-3' : null,
              is2x ? 'sm:grid-cols-2' : null,
              is4x ? 'lg:grid-cols-4' : null,
              isOther ? 'sm:grid-cols-2 lg:grid-cols-4' : null
            )}
          >
            {items.map((item: TDynamicGridItem, index) => (
              <AnimateRevealItem
                key={`dynamic-grid-item${item.id}`}
                delay={is369 ? 100 + (index % 3) * 100 : 100 + (index % 4) * 100}
                isAnimated={isAnimated}
                className={cx({
                  'max-sm:translate-y-0 max-sm:opacity-100': !isMobileVerticalStack,
                })}
              >
                <DynamicGridItem
                  imageAspectRatio={imageAspectRatio}
                  className="w-full sm:w-full"
                  onItemClick={openModal}
                  variant={variant}
                  brandComponentTheme={brandComponentTheme}
                  {...item}
                />
              </AnimateRevealItem>
            ))}
            <div className="w-4 sm:hidden md:ms-6" />
          </div>
        </div>

        <DialogWithContent
          id="dynamicGridModalOverlay"
          ariaLabel={activeItem?.itemTitle ?? ''}
          containerAspectRatio={modalImageAspectRatio}
          imageAspectRatio={modalImageAspectRatio}
          imageUrl={activeItem?.modalImageUrl}
          imageAltText={activeItem?.imageAltTxt}
          title={activeItem?.itemTitle}
          headline={activeItem?.headline}
          content={activeItem?.shortDescription}
          link={activeItem?.link}
          isOpen={isModalOpen}
          onClose={closeModal}
          cmsTranslationClasses={activeItem?.cmsTranslationClasses}
          {...props}
        />
      </div>
    </section>
  );
};

export default DynamicGrid;
