import type { ReactNode } from 'react';
import cx from 'classnames';

type Height =
  | `${number}px`
  | `${number}%`
  | `${number}em`
  | `${number}rem`
  | `${number}vh`
  | `${number}vw`
  | 'auto'
  | 'fit-content'
  | 'inherit'
  | 'initial'
  | 'unset';

export type SpacerProps = {
  /* Add a class to the Spacer wrapper */
  wrapperClassName?: string;
  /* Add a fixed height to the spacer */
  height?: Height;
  /* Add a background image to the spacer, which can be position using styles like background position and background-repeat. */
  backgroundImage?: string;
  /* Style object to customise the spacer as required */
  styles?: React.CSSProperties;
  /* Child node allows the spacer to render a responsive image or any other markup */
  children?: ReactNode;
};

export const Spacer: React.FC<React.PropsWithChildren<SpacerProps>> = ({
  wrapperClassName,
  height,
  backgroundImage,
  styles,
  children,
}) => {
  const defaultStyles: React.CSSProperties = {
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div
      className={cx('w-full', wrapperClassName)}
      data-testid="spacer"
      style={{
        height,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
        ...defaultStyles,
        ...styles,
      }}
    >
      {children}
    </div>
  );
};

export default Spacer;
