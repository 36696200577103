import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const BrandMessagesDocument = {
  operationName: 'brand',
  operationString: `query brand($brandCode: String!, $lang: String!) {
  clientMessages: brand(brandCode: $brandCode, language: $lang) {
    messages {
      id
      type
      name
      headline
      shortDescription
      startDateTime
      endDateTime
      link {
        _id
        adaDescription
        isNewWindow
        label
        url
      }
    }
  }
}`,
  originalOpName: 'BrandMessages',
};
export function useBrandMessagesQuery<TData = Types.BrandMessagesQuery, TError = DXError>(
  variables: Types.BrandMessagesQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandMessagesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandMessagesQuery, TError, TData>({
    queryKey: [BrandMessagesDocument, variables],
    ...options,
  });
}
export function serverSideBrandMessagesQuery(
  queryClient: QueryClient,
  variables: Types.BrandMessagesQueryVariables,
  options?: FetchQueryOptions<Types.BrandMessagesQuery>
) {
  return queryClient.fetchQuery<Types.BrandMessagesQuery>({
    queryKey: [BrandMessagesDocument, variables],
    ...options,
  });
}
