import cx from 'classnames';

interface Effects {
  animation?: boolean;
  backgroundImage?: boolean;
  blur?: boolean;
  svg?: boolean;
}

export const getEffectClassNames = (effects: Effects) =>
  cx({
    // Only background image effect
    'bg-img-overlay': effects.backgroundImage,
  });
