import type { BaseData } from '../adapters/types';
import type { CmsDocumentType } from '../schema';
import { getDocumentOfTypeFromPageAndComponent } from '../adapters/getters';
import { useBrComponentContext } from '../adapters/hooks';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';

export const useData = <T extends BaseData>({
  documentType,
}: {
  documentType?: CmsDocumentType;
} = {}): T[] | null => {
  const page = useCpmMergedBrPageContext();
  const component = useBrComponentContext();
  return getDocumentOfTypeFromPageAndComponent<T>(
    documentType ? [documentType] : null,
    component,
    page
  );
};

export const useDataSingle = <T extends BaseData>(
  query: { documentType?: CmsDocumentType } = {}
): T | null => useData<T>(query)?.[0] ?? null;
