import Icon from '@dx-ui/osc-icon';
import { useState } from 'react';

export type TImageControlButton = {
  icon: 'next' | 'previous';
  className: string;
  labelText: string;
  navigationText: string;
  onClick: () => void;
};

const ImageControlButton = ({
  icon,
  className,
  labelText,
  navigationText,
  onClick,
}: TImageControlButton) => {
  const [justClicked, setJustClicked] = useState(false);

  const onButtonClick = () => {
    if (onClick) onClick();
    setJustClicked(true);
    let timeout: number | null = null;
    timeout = window.setTimeout(() => {
      setJustClicked(false);
    }, 150);
    return () => timeout && window.clearTimeout(timeout);
  };

  const onButtonFocus = () => {
    setJustClicked(true);
    let timeout: number | null = null;
    timeout = window.setTimeout(() => {
      setJustClicked(false);
    }, 150);
    return () => timeout && window.clearTimeout(timeout);
  };

  return (
    <>
      <span className="sr-only" aria-live="polite">
        {justClicked ? labelText.replace('Next slide,', '').replace('Previous slide', '') : ''}
      </span>
      <button className={className} onClick={onButtonClick} onFocus={onButtonFocus} type="button">
        <span className="sr-only">{navigationText}</span>
        <Icon
          className="fill-bg-alt"
          name={icon === 'next' ? 'arrowhead-right' : 'arrowhead-left'}
          size="xl"
        />
      </button>
    </>
  );
};

export default ImageControlButton;
