import * as GeneratedQueries from './generated/queries';

export * from './types';
export type { BrandQuery, BrandsQuery } from './generated/types';
export const { useBrandQuery, serverSideBrandQuery, serverSideBrandsQuery } = GeneratedQueries;

export * from './components/CpmPage';
export * from './components/Error';

export { useSingleInstanceQueryStrings } from './hooks/use-query-strings';
export { getBaseUrl, getAppVersion } from './utils/constants';
export { getAssetPath, getBrandSvg } from './utils/assets';
export * from './utils/routing';
export { getBrandTaggingValues } from './utils/get-brand-tagging-values';
export { getQueryProviderProps } from './utils/get-query-provider-props';
export type { SearchUrlParameters } from './utils/search';
export { generateUrl } from './utils/search';
export { mapDataToSpecialRates } from './utils/map-data-to-special-rates';

export { useMetrics } from './metrics/metrics';

export { namespaces, mappingSpec, Header } from './generatedCpmInterface';

export type { CpmData, CpmServerBundleWithLanguages } from './utils/fetch-server-cpm-page';
export {
  fetchServerCpmPageWithSupportedLanguages,
  FetchServerCpmPage404Error,
} from './utils/fetch-server-cpm-page';
