export const parseStringyBool = (convertString: string) => {
  switch (convertString.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(convertString);
  }
};

const booleanYNMap: { [key: string]: string } = {
  true: 'Y',
  false: 'N',
};

export const convertBoolToYNString = (booleanUrlParam: boolean): string =>
  booleanYNMap[booleanUrlParam.toString()] || 'X';
