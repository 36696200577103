import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { type BrandCode, searchUriBuilder } from '@dx-ui/framework-uri-builder';
import type { FormData } from '@dx-ui/osc-brands-search';
import { convertBoolToYNString } from './parse-stringy-bool';
import { getVisitorId } from './visitor-id';
import { getBaseUrl } from './constants';

export type SearchUrlParameters = {
  languageCode: string;
  awc: string;
  cid: string;
  dclid: string;
  formData: FormData;
  gclid: string;
  isGroupSearch: boolean;
  baseAppUrl: string;
  url: string;
  wtmcid: string;
  brandCode: string;
};

export const GROUP_LIMIT = 10;
function generateGroupSearchUrl({
  formData,
  isGroupSearch,
  brandCode,
  languageCode,
}: SearchUrlParameters) {
  const { numRooms, numAttendees, dates, query } = formData;
  const { arrivalDate, departureDate } = dates;
  const queryVal = query;

  // if numAttendees isn't defined, make sure that we are requesting at least 10 rooms
  const numRoomsVal = Number(numAttendees)
    ? Number(numRooms)
    : Math.max(Number(numRooms), GROUP_LIMIT);

  const groupUrl = searchUriBuilder({
    brandCode: brandCode as keyof BrandCode,
    locale: languageCode,
    urlParams: {
      dates: {
        arrivalDate: arrivalDate ?? undefined,
        departureDate: departureDate ?? undefined,
      },
      placeId: formData.placeId,
      query: queryVal,
      numRooms: numRoomsVal,
      numAttendees: isGroupSearch ? Number(numAttendees) : 0,
      sessionToken: getVisitorId() ?? undefined,
    },
    baseUrl: getBaseUrl(),
    relative: false,
  });

  return groupUrl;
}

function generateNormalSearchUrl({ url, baseAppUrl }: SearchUrlParameters) {
  const origin = baseAppUrl ? new URL(baseAppUrl).origin : null;

  return origin ? `${origin}${url}` : url;
}

export function generateUrl(searchUrlParameters: SearchUrlParameters) {
  // For less than 10 rooms and no attendees, use transient search
  const isGroupSearchAndMoreThan10Rooms =
    searchUrlParameters.isGroupSearch && (searchUrlParameters?.formData?.numRooms || 10) >= 10;
  const hasAtLeastOneAttendee =
    searchUrlParameters?.formData?.numAttendees && searchUrlParameters?.formData?.numAttendees > 0;

  if (isGroupSearchAndMoreThan10Rooms || hasAtLeastOneAttendee) {
    return generateGroupSearchUrl(searchUrlParameters);
  }

  if (
    (!searchUrlParameters.formData?.dates?.arrivalDate &&
      !searchUrlParameters.formData?.dates?.departureDate) ||
    //dreamURIBuilder previously generates valid URI
    (searchUrlParameters.formData?.dates?.datesFlex &&
      searchUrlParameters?.url?.includes('/locations/'))
  ) {
    return new URL(searchUrlParameters.url, searchUrlParameters.baseAppUrl).toString();
  }
  return generateNormalSearchUrl(searchUrlParameters);
}

export type ICreateProperySearchDateInfoString = {
  arrivalDate: string | null;
  departureDate: string | null;
};
export const createPropertySearchDateInfoString = (
  props: ICreateProperySearchDateInfoString
): string => {
  const { arrivalDate, departureDate } = props;
  const currentDate = format(new Date(), 'MMddyyyy');
  const parsedArrivalDate = arrivalDate && parseISO(new Date(arrivalDate).toISOString());
  const parsedDepartureDate = departureDate && parseISO(new Date(departureDate).toISOString());
  const formattedParsedArrivalDate = parsedArrivalDate && format(parsedArrivalDate, 'MMddyyyy');
  const formattedParsedDepartureDate =
    parsedDepartureDate && format(parseISO(new Date(departureDate).toISOString()), 'MMddyyyy');
  const numNights =
    parsedDepartureDate &&
    parsedArrivalDate &&
    differenceInCalendarDays(parsedDepartureDate, parsedArrivalDate);
  const daysInAdvance =
    parsedArrivalDate && differenceInCalendarDays(parsedArrivalDate, new Date());

  return `${currentDate}:${formattedParsedArrivalDate}:${formattedParsedDepartureDate}:${numNights}:${daysInAdvance}`;
};

export type SearchRateCodeStringProps = {
  isAarp: boolean | undefined;
  isGovernment: boolean | undefined;
  isSenior: boolean | undefined;
  isTravelAgents: boolean | undefined;
  isTripleA: boolean | undefined;
  isUsePoints: boolean | undefined;
  isEmployee: boolean | undefined;
  isOwner: boolean | undefined;
  isFriendsAndFamily: boolean | undefined;
  groupCode: string | undefined;
  promoCode: string | undefined;
  corporateCode: string | undefined;
};
export const createPropertySearchRateCodeString = (props: SearchRateCodeStringProps): string =>
  `${props?.promoCode || 'N'}:${props?.groupCode || 'N'}:${
    props?.corporateCode || 'N'
  }:${convertBoolToYNString(props?.isTravelAgents || false)}:${convertBoolToYNString(
    props?.isTripleA || false
  )}:${convertBoolToYNString(props?.isAarp || false)}:${convertBoolToYNString(
    props?.isSenior || false
  )}:${convertBoolToYNString(props?.isGovernment || false)}:${convertBoolToYNString(
    props?.isUsePoints || false
  )}:N:${convertBoolToYNString(props?.isOwner || false)}:${convertBoolToYNString(
    props?.isEmployee || false
  )}:${convertBoolToYNString(props?.isFriendsAndFamily || false)}`;

export type ICreatePropertySearchCriteriaString = {
  numRooms: number;
  numAdults: number;
  numChildren: number;
  isFlexDatesEnabled: boolean;
  isUsePoints: boolean;
  isSpecialRatesUsed: boolean;
  distance: number;
  meetingCapacity?: string;
  meetingStartDate?: string;
  meetingEndDate?: string;
  isMeetingFoodOrBeverage?: boolean;
  isMeetingAudioVideo?: boolean;
};
export const createPropertySearchCriteriaString = (
  props: ICreatePropertySearchCriteriaString
): string => {
  const {
    numRooms = 1,
    numAdults = 1,
    numChildren = 0,
    isFlexDatesEnabled = false,
    isUsePoints = false,
    isSpecialRatesUsed = false,
    distance = 0,
    meetingCapacity = 'X',
    meetingStartDate = 'X',
    meetingEndDate = 'X',
    isMeetingFoodOrBeverage = false,
    isMeetingAudioVideo = false,
  } = props;

  return `${numRooms}:${numAdults}:${numChildren}:${convertBoolToYNString(
    isFlexDatesEnabled
  )}:${convertBoolToYNString(isUsePoints)}:${convertBoolToYNString(
    isSpecialRatesUsed
  )}:${distance}:${meetingCapacity}:${meetingStartDate}:${meetingEndDate}:${convertBoolToYNString(
    isMeetingFoodOrBeverage
  )}:${convertBoolToYNString(isMeetingAudioVideo)}`;
};
