import type { CpmMappedPage } from '../mappingEngine/cpmMappedPage';
import type { ExtractCampaignCodeTaggingValuesFn, AddCampaignCodeToUrl } from '../adapters/types';

export function createAddCampaignCodeToUrl(
  // Name of the component that this link appears in
  componentName: string,
  // Mapped page data from CPM
  mappedPage: CpmMappedPage,
  // Analytics values provided by the CMS will be used by default. Apps can override this by passing a custom function.
  extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
): AddCampaignCodeToUrl {
  return function addCampaignCodeToUrl(linkUrl?: string, campaignId?: string | null) {
    try {
      // Bail out if a URL or campaign code ID hasn't been passed
      if (!linkUrl || !campaignId) {
        return linkUrl || '';
      }

      const url = new URL(linkUrl);
      checkValidQueryString(url);

      const params = url.searchParams;

      if (params.has('cid')) {
        return linkUrl;
      }

      const { brandCode } = mappedPage;
      const { adobePageType, cidPostfix } = extractCampaignCodeTaggingValues(mappedPage);
      const campaignCodeSegments = [
        'OH',
        brandCode,
        campaignId,
        'MULTIPR',
        componentName,
        adobePageType,
      ];

      if (cidPostfix) {
        campaignCodeSegments.push(cidPostfix);
      }

      const cid = campaignCodeSegments.join(',');
      params.set('cid', cid);

      // We need to keep the CID param human-readable for content/analytics
      // teams
      const [href, search] = url.href.split('?');
      if (!(href && search)) {
        throw new Error('missing query string');
      }

      return `${href}?${buildSearchStringForAnalyticsCampaignId(search)}`;
    } catch {
      return linkUrl || '';
    }
  };
}

/**
 * Use the analytics values set by the CMS. Consumers are able to override this by passing in their own function.
 */
export function getTaggingValuesFromCpmAnalytics(mappedPage: CpmMappedPage) {
  const postfix: string[] = [];
  const { analyticsTagging } = mappedPage;

  if (analyticsTagging.primaryCategory) {
    postfix.push(capitalizeHyphen(analyticsTagging.primaryCategory));
  }

  if (analyticsTagging.pageDetailOne) {
    // Campaign code postfix only includes two "page detail" levels.
    postfix.push(capitalizeHyphen(analyticsTagging.pageDetailOne));

    // We only want to include the second page detail if the first exists
    if (analyticsTagging.pageDetailTwo) {
      postfix.push(capitalizeHyphen(analyticsTagging.pageDetailTwo));
    }
  }

  return {
    adobePageType: analyticsTagging.adobePageType,
    cidPostfix: postfix.join('-'),
  };
}

function capitalizeHyphen(input?: string) {
  if (!input) {
    return '';
  }

  return input.replace(/(^|[\s-])\S/g, (match) => match.toUpperCase());
}

/**
 * Builds a search string that leaves the `cid` param decoded to aid readability
 * Note: search is a string instead of a URLSearchParams because we don't want
 * to modify the encoding of any other parameter.
 */
export function buildSearchStringForAnalyticsCampaignId(search: string) {
  return search
    .split('&')
    .map((keyValue) => {
      const [key, value] = keyValue.split('=');

      if (key === 'cid' && value) {
        return `cid=${decodeURIComponent(value)}`;
      }

      return keyValue;
    })
    .join('&');
}

/**
 * Check if the URL has a valid query string with a single ?
 * replace subsequent ? with &
 */
export function checkValidQueryString(url: URL) {
  const questionMarkCount = (url.href.match(/\?/g) || []).length;
  if (questionMarkCount > 1) {
    const [baseUrl, queryString] = url.href.split(/\?(.+)/);
    if (baseUrl && queryString) {
      const fixedQueryString = queryString.replace(/\?/g, '&');
      url.href = `${baseUrl}?${fixedQueryString}`;
    }
  }
}
