import { createCpmComponentDefinition, type StructuredAsset } from '@dx-ui/cpm-sdk';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useSegmentedItems } from '../../hooks/use-segments';
import { BrandFilmstrip } from './BrandFilmstrip';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';

type ImageObjectTypes = {
  aspectRatios: {
    [key: string]: {
      url: string;
    };
  };
  altText?: string;
  caption?: string;
  captionLink?: string;
  id?: string;
};

const createImageObject = (image: ImageObjectTypes) => {
  const imageObject = {
    id: image?.aspectRatios['16x9']?.url ?? '',
    desktopImageUrl: image?.aspectRatios['16x9']?.url ?? '',
    mobileImageUrl: image?.aspectRatios['3x2']?.url ?? '',
    altText: image?.altText ?? '',
    captionData: image?.caption
      ? {
          caption: image?.caption,
          captionLink: image?.captionLink ?? '',
        }
      : undefined,
  };

  return imageObject;
};

export default createCpmComponentDefinition(
  'Film Strip',

  function mapComponentData({ data }) {
    const images = data.cpmAssets;

    if (!images) {
      throw new Error(`${data.displayName || data.name} is missing an image`);
    }

    const filmStripImages = images
      .filter((image): image is StructuredAsset => image !== undefined)
      .map(createImageObject);

    return {
      $ref: data.ref?.$ref,
      id: data.id,
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      images: filmStripImages,
    };
  },

  function CpmFilmStrip({ items = [], listData, componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);

    const data = filteredItems[0];

    if (!data || !data.images) {
      return null;
    }

    const headline = listData?.headline ?? '';
    const description = listData?.description ?? '';
    const links = listData?.links ?? [];
    const listLink = links.length ? links[0] : undefined;

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
          <BrandFilmstrip
            images={data.images}
            headline={headline}
            description={description}
            link={listLink}
            textAlign={componentParams?.textAlign}
            brandComponentTheme={componentParams?.theme}
          />
        </AnimateRevealItem>
      </BrandComponentThemeInline>
    );
  }
);
