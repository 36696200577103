import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const TogglesDocument = {
  operationName: 'featureConfigs_featureToggles',
  operationString: `query featureConfigs_featureToggles($features: [String]!, $configs: [String!]!) {
  featureToggles: featureToggles(names: $features) {
    name
    enabled
    cacheable
  }
  configToggles: featureConfigs(names: $configs) {
    config
    name
  }
}`,
  originalOpName: 'Toggles',
};
export function useTogglesQuery<TData = Types.TogglesQuery, TError = DXError>(
  variables: Types.TogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.TogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.TogglesQuery, TError, TData>({
    queryKey: [TogglesDocument, variables],
    ...options,
  });
}
export function serverSideTogglesQuery(
  queryClient: QueryClient,
  variables: Types.TogglesQueryVariables,
  options?: FetchQueryOptions<Types.TogglesQuery>
) {
  return queryClient.fetchQuery<Types.TogglesQuery>({
    queryKey: [TogglesDocument, variables],
    ...options,
  });
}
